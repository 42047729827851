import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Users} from './models/users';
import {Observable, throwError} from 'rxjs';
import {retry, catchError} from 'rxjs/operators';
import {environment} from '../environments/environment';

const url = environment.baseUrl;
@Injectable({
  providedIn: 'root',
})
export class RegisterService {
   // Http Options
   httpOptions = {
     headers: new HttpHeaders({
       'Content-Type': 'application/json',
     }),
   };
  httpOptions2 = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'skip': 'true',
    }),
  };
  constructor(private httpClient: HttpClient) { }

  // sendMessage(messageContent: Observable<Users>) {
  //   return this.http.post(this.url,
  //   JSON.stringify(messageContent),
  //   { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'text' });
  // }

  sendMessage(messageContent): Observable<Users> {
    return this.httpClient.post<Users>(url + '/v1/users', JSON.stringify(messageContent), this.httpOptions)
        .pipe(
            retry(0),
            catchError(this.handleError),
        );
  }


  public checkUsername(username) {
    return this.httpClient.post(url + '/v1/users/register/username/check', username, this.httpOptions2);
  }


  // Error handling
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
    // Get client-side error
      errorMessage = error.error.message;
    } else {
    // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
