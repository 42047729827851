import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

const baseUrl = environment.fileUpURL + 'sms';
@Injectable({
  providedIn: 'root',
})
export class TestService {
  token = localStorage.getItem('Authorization');

  httpOptions = {
    headers: {},
  };


  constructor(private httpClient: HttpClient) {}


  testUpload(file) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + '/read', file, this.httpOptions);
    } return;
  }


  commitFile(file) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + '/commit', file, this.httpOptions);
    } return;
  }

  httpOptionsDownload = {
    responseType: 'blob',
    headers: {},
  }

  public getBadRows(file) {
    if (this.token !== null) {
      const httpOptionsDownload = {
        responseType: 'blob' as 'json',
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + '/badrows', file, httpOptionsDownload);
    } return;
  }
}
