/* eslint-disable max-len */
/* eslint-disable no-multi-str */
import {Injectable} from '@angular/core';
import Shepherd from 'shepherd.js';
import {Router} from '@angular/router';
import {TutorialTwoService} from '../guide/tutorial-two.service';

const smsTour = new Shepherd.Tour({
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
    classes: 'shadow-md bg-purple-dark',
    scrollTo: {behavior: 'smooth', block: 'center'},
  },
  confirmCancel: false,
  // confirmCancelMessage: '',
  exitOnEsc: false,
  keyboardNavigation: false,
  useModalOverlay: true,
});

@Injectable({
  providedIn: 'root',
})
export class TutorialService {
  constructor(private router: Router, private tutorialTwo: TutorialTwoService) {}


  smsTour() {
    smsTour.on('complete', () => {
      this.router.navigate(['/sms/send/from-phonebook']);
      this.tutorialTwo.smsTour2();
    });
    smsTour.addSteps([{
      id: 'start',
      title: 'Welcome to the SMS Tour',
      text: 'This is an interactive tutorial and will cover every component related to SMS messages.<br><br>\
      As always I will be assuming the role of your fearless tour guide. At the end you will have mastered the art of SMS.<br><br>\
      <mark>- At some steps I will require you to interact with the page before we can move on. These interactive actions will be highlighted like this</mark>',
      buttons: [
        {action() {
          return this.next();
        }, text: 'Start Tour'},
      ],
    }, {
      id: 'step1',
      title: 'Send Single SMS',
      text: `Send SMS messages by manually providing the message and number.<br><br>\
      - <i>Perfect for sending a few messages.</i><br><br>
      <mark>To <strong>Skip</strong> the Sending Single SMS Tour click on "Skip".<br>
      To <strong>Start</strong> the Sending Single SMS Tour click on "Next".</mark>`,
      attachTo: {element: '.mainS5', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.show('step13');
        }, text: 'Skip'},
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step2',
      title: 'Message Field',
      text: 'First you enter the message that you would like to send.<br><br>\
      <i>Your message may only contain between 1 and 160 characters. Avoid using any special characters in your message.</i><br><br>\
      <mark>- Try typing in your own message before we move on to the next step.</mark>',
      attachTo: {element: '.sms2', on: 'top'},
      buttons: [
        {action() {
          return this.back();
        }, text: 'Back'},
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step3',
      title: 'Number Field',
      text: 'Then you enter the cellphone number of the intended recipient.<br><br>\
      <i>This number should start with a "0" and be exactly 10 digits long. It should be noted that no spaces should be included.</i><br><br>\
      <mark>- Try entering your number here before you continue.</mark>',
      attachTo: {element: '.sms3', on: 'top'},
      buttons: [
        {action() {
          return this.back();
        }, text: 'Back'},
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step4',
      title: 'When To Send',
      text: 'Then you decide if the message should be sent either instantly or at a chosen time and date.<br><br>\
      <i>Lets have a look at scheduling a message for a specific time.</i><br><br>\
      <mark>- To continue to the next step please flip this switch over to "Scheduled".</mark>',
      attachTo: {element: '.sms4', on: 'top'},
      advanceOn: {selector: '.sms4a', event: 'click'},
      modalOverlayOpeningPadding: 10,
    }, {
      id: 'step5',
      title: 'Schedule Time & Date',
      text: 'If you want your message to be scheduled & sent at a specific time you have to select a date and a time.<br><br>\
      <i>Normally you would select a date and time by clicking on the respective field and selecting a value.</i><br><br>',
      attachTo: {element: '.sms5', on: 'top'},
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 200);
        });
      },
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step6',
      title: 'Sending messages instantly',
      text: 'Lets say that you want this message to be sent out ASAP; Hypothetically speaking of course.<br><br>\
      <i>Flipping this switch back to "Instantly" will ensure that I get this message out for you almost instantly.</i><br><br>\
      <mark>- To continue to the next step please flip this switch back to "Instantly" again.</mark>',
      attachTo: {element: '.sms4', on: 'top'},
      advanceOn: {selector: '.sms4a', event: 'click'},
      modalOverlayOpeningPadding: 10,
    }, {
      id: 'step7',
      title: 'Add Message To Queue Button',
      text: 'After completing all of the previous steps you need to click on this button to add your SMS to your queue.<br><br>\
      <i>It should be noted that this queue is local and not yet final. It serves as a preview before you finally submit your queue.</i><br><br>\
      <mark>- Click on "Add Messages To Queue" to load your message into your preview queue.</mark>',
      attachTo: {element: '.sms7', on: 'top'},
      advanceOn: {selector: '.sms7a', event: 'click'},
    }, {
      id: 'stepCheck',
      title: 'Verifying...',
      text: 'At this stage you will either see an error pop up or your queue will be displayed.<br><br>\
      If you see a red error popup it means that there are some invalid inputs.<br><br>\
      If not then it means everything is valid and your message is now in your local queue ',
      canClickTarget: false,
      buttons: [
        {action() {
          if (document.getElementById('errorPop')) {
            return this.show('stepError');
          } else {
            return this.show('step8');
          }
        }, text: 'Next'},
      ],
    }, {
      id: 'stepError',
      title: 'Oops...',
      text: 'It seems like either your message field, number field, or scheduled switch might be incorrect.<br><br>\
      <mark>- Please click on the "Fix" button and we will circle back to the input fields to try again</mark>.<br><br>',
      attachTo: {element: '.errorBtn', on: 'bottom'},
      canClickTarget: true,
      advanceOn: {selector: '.errorBtn', event: 'click'},
    }, {
      id: 'stepError2',
      title: 'Back to the drawing board',
      text: 'If you are sure that everything is correct - try reloading the page if this happens again.<br><br>',
      canClickTarget: false,
      buttons: [
        {action() {
          return this.show('step2');
        }, text: 'Start again'},
      ],
    }, {
      id: 'step8',
      title: 'Your Message Queue',
      text: 'After adding a message to your queue it will be displayed here.<br><br>\
      Here you would be able to review your messages, add more messages, remove single messages, remove your entire queue or submit your queue to be sent.<br><br>',
      attachTo: {element: '.sms8', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step9',
      title: 'Remove A Single Message',
      text: `If you want to remove a single message from your queue you can simply click on "Remove".<br><br>`,
      attachTo: {element: '.sms9', on: 'left'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.back();
        }, text: 'Back'},
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step10',
      title: 'Submit My Queue',
      text: `Finally, when you are happy with your queue you would click on "Submit My Queue".<br><br>\
      <i>This will submit all messages contained in your queue to be processed to be sent on the date and time you chose.</i><br><br>\
      <i>I don't want to waste your credits so we will not be clicking on this button now.</i><br><br>`,
      attachTo: {element: '.sms10', on: 'top'},
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [
        {action() {
          return this.back();
        }, text: 'Back'},
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step11',
      title: 'Remove Entire Queue',
      text: 'If you want to remove all messages from your queue you would simply click on "Remove My Queue".<br><br>',
      attachTo: {element: '.sms11', on: 'top'},
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [
        {action() {
          return this.back();
        }, text: 'Back'},
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step12',
      title: 'Well Done! (1/4)',
      text: 'You have just successfully completed Part 1: Sending Single SMS Message.<br><br>\
      <i>This concludes Part 1/4 of <strong>this</strong> tutorial; so don\'t end now! I highly recommend that you complete all 4 Parts of <strong>this</strong> tutorial.</i><br><br>\
      <mark>- Click on "To Phone Books" to start part 2 of <strong>this</strong> tutorial.</mark>',
      buttons: [
        {action() {
          return this.complete();
        }, text: 'Part 2: Phone Books'},
      ],
    }, {
      id: 'step13',
      title: 'It\'s Okay...',
      text: 'You can return to this tutorial at any time and complete it when you are ready.<br><br>\
      <mark>- Click on "To Phone Books" to start part 2 of <strong>this</strong> tutorial.</mark>',
      buttons: [
        {action() {
          return this.complete();
        }, text: 'Part 2: Phone Books'},
      ],
    },
    ]);


    smsTour.start();
  }
}

