import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

const baseUrl = environment.randomWinUrl;

@Injectable({
  providedIn: 'root',
})
export class RandomWinnerService {
  token = localStorage.getItem('Authorization');

  httpOptions = {
    headers: {},
  };


  constructor(private httpClient: HttpClient) {}

  getActiveCampaigns() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.get(baseUrl, this.httpOptions);
    } return;
  }

  getRandomWinners(info) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl, info, this.httpOptions);
    } return;
  }

  getFirstWinners(info) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + '/first', info, this.httpOptions);
    } return;
  }

  emailRandomWinners(info) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + `/email`, info, this.httpOptions);
    } return;
  }
}
