/* eslint-disable linebreak-style */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

const baseUrl = environment.smsUrl + '/send';

@Injectable({
  providedIn: 'root',
})
export class SmsSendSingleService {
  token = localStorage.getItem('Authorization');

  httpOptions = {
    headers: {},
  };


  constructor(private httpClient: HttpClient) {}

  sendSmsToQueue(content) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + '/single', content, this.httpOptions);
    } return;
  }


  sendSmsToCampaign(content) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + '/campaign', content, this.httpOptions);
    } return;
  }
}
