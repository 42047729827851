import {Component, OnInit, OnDestroy, ViewChild, HostListener, EventEmitter, Output, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import {ExcelService} from '../../../../../services/excel.service';
import {ConfirmComponent} from '../../../../../modals/other/confirm/confirm.component';
import {SuccessModalComponent} from '../../../../../modals/other/success-modal/success-modal.component';
import {MdbTablePaginationComponent, MdbTableDirective} from 'ng-uikit-pro-standard';
import {PhonebookService} from '../../../../../services/phonebook.service';


@Component({
  selector: 'app-phonebook',
  templateUrl: './phonebook.component.html',
  styleUrls: ['./phonebook.component.scss'],
})
export class PhonebookComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() onEditPhonebook = new EventEmitter<{
    edit: boolean, phonebookName: string, phonebookId: number, col1: string, col2: string, col3: string, col4: string
  }>();
  @ViewChild(MdbTablePaginationComponent, {static: true}) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, {static: true}) mdbTable: MdbTableDirective;
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  public phonebooks: Array<any>;
  private phonebookToExport2: Array<any>;

  public submitted: boolean;
  public edit: boolean;

  public searchText: string;
  public previous: string;

  private tempArr: any;
  private resp: any;
  private phonebookToDelete: any;
  private phonebookToEdit: any;
  private phonebookToExport: any;


  private phonebookName: string;
  private expCol1: string;
  private expCol2: string;
  private expCol3: string;
  private expCol4: string;


  constructor(
    private phonebookService: PhonebookService,
    private cdRef: ChangeDetectorRef,
    private modalService: MDBModalService,
    private excelService:ExcelService,
  ) { }

  @HostListener('input') oninput() {
    this.mdbTablePagination.searchText = this.searchText;
  }


  ngOnInit() {
    this.phonebooks = [];

    this.searchText = '';
    this.previous = '';

    this.submitted = false;
    this.edit = false;

    this.tempArr = {};
    this.resp = {};
    this.phonebookToDelete = {};
    this.phonebookToEdit = {};
    this.phonebookToExport = {};
    this.phonebookToExport2 = [];

    this.phonebookName = '';
    this.expCol1 = '';
    this.expCol2 = '';
    this.expCol3 = '';
    this.expCol4 = '';
    this.getPhoneBooks();
  }

  getPhoneBooks() {
    this.phonebookService.getUserPhonebooksAndCount()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.tempArr = data;
              for (let index = 0; index < this.tempArr.length; index++) {
                const element = this.tempArr[index];
                this.phonebooks[index] = element;
              }
              this.tempArr = [];

              this.mdbTable.setDataSource(this.phonebooks);
              this.phonebooks = this.mdbTable.getDataSource();
              this.previous = this.mdbTable.getDataSource();
            },
        );
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.phonebooks = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.phonebooks = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.mdbTable.searchDataObservable(this.searchText).subscribe(() => {
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
    });
  }

  remove(id: any) {
    this.phonebookToDelete = {
      ucgoup_id: this.phonebooks[id].ucgoup_id,
    };
    this.phonebookService.deletePhonebook(this.phonebookToDelete)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data) => {
          this.phonebooks.splice(id, 1);
          this.openModalSuccessDelete();
          this.ngOnInit();
        });
  }

  onEdit(id: any) {
    this.onEditPhonebook.emit({
      edit: true,
      phonebookName: this.phonebooks[id].ucgoup_name,
      phonebookId: this.phonebooks[id].ucgoup_id,
      col1: this.phonebooks[id].ucgroup_col1,
      col2: this.phonebooks[id].ucgroup_col2,
      col3: this.phonebooks[id].ucgroup_col3,
      col4: this.phonebooks[id].ucgroup_col4,
    });
    window.scroll(0, 0);
  }


  onExport(id: any) {
    this.phonebookName = this.phonebooks[id].ucgoup_name;
    this.phonebookToExport = {ucgoup_id: this.phonebooks[id].ucgoup_id};
    this.phonebookService.getPhonebookContacts(this.phonebookToExport)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              for (let index = 0; index < this.resp.count; index++) {
                const element = this.resp.rows[index];
                this.phonebookToExport2[index] = {
                  'Contact Number': element.contact_cell,
                  [this.phonebooks[id].ucgroup_col1]: element.contact_col1,
                  [this.phonebooks[id].ucgroup_col2]: element.contact_col2,
                  [this.phonebooks[id].ucgroup_col3]: element.contact_col3,
                  [this.phonebooks[id].ucgroup_col4]: element.contact_col4,
                };
              }
              this.resp = {};
              this.excelService.exportAsExcelFile(this.phonebookToExport2, 'Phonebook_'+this.phonebookName);
              this.phonebookToExport2 = [];
              this.ngOnInit();
            },
        );
  }

    modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-dialog modal-notify modal-info',
      containerClass: 'modal fade',
      animated: true,
      data: {
        heading: '',
        content: {heading: '', amount: 0, description: '', btnText: ''},
      },
    }

    openModalConfirmDelete(id: any) {
      this.modalOptions.data = {
        heading: 'Really remove this phonebook?',
        content: {
          heading: 'Are you sure you want to permanently remove this phonebook, which you have cleverly named: ',
          amount: 0,
          description: '"'+ this.phonebooks[id].ucgoup_name +'"? This is a permanent action! All contacts contained herein will be lost forever...',
          btnText: 'I really want to',
        },
      };
      this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
      this.modalRef.content.action
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe( (result: any) => {
            if (result) {
              this.remove(id);
            }
          });
    }


    modalOptionsSuccess = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-dialog modal-notify modal-success',
      containerClass: 'modal fade',
      animated: true,
      data: {
        heading: '',
        content: {heading: '', btnText: ''},
      },
    }

    openModalSuccessDelete() {
      this.modalOptionsSuccess.data = {
        heading: 'Taken care of!',
        content: {
          heading: 'The phonebook in question has ceased to exist. I have taken care of every single one of its contacts myself.',
          btnText: 'Good riddance',
        },
      };
      this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
    }

    ngOnDestroy(): any {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }

    ngAfterViewInit() {
      this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
      this.cdRef.detectChanges();
    }
}
