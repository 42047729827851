/* eslint-disable max-len */
import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';

import {LoaderService} from '../../services/loader.service';
import {RegisterService} from '../../register.service';
import {SuccessModalComponent} from '../../modals/other/success-modal/success-modal.component';
import {ErrorComponent} from '../../modals/other/error/error.component';

import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-register-login',
  templateUrl: './register-login.component.html',
  styleUrls: ['./register-login.component.scss'],
})
export class RegisterLoginComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  public modalRef2: MDBModalRef;
  registerForm: FormGroup;
  public submitted: boolean;
  public usernameTaken: boolean;
  private tempEmail: string;
  public tempUsername: string;
  public loading: boolean;
  public loadingtext: string;
  private checkUsername: string;
  private resp: any;


  constructor(
    public modalRef: MDBModalRef,
    private fb: FormBuilder,
    private registerService: RegisterService,
    private modalService: MDBModalService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }


  ngOnInit() {
    this.loading = false;
    this.loadingtext = '';
    this.usernameTaken = false;
    this.submitted = false;
    this.tempEmail = '';
    this.tempUsername = '';
    this.checkUsername = '';
    this.resp = '';

    this.registerForm = this.fb.group({
      user_name: ['', [Validators.required, Validators.maxLength(20), Validators.pattern(/^([0-9]|[a-z]|[A-Z])*$/)]],
      user_pw: ['', [Validators.required, Validators.minLength(6)]],
      user_pName: ['', [Validators.required, Validators.maxLength(50)]],
      user_pEmail: ['', [Validators.required, Validators.maxLength(100), Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)]],
      user_pCell: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern(/^(\+[0-9]|[0-9])*$/)]],
      user_tradingName: ['', [Validators.required, Validators.maxLength(50)]],
      checkedTnc: [false, Validators.requiredTrue],
      checkedCoc: [false, Validators.requiredTrue],
    });
  }

  get p() {
    return this.registerForm.controls;
  }
  get user_name() {
    return this.registerForm.get('user_name');
  }
  get user_pw() {
    return this.registerForm.get('user_pw');
  }
  get user_pName() {
    return this.registerForm.get('user_pName');
  }
  get user_pEmail() {
    return this.registerForm.get('user_pEmail');
  }
  get user_pCell() {
    return this.registerForm.get('user_pCell');
  }
  get user_tradingName() {
    return this.registerForm.get('user_tradingName');
  }

  onSubmit() {
    this.submitted = true;

    // stop if invalid
    if (this.registerForm.invalid) {
      this.openModalError();
      return;
    }

    this.registerNewUser();
  }

  onBlurUsername() {
    this.loadingtext = 'Checking Username...';
    this.checkUsername = this.user_name.value;
    this.registerService.checkUsername({username: this.checkUsername})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data) => {
          this.resp = data;
          if (this.resp.error) {
            this.usernameTaken = true;
          } else {
            this.usernameTaken = false;
          }
        });
  }

  onKeydown(event) {
    this.usernameTaken = false;
  }

  registerNewUser() {
    this.loadingtext = 'Registering Account...';
    this.tempEmail = this.user_pEmail.value;
    this.registerService.sendMessage(this.registerForm.value)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data) => {
          this.modalRef.hide();
          this.openModalSuccess();
          this.usernameTaken = false;
        }, (error) => {
          this.usernameTaken = true;
          this.tempUsername = this.user_name.value;
          this.openModalErrorUsernameTaken();
        });
  }

  get r() {
    return this.registerForm.controls;
  }


  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', btnText: ''},
    },
  }


  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Registration Successful!',
      content: {
        heading: 'Everything looks good! Your activation code has been sent to: ' + this.tempEmail + '. Please check your inbox. You will be prompted to provide your activation code on your first login.',
        btnText: 'Awesome',
      },
    };
    this.modalRef2 = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }


  modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
    },
  }


  openModalError() {
    this.modalOptionsError.data = {
      heading: 'Oops...',
      content: {
        heading: 'Please ensure that you have provided all required details before proceeding.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure all required fields are provided.',
        fix2: 'Ensure that all fields are valid.',
        fix3: 'Look for the red text indicating errors.',
        btnText: 'lets see',
      },
    };
    this.modalRef2 = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }


  openModalErrorUsernameTaken() {
    this.modalOptionsError.data = {
      heading: 'Quite the popular username...',
      content: {
        heading: 'The username that you have chosen appears to be currently in use.',
        suggest: 'Suggested Actions:',
        fix1: 'Try to add slight variations i.e. ' + this.user_name.value + '123, ' + this.user_name.value + 'abc',
        fix2: 'Try using a different username.',
        fix3: 'Then try again',
        btnText: 'lets see',
      },
    };
    this.modalRef2 = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
