import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {TemplatesSmsService} from '../../../services/templates-sms.service';
import {LoaderService} from '../../../services/loader.service';

const gsmChar = [
  '@', '£', '$', '¥', 'è', 'é', 'ù', 'ì',
  'ò', 'Ç', '\n', 'Ø', 'ø', '\r', 'Å', 'å',
  'Δ', '_', 'Φ', 'Γ', 'Λ', 'Ω', 'Π', 'Ψ',
  'Σ', 'Θ', 'Ξ', 'Æ', 'æ', 'ß', 'É',
  '!', '"', '#', '¤', '%', '&', '\'',
  '(', ')', '*', '+', ',', '-', '.', '/',
  '0', '1', '2', '3', '4', '5', '6', '7',
  '8', '9', ':', ';', '<', '=', '>', '?',
  '¡', 'A', 'B', 'C', 'D', 'E', 'F', 'G',
  'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O',
  'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W',
  'X', 'Y', 'Z', 'Ä', 'Ö', 'Ñ', 'Ü', '§',
  '¿', 'a', 'b', 'c', 'd', 'e', 'f', 'g',
  'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o',
  'p', 'q', 'r', 's', 't', 'u', 'v', 'w',
  'x', 'y', 'z', 'ä', 'ö', 'ñ', 'ü', 'à',
  '^', '{', '}', '\\', '[', ']', '~', '|', '€', ' ',
];

@Component({
  selector: 'app-template-modal',
  templateUrl: './template-modal.component.html',
  styleUrls: ['./template-modal.component.scss'],
})
export class TemplateModalComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  action: Subject<any> = new Subject();

  templateForm: FormGroup;
  public content: {
    template_id: number,
    template_name: string,
    template_text: string
  }

  public title: string;
  public buttonText: string;
  public submitted: boolean;
  public loading: boolean;
  public loadingtext: string;
  private payload: any;
  private payload2: any;
  private resp: any;

  public specialChars: number;
  public specialCharArr: Array<any>;
  public hasSpecialChars: boolean;
  private msgProcessText: string;

  constructor(
    public modalRef: MDBModalRef, private fb: FormBuilder,
    private templateService: TemplatesSmsService,
    private modalservice: MDBModalService,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }

  ngOnInit() {
    this.msgProcessText = '';
    this.hasSpecialChars = false;
    this.specialCharArr = [];
    this.specialChars = 0;
    this.resp = {};
    this.payload = {};
    this.payload2 = {};
    this.loadingtext = '';
    this.templateForm = this.fb.group({
      template_name: [this.content.template_name, [Validators.required, Validators.maxLength(250)]],
      template_text: [this.content.template_text, [Validators.required, Validators.maxLength(160)]],
    });
  }

  get p() {
    return this.templateForm.controls;
  }
  get template_name() {
    return this.templateForm.get('template_name');
  }
  get template_text() {
    return this.templateForm.get('template_text');
  }


  onSubmitTemplate() {
    this.submitted = true;

    if (this.template_name.invalid) {
      return;
    }
    if (this.template_text.invalid) {
      return;
    }
    if (this.hasSpecialChars) {
      window.alert('Error SPecial Chars');
      return;
    }

    if (this.content.template_id === 0) {
      this.payload = {
        template_name: this.template_name.value,
        template_text: this.template_text.value,
      };

      this.loadingtext = 'Creating New Template...';
      this.templateService.createSmsTemplate(this.payload)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
              (data) => {
                this.resp = data;
                this.action.next(true);
                this.modalRef.hide();
              }, (error) => {
                return;
              },
          );
    } else {
      this.payload = {
        template_id: this.content.template_id,
        template_name: this.template_name.value,
        template_text: this.template_text.value,
      };
      this.loadingtext = 'Updating Template...';
      this.templateService.updateSmsTemplate(this.payload)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
              (data) => {
                this.resp = data;
                this.action.next(true);
                this.modalRef.hide();
              }, (error) => {

              },
          );
    }
  }

  onBlurCheckForSpecialCharacters() {
    this.specialChars = 0;
    this.specialCharArr = [];
    this.hasSpecialChars = false;
    if (this.template_text.value) {
      this.specialChars = 0;
      this.specialCharArr = [];
      this.msgProcessText = this.template_text.value;
      this.hasSpecialChars = false;

      for (let index = 0; index < this.msgProcessText.length; index++) {
        const element = this.msgProcessText.charAt(index);

        if (gsmChar.indexOf(element) === -1) {
          this.specialChars++;
          this.specialCharArr.push(element);
          this.hasSpecialChars = true;
        }
      }

      if (this.hasSpecialChars) {
        window.alert('error special chars');
      }
    }
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
