
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-navbar-landing',
  templateUrl: './navbar-landing.component.html',
  styleUrls: ['./navbar-landing.component.scss'],
})
export class NavbarLandingComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
