import {Component, OnInit} from '@angular/core';
import {MDBModalRef} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  heading = '';
  content = {
    heading: '',
    suggest: '',
    fix1: '',
    fix2: '',
    fix3: '',
    btnText: '',
  }

  constructor(public frame: MDBModalRef) {}

  ngOnInit() {
  }
}
