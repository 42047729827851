import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {OptOutService} from '../../../services/optout.service';
import {LoaderService} from '../../../services/loader.service';

@Component({
  selector: 'app-new-optout-modal',
  templateUrl: './new-optout-modal.component.html',
  styleUrls: ['./new-optout-modal.component.scss'],
})
export class NewOptoutModalComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  action: Subject<any> = new Subject();

  newListForm: FormGroup;
  public buttonText: any;
  public submitted: boolean;
  public loading: boolean;
  private payload: any;
  private payload2: any;
  private resp: any;

  constructor(public modalRef: MDBModalRef, private fb: FormBuilder, private optoutService: OptOutService, private modalService: MDBModalService,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }


  ngOnInit() {
    this.resp = {};
    this.payload2 = {};
    this.submitted = false;
    this.newListForm = this.fb.group({
      ougr_name: ['', [Validators.required, Validators.maxLength(50)]],
    });
  }

  get p() {
    return this.newListForm.controls;
  }
  get ougr_name() {
    return this.newListForm.get('ougr_name');
  }

  onCreateList() {
    this.submitted = true;

    if (this.ougr_name.invalid) {
      return;
    }

    this.payload = {
      ougr_name: this.ougr_name.value,
    };
    this.optoutService.createNewList(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.action.next(true);
              this.modalRef.hide();
            },
        );
  }

  modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
    },
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
