import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';

import {ConfirmComponent} from '../../modals/other/confirm/confirm.component';
import {SuccessModalComponent} from '../../modals/other/success-modal/success-modal.component';
import {ErrorComponent} from '../../modals/other/error/error.component';
import {UserService} from '../../services/user.service';
import {LoaderService} from '../../services/loader.service';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss'],
})
export class PersonalDetailsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  private accountDetails: any;
  modalRef: MDBModalRef;

  personalDetails: FormGroup;
  public submitted: boolean;
  public tutorialActive: Boolean;
  public loading: boolean;
  public loadingfail: boolean;

  // public user_name : string;

  constructor(
    private accountDetailService: UserService,
    private fb: FormBuilder,
    private modalService: MDBModalService,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }

  ngOnInit() {
    this.submitted = false;
    this.loadingfail = false;
    // this.user_name = '';
    this.accountDetails = {};
    this.personalDetails = this.fb.group({
      user_pName: ['', [Validators.required, Validators.maxLength(50)]],
      user_pEmail: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
      user_pCell: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern(/^(\+[0-9]|[0-9])*$/)]],
      user_tradingName: ['', [Validators.required, Validators.maxLength(50)]],
      user_vatNum: ['', [Validators.maxLength(10), Validators.minLength(10), Validators.pattern(/^([0-9]|[0-9])*$/)]],
    });
    this.loadAccountDetails();
  }

  loadAccountDetails() {
    this.accountDetailService.getAccountDetails()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.accountDetails = data;
              this.personalDetails.setValue({
                user_pName: this.accountDetails.user.user_pName,
                user_pEmail: this.accountDetails.user.user_pEmail,
                user_pCell: this.accountDetails.user.user_pCell,
                user_tradingName: this.accountDetails.user.user_tradingName,
                user_vatNum: this.accountDetails.user.user_vatNum,
              });
              // this.user_name = this.accountDetails.user.user_name;
            },
            (err) => {
              this.loadingfail = true;
            },
        );
  }

  updateAccountDetails() {
    this.accountDetailService.updateAccountDetails(this.personalDetails.value)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data) => {
          this.openModalSuccess();
          this.ngOnInit();
        },
        );
  }

  get p() {
    return this.personalDetails.controls;
  }


  onSubmitDetails() {
    this.submitted = true;
    if (this.personalDetails.invalid) {
      this.openModalError();
      return;
    }
    this.openModalConfirm();
  }

  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', amount: 0, description: '', btnText: ''},
    },
  }

  openModalConfirm() {
    this.modalOptions.data = {
      heading: 'Are you sure?',
      content: {
        heading: 'Are you sure you want to save all changes',
        amount: 0,
        description: ' to your profile?',
        btnText: 'Yes',
      },
    };
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.updateAccountDetails();
          }
        });
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', btnText: ''},
    },
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Update Successful',
      content: {
        heading: 'Your profile was updated successfully',
        btnText: 'Thank you',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }

  modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
    },
  }

  openModalError() {
    this.modalOptionsError.data = {
      heading: 'Oops...',
      content: {
        heading: 'Please ensure that you have provided all required details before proceeding.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure all required fields are provided.',
        fix2: 'Look for the red text to guide you.',
        fix3: 'Then try again.',
        btnText: 'lets see',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }


  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {

  }
}
