import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-reply-routing',
  templateUrl: './reply-routing.component.html',
  styleUrls: ['./reply-routing.component.scss'],
})
export class ReplyRoutingComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
