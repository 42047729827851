
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-price-bulk',
  templateUrl: './price-bulk.component.html',
  styleUrls: ['./price-bulk.component.scss'],
})
export class PriceBulkComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
