/* eslint-disable no-invalid-this */
import {Component, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DatePipe} from '@angular/common';
import {SmsScheduledUnnamedService} from '../../../services/sms-scheduled-unnamed.service';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import {LoaderService} from '../../../services/loader.service';
import {ConfirmComponent} from '../../../modals/other/confirm/confirm.component';
import {SuccessModalComponent} from '../../../modals/other/success-modal/success-modal.component';


@Component({
  selector: 'app-unnamed',
  templateUrl: './unnamed.component.html',
  styleUrls: ['./unnamed.component.scss'],
})
export class UnnamedComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  private resp: any;
  private messageContent: any = [];
  public messageQueue: any = [];

  public messagesScheduled: number;
  public idOfSelected: number;
  public totalMsgCount: number;


  public loading: boolean;
  public loadingfail: boolean;


  pipe = new DatePipe('en-ZA'); // Use your own locale
  now = Date.now();
  scheduledDate = this.pipe.transform(this.now, 'yyyy-MM-dd HH:mm:ss');


  headMessageQueue = ['Cell Number', 'Message', 'Date to Send', 'Time To Send', '', ''];

  constructor(
    private smsService: SmsScheduledUnnamedService,
    private modalService: MDBModalService,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }


  ngOnInit() {
    this.loading = false;
    this.loadingfail = false;
    this.resp = {};
    this.messageContent = [];
    this.messagesScheduled = 0;
    this.idOfSelected = 0;
    this.totalMsgCount = 0;
    this.loadScheduledMessages();
  }


  loadScheduledMessages() {
    this.smsService.getAllScheduledSms()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              for (let index = 0; index < this.resp.count; index++) {
                const element = this.resp.rows[index];
                this.messageContent[index] = element;
                this.messageContent[index].sms_dateToSend = this.pipe.transform(element.sms_dteTime2Send, 'yyyy-MM-dd');
                this.messageContent[index].sms_timeToSend = this.pipe.transform(element.sms_dteTime2Send, 'HH:mm:ss');
              }
              this.messageQueue = this.messageContent;
              this.totalMsgCount = this.messageQueue.length;
              this.messageContent = [];
            }, (error) => {
              this.loadingfail = true;
            },
        );
  }

  onDeleteOne(id) {
    this.idOfSelected = this.messageQueue[id].sms_id;
    this.messageContent = {
      sms_id: this.idOfSelected,
    };
    this.smsService.deleteScheduledSms(this.messageContent)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data) => {
          this.openModalSuccessDelete();
          this.messageQueue.splice(id, 1);
          this.totalMsgCount--;
        });
  }


  onSendOneNow(id) {
    this.idOfSelected = this.messageQueue[id].sms_id;
    this.messageContent = {
      sms_id: this.idOfSelected,
    };
    this.smsService.SendNowScheduledSms(this.messageContent)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data) => {
          this.openModalSuccess();
          this.messageQueue.splice(id, 1);
          this.totalMsgCount--;
        });
  }

  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', amount: 0, description: '', btnText: ''},
    },
  }

  openModalConfirm(id: any) {
    this.modalOptions.data = {
      heading: 'Send this message now?',
      content: {
        heading: 'Please confirm that you want to reschedule this ',
        amount: 1,
        description: ' message to be sent now?',
        btnText: 'Confirm',
      },
    };
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.onSendOneNow(id);
          }
        });
  }

  openModalConfirmDelete(id: any) {
    this.modalOptions.data = {
      heading: 'Really remove this message?',
      content: {
        heading: 'Are you sure you want to permanently remove this ',
        amount: 1,
        description: ' message, never to be heard from again?',
        btnText: 'Remove it',
      },
    };
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.onDeleteOne(id);
          }
        });
  }


  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', btnText: ''},
    },
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Done and done!',
      content: {
        heading: 'That message you wanted me to send has been taken care of - It\'s almost never too late to reschedule.',
        btnText: 'Super',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }

  openModalSuccessDelete() {
    this.modalOptionsSuccess.data = {
      heading: 'Rooted out!',
      content: {
        heading: 'That message you wanted me to remove has been successfully eradicated.',
        btnText: 'Next',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  //  ,{
  //   id: '',
  //   title: '',
  //   text: '.<br><br>\
  //   .<br><br>\
  //   <i>- </i><br><br>\
  //   <mark>- </mark>',
  //   attachTo: {element: '.t', on: 'top'},
  //   advanceOn: {selector: '.', event: 'click'},
  //   canClickTarget: false,
  //   modalOverlayOpeningPadding: 10,
  // beforeShowPromise: function() {
  //   return new Promise(function(resolve) {
  //     setTimeout(function() {
  //       resolve();
  //     }, 200);
  //   });
  // },
  //   buttons: [
  //     {action() {return this.next();},text: 'Next',}
  //   ],
  // }

  ngAfterViewInit() {

  }
}
