import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {MDBModalRef} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  action: Subject<any> = new Subject();

  headElements = ['No', 'Description', 'Qty', 'Unit Price', 'Total (Incl VAT)'];
  content: {
    eft_personName: string, eft_phone: string, eft_cell: string, eft_email: string,
    eft_dteCreated: Date, eft_id: string, user_name: string, eft_VatNo: string,
    smsp_desc: string, smsp_unitPrice: number, eft_phyAddr: string
  };
  submitted = false;
  public scale = 0.60;
  public emailSent;

  constructor(public modalRef: MDBModalRef) {}

  ngOnInit() {
    this.emailSent = false;
  }

  sendInvoiceAsEmail() {
    window.alert('(?Placeholder for email sent confirmation?)');
    this.emailSent = true;
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
