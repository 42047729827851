import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

const baseUrl = environment.smsUrl + '/templates';

@Injectable({
  providedIn: 'root',
})
export class TemplatesSmsService {
  token = localStorage.getItem('Authorization');

  httpOptions = {
    headers: {},
  };

  constructor(private httpClient: HttpClient) {}

  getAllSmsTemplates() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.get(baseUrl, this.httpOptions);
    } return;
  }

  updateSmsTemplate(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.put(baseUrl, payload, this.httpOptions);
    } return;
  }

  createSmsTemplate(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl, payload, this.httpOptions);
    } return;
  }

  removeSmsTemplate(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + '/remove', payload, this.httpOptions);
    } return;
  }
}
