import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

const apiURL = environment.adminUrl;
@Injectable({
  providedIn: 'root',
})
export class AdminService {
  token = localStorage.getItem('Authorization');

  httpOptions = {
    headers: {},
  };

  constructor(private httpClient: HttpClient) {}

  // //////////////////////////////////////////////////////////////////////////
  // ADMIN // USERS /////////////////////////////////////////////////////////
  // ////////////////////////////////////////////////////////////////////////
  // GET ALL USERS //
  getAllUsers() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.get(apiURL + '/users', this.httpOptions);
    } return;
  }

  // //////////////////////////////////////////////////////////////////////////
  // ADMIN // DEALERS /////////////////////////////////////////////////////////
  // ////////////////////////////////////////////////////////////////////////
  // GET ALL DEALERS //
  getAllDealers() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.get(apiURL + '/dealers', this.httpOptions);
    } return;
  }

  // GET DETAILS OF SELECTED USER [user_id | ALL DETAILS]
  getSelectedUserDetails(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(apiURL + '/users', id, this.httpOptions);
    } return;
  }

  // GET DETAILS OF SELECTED USER [user_id | ALL DETAILS]
  getSelectedDealerDetails(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(apiURL + '/dealers', id, this.httpOptions);
    } return;
  }

  // GET DETAILS OF SELECTED USER [user_id | ALL DETAILS]
  getSelectedCustomerDetails(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(apiURL + '/dealers/customers', id, this.httpOptions);
    } return;
  }

  // UPDATE DETAILS OF SELECTED USER [ALL DETAILS | confirmation]
  updateSelectedUserDetails(details) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.put(apiURL + '/users', details, this.httpOptions);
    } return;
  }
  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ADMIN // CAMPAIGNS ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // GETS ALL SELECTED USER CAMPAIGNS
  getUserCampaigns(user_id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(apiURL + '/campaigns', user_id, this.httpOptions);
    } return;
  }


  // GET SELECTED CAMPAIGN DETAILS
  getSelectedCampaignDetails(camp_id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(apiURL + '/campaigns/details', camp_id, this.httpOptions);
    } return;
  }


  // GET SELECTED CAMPAIGN DETAILS
  getCheckIfShortcodeSharedOrDedicated(sc_id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(apiURL + '/campaign/shortcode/check', sc_id, this.httpOptions);
    } return;
  }


  // REPLACE CURRENT REPORT DAYS FOR USER CAMPAIGN [camp_id, camp_rep_hour, camp_rep_day | confirmation]
  setNewCampaignReportDays(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(apiURL + '/campaigns/report', payload, this.httpOptions);
    } return;
  }

  // DELETES SELECTED REPORT DAY FROM CAMPAIGN [camp_rep_id | confirmation]
  deleteOneReportDay(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.put(apiURL + '/campaigns/report', payload, this.httpOptions);
    } return;
  }


  // TRIES TO ADD ONE KEYWORD TO CAMPAIGN, IF POSSIBLE DO SO []
  tryToAddKeywordToShortcode(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(apiURL + '/campaigns/keyword/add', payload, this.httpOptions);
    } return;
  }


  // DELETES SELECTED KEYWORD FROM CAMPAIGN AND SHORTCODE
  deleteKeywordFromCampaign(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.put(apiURL + '/campaigns/keyword', payload, this.httpOptions);
    } return;
  }


  // UPDATES CAMPAIGN DETAILS ON SUBMIT
  updateUserCampaign(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.put(apiURL + '/campaigns', payload, this.httpOptions);
    } return;
  }


  // CHANGES SHORTCODE IF POSSIBLE (NO KEYWORD CONFLICTS)
  changeShortcodeWithKeywords(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(apiURL + '/campaigns/keyword', payload, this.httpOptions);
    } return;
  }


  // CHANGES SHORTCODE IF POSSIBLE (NO KEYWORD CONFLICTS)
  getSharedAndDedicatedShortcodes(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(apiURL + '/campaigns/shortcode', payload, this.httpOptions);
    } return;
  }


  // ADDS A SINGLE BLACKLIST NUMBER TO THE CAMPAIGN
  addSingleBlacklistNumber(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(apiURL + '/campaigns/blacklist/add', payload, this.httpOptions);
    } return;
  }


  // DELETES A SINGLE BLACKLIST NUMBER TO THE CAMPAIGN
  deleteSingleBlacklistNumber(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.put(apiURL + '/campaigns/blacklist', payload, this.httpOptions);
    } return;
  }


  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ADMIN // SHORTCODES //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  // GETS ALL THE DIFFERENT R VALUES OF SHORTCODES
  getAllShortcodeValues() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.get(apiURL + '/shortcodes', this.httpOptions);
    } return;
  }

  // TRIES TO ADD NEW SHORTCODE, IF NOT EXISTS ADD SHORTCODE
  tryToAddNewShortcode(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(apiURL + '/shortcodes', payload, this.httpOptions);
    } return;
  }

  // UPDATES SELECTED SHORTCODE WITH NEW VALUE
  updateShortcode(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.put(apiURL + '/shortcodes', payload, this.httpOptions);
    } return;
  }

  // DELETES SELECTED SHORTCODE
  deleteShortcode(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.put(apiURL + '/shortcodes/delete', payload, this.httpOptions);
    } return;
  }


  // GETS ALL USERS ASSIGNED TO A DEDICATED SHORTCODE
  getAllUsersAssignedToShortcode(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(apiURL + '/shortcodes/users', payload, this.httpOptions);
    } return;
  }

  // CHECKS IF USER HAS ANY OPEN CAMPAIGNS USING THE SHORTCODE BEFORE UNASSIGNED
  checkForUserOpenCampaigns(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.put(apiURL + '/shortcodes/users', payload, this.httpOptions);
    } return;
  }

  // ASSIGNS DEDICATED SHORTCODE TO USER
  assignUserToShortcode(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(apiURL + '/shortcodes/assign', payload, this.httpOptions);
    } return;
  }

  // GETS CAMPAIGNS ASSIGNED TO SELECTED SHORTCODE
  getCampaignAssignedToShortcode(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(apiURL + '/shortcodes/campaign', payload, this.httpOptions);
    } return;
  }

  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ADMIN // CREDIT PURCHASES ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // GETS ALL PENDING USER CREDIT PURCHASE REQUESTS
  getAllPendingRequests() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.get(apiURL + '/credits', this.httpOptions);
    } return;
  }

  // APPROVES OR REJECTS USER REQUEST AND NOTIFIES VIA EMAIL
  approveOrReject(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(apiURL + '/credits', payload, this.httpOptions);
    } return;
  }


  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ADMIN // CREDIT PURCHASES ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // GETS ALL NON DELETED CREDIT PACKAGES
  getAllCreditPackages() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.get(apiURL + '/credits/packages', this.httpOptions);
    } return;
  }


  // UPDATE SELECTED CREDIT PACKAGE
  updateCreditPackage(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.put(apiURL + '/credits/packages', payload, this.httpOptions);
    } return;
  }


  // DELETE SELECTED CREDIT PACKAGE
  deleteCreditPackage(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.put(apiURL + '/credits/packages/delete', payload, this.httpOptions);
    } return;
  }


  // ADDS A NEWLY CREATED CREDIT PACKAGE
  addNewCreditPackage(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(apiURL + '/credits/packages', payload, this.httpOptions);
    } return;
  }
};

