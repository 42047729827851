/* eslint-disable linebreak-style */
// **********************LIVE***************************
export const environment = {
  production: true,
  orgId: 'panec',
  baseUrl: 'https://chatapi.stouf.com',
  fileUpURL: 'https://chatapi.stouf.com/v1/upload/',
  adminUrl: 'https://chatapi.stouf.com/v1/admin',
  dealerUrl: 'https://chatapi.stouf.com/v1/dealer',
  campaignsUrl: 'https://chatapi.stouf.com/v1/campaigns',
  campaignUrl: 'https://chatapi.stouf.com/v1/campaign',
  smsInUrl: 'https://chatapi.stouf.com/v1/sms_in',
  creditsUrl: 'https://chatapi.stouf.com/v1/credits',
  dashboardUrl: 'https://chatapi.stouf.com/v1/dashboard/campaigns',
  passwordUrl: 'https://chatapi.stouf.com/v1/password/forgot/reset',
  liveReadUrl: 'https://chatapi.stouf.com/v1/campaigns/reader/live',
  liveReadUrl2: 'https://chatapi.stouf.com/v1/report/live/sms/reply',
  navbarUrl: 'https://chatapi.stouf.com/v1/navbar',
  phonebooksUrl: 'https://chatapi.stouf.com/v1/phonebooks',
  randomWinUrl: 'https://chatapi.stouf.com/v1/campaign/randomwinner',
  smsUrl: 'https://chatapi.stouf.com/v1/sms',
  usersUrl: 'https://chatapi.stouf.com/v1/users',
  xlsxReportUrl: 'https://chatapi.stouf.com/v1/report/xlsx',
  contactUsUrl: 'https://chatapi.stouf.com/v1/contact/send',
  optoutUrl: 'https://chatapi.stouf.com/v1/optout',
};
