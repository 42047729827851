import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MDBModalRef} from 'ng-uikit-pro-standard';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {TemplatesSmsService} from '../../../services/templates-sms.service';
import {LoaderService} from '../../../services/loader.service';

@Component({
  selector: 'app-template-select-modal',
  templateUrl: './template-select-modal.component.html',
  styleUrls: ['./template-select-modal.component.scss'],
})
export class TemplateSelectModalComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  action: Subject<any> = new Subject();

  public templateArr: Array<any>;
  public optionsTemplate: Array<any>;

  templateForm: FormGroup;
  public submitted: boolean;
  public loading: boolean;
  private payload: any;
  private resp: any;
  public template_text: string;
  public templateSelected: boolean;


  constructor(
    private templateService: TemplatesSmsService,
    public modalRef: MDBModalRef, private fb: FormBuilder,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }

  ngOnInit() {
    this.optionsTemplate = [];
    this.templateArr = [];
    this.resp = {};
    this.payload = {};
    this.templateSelected = false;

    this.templateForm = this.fb.group({
      template_name: ['', Validators.required],
    });

    this.getAllSmsTemplates();
    this.selectedTemplate();
  }

  get p() {
    return this.templateForm.controls;
  }
  get template_name() {
    return this.templateForm.get('template_name');
  }

  getAllSmsTemplates() {
    this.templateService.getAllSmsTemplates()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              this.templateArr = this.resp.rows;
              for (let index = 0; index < this.resp.count; index++) {
                const element = this.templateArr[index];
                this.optionsTemplate.push({
                  value: element.template_text,
                  label: element.template_name,
                });
              }
            }, (error) => {
              return;
            },
        );
  }

  selectedTemplate() {
    this.templateForm.get('template_name').valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (value) => {
          if ( value === null || value.length === 0) {
            return;
          } else {
            this.template_text = value;
            this.templateSelected = true;
          }
        },
        );
  }

  onSubmitTemplate() {
    this.submitted = true;
    if (this.templateSelected) {
      this.action.next(this.template_text);
      this.modalRef.hide();
    } else {
      return;
    }
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
