import {Component, OnInit, OnDestroy, ElementRef, HostListener, AfterViewInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {MdbTableDirective, MdbTablePaginationComponent} from 'ng-uikit-pro-standard';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {LoaderService} from '../../services/loader.service';
import {DashboardService} from '../../services/dashboard.service';

@Component({
  selector: 'app-campaign-dash',
  templateUrl: './campaign-dash.component.html',
  styleUrls: ['./campaign-dash.component.scss'],
})
export class CampaignDashComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MdbTableDirective, {static: true}) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, {static: true}) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('row', {static: true}) row: ElementRef;


  private ngUnsubscribe: Subject<any> = new Subject();

  public searchText: string;
  public previous: string;

  public loading: boolean;
  public loadingfail: boolean;

  public maxVisibleItems: number;

  public loadedCampaigns: Array<any>;
  // public headCampaigns = ['Campaign Name', 'Date Created', 'Ending Date', 'Status',
  //                  'ShortCode', 'Value', 'Last Keyword', 'Total Entries', 'Unique Numbers'];

  public campaign: {camp_id: number};
  public campaigns: any;


  constructor(
    public router: Router,
    private dashService: DashboardService,
    private cdRef: ChangeDetectorRef,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }

  @HostListener('input') oninput() {
    this.mdbTablePagination.searchText = this.searchText;
  }

  ngOnInit() {
    this.loadingfail = false;
    this.searchText = '';
    this.previous = '';
    this.maxVisibleItems = 8;
    this.loadedCampaigns = [];
    this.campaigns = [];
    this.loadDashboard();
  }

  loadDashboard() {
    this.dashService.getDashContent()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.campaigns = data;
              for (let index = 0; index < this.campaigns.data.length; index++) {
                const element = this.campaigns.data[index];
                this.loadedCampaigns[index] = element;
                if (this.loadedCampaigns[index].Status === 'Trial') {
                  this.loadedCampaigns[index].Status = 'Active';
                } else {
                  this.loadedCampaigns[index].Status = 'Closed';
                }
              }

              this.campaigns = this.loadedCampaigns;
              // console.log(this.campaigns)
              this.loadedCampaigns = [];
              this.mdbTable.setDataSource(this.campaigns);
              this.campaigns = this.mdbTable.getDataSource();
              this.previous = this.mdbTable.getDataSource();
            }, (err) => this.loadingfail = true);
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.campaigns = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.campaigns = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.mdbTable.searchDataObservable(this.searchText).subscribe(() => {
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
    });
  }


  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
}
