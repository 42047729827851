/* eslint-disable max-len */
import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {interval, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CampaignSelectModalComponent} from '../../modals/campaign-select-modal/campaign-select-modal.component';
import {MDBModalRef, MDBModalService, SidenavComponent, SBItemComponent} from 'ng-uikit-pro-standard';
import Shepherd from 'shepherd.js';
import {NavbarService} from '../../services/navbar.service';
import {TutorialService} from '../../services/guide/tutorial.service';
import {SmsGuideComponent} from '../../modals/guide/sms-guide/sms-guide.component';
import {ShortcodeGuideComponent} from '../../modals/guide/shortcode-guide/shortcode-guide.component';
// import { SplashComponent } from "../../modals/splash/splash.component";
import {Router} from '@angular/router';

const Maintour = new Shepherd.Tour({
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
    classes: 'shepherd-button-secondary',
    scrollTo: {behavior: 'smooth', block: 'center'},
  },
  confirmCancel: false,
  // confirmCancelMessage: '',
  exitOnEsc: false,
  keyboardNavigation: false,
  useModalOverlay: true,
});

// TIMER STUFF
// const countDownDate = new Date("Apr 30, 2020 23:59:59").getTime();
// TIMER STUFF

const counter = interval(10000);
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  @ViewChild('sidenav', {static: false}) sidenav: SidenavComponent;
  @ViewChild('SMS', {static: false}) SMS: SBItemComponent;
  @ViewChild('SCC', {static: false}) SCC: SBItemComponent;
  @ViewChild('GNR', {static: false}) GNR: SBItemComponent;
  @ViewChild('E2S', {static: false}) E2S: SBItemComponent;

  private resp;
  public shouldToggle: boolean;
  private user_name = '';
  private uc_amt = '';
  private user_pName = '';
  public user_isFirstTime: number;
  private subscription: Subscription = new Subscription();

  private checker: boolean = false;

  token = localStorage.getItem('Authorization');

  // TIMER STUFF
  // private now = new Date().getTime();
  // private distance: number;
  // private days: number;
  // private hours: number;
  // private minutes: number;
  public timerVal: string = 'Level 4';
  // TIMER STUFF

  // MODAL
  modalRef: MDBModalRef;
  modalRefSplash: MDBModalRef;

  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-lg',
    containerClass: 'modal fade top',
    animated: true,
    data: {
      heading: 'What Campaign Do You Have In Mind?',
      contentKeyword: {heading: 'Shortcode Campaign', description: 'Competitions, Voting lines and so much more!'},
      contentInstant: {heading: 'Instant Winner Campaign', description: 'Give away prizes instantly! Airtime, cash and more!'},
    },
  }

  modalOptionsGuide = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade top',
    animated: true,
  }

  // MODAL END

  clicked: boolean;


  constructor(
    private navbarService: NavbarService,
    private modalService: MDBModalService,
    private router: Router,
    private tutorial: TutorialService,
  ) {
    this.clicked = this.clicked === undefined ? false : true;
  }

  ngOnInit() {
    // this.getTimerValues();
    this.shouldToggle = false;
    this.resp = {};
    this.checkToggle();
    if (this.token !== null) {
      this.loadNavInfo();
      this.subscription = counter
          .subscribe(() => {
            this.onRefreshCredits();
            // this.getTimerValues();
          });
    }
  }

  // getTimerValues() {
  //   this.now = new Date().getTime();
  //   this.distance = countDownDate - this.now;
  //   if (this.distance < 0) {
  //     this.timerVal = "Freedom!"
  //   } else {
  //     this.days = Math.floor(this.distance / (1000 * 60 * 60 * 24));
  //     this.hours = Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  //     this.minutes = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60));
  //     this.timerVal = this.days + 'd ' + this.hours + 'h ' + this.minutes + 'm '
  //   }

  // }

  modalOptionsSplash = {
    backdrop: true,
    keyboard: false,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-side modal-bottom-right modal-notify modal-success',
    containerClass: 'modal fade right modal-scrolling',
    animated: true,
  }

  // openSplashModal() {
  //   this.modalRefSplash = this.modalService.show(SplashComponent,  (this.modalOptionsSplash) );
  // }

  checkToggle() {
    if (navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
    ) {
      // this.sidenav.toggle()
      this.shouldToggle = true;
    } else {
      this.shouldToggle = false;
      return;
    }
  }


  onClick() {
    if (this.shouldToggle) {
      this.sidenav.toggle();
    } else {
      return;
    }
    // this.checkToggle();
  }

  loadNavInfo() {
    this.navbarService.getNavInfo()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              // this.openSplashModal();
              this.resp = data;
              this.user_name = this.resp.user.user_name;
              this.uc_amt = this.resp.user.credit.uc_amt;
              this.user_pName = this.resp.user.user_pName;
              this.user_isFirstTime = this.resp.user.user_isFirstTime;
              if (!this.shouldToggle) {
                if (this.user_isFirstTime === 1) {
                  this.router.navigate(['/dashboard/campaign']), this.mainTour();
                }
              }
              if (this.resp.user.utype_id === 1) {
                this.checker = true;
              } else {
                this.checker = false;
              }
            },
        );
  }

  onRefreshCredits() {
    this.onUnsub();
    this.navbarService.getNavCredits()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              this.uc_amt = this.resp.uc_amt;
            },
        );
  }

  setClicked(val: boolean): void {
    this.clicked = val;
  }

  logOut() {
    localStorage.removeItem('token');
  }

  onClickTourSMS() {
    this.modalRef = this.modalService.show(SmsGuideComponent, (this.modalOptionsGuide) );
  }

  onClickTourShortcode() {
    this.modalRef = this.modalService.show(ShortcodeGuideComponent, (this.modalOptionsGuide) );
  }

  openModal() {
    if (this.shouldToggle) {
      this.sidenav.toggle();
    }
    this.modalRef = this.modalService.show(CampaignSelectModalComponent, (this.modalOptions) );
  }

  onCancelCompleteTour() {
    this.navbarService.updateTutorial()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {

            },
        );
  }

  onUnsub(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnDestroy(): any {
    this.subscription.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  smsTour() {
    this.tutorial.smsTour();
  }

  ngAfterViewInit() {
  }


  mainTour() {
    const that = this;
    Maintour.on('complete', () => {
      that.onCancelCompleteTour();
    });
    Maintour.on('cancel', () => {
      that.onCancelCompleteTour();
    });
    Maintour.addSteps([{
      id: 'start',
      title: 'Welcome to Panec Tours.',
      text: `Hi there <i>` + this.user_name + `</i>! It's always grand to see a new face around these parts.<br><br>
      It seems like this might be your first time using Panec Connect, so I thought it good to quickly show you the ropes.<br><br>
      I will be acting as your personal assistant throughout this informative experience.<br><br>
      In this guided tour we will navigate you through Panec Connect. When you are all set click on "Start Tour".`,
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Start Tour'},
      ],
    }, {
      id: 'step1',
      title: 'All Aboard!',
      text: `Brilliant - I am starting to like you already. ;)<br><br>
      First things first, I hope that you are having an awesome day.</i>`,
      canClickTarget: false,
      buttons: [
        {action() {
          return this.show('happy'); ;
        }, text: 'I am!'},
        {action() {
          return this.show('sad'); ;
        }, text: 'Not Really'},
      ],
    }, {
      id: 'happy',
      title: 'Super!',
      text: `Knowing that you are happy makes my motherboard spark with joy.<br><br>
      Anyway, enough about me and my parts let's get this show on the road.<br><br>
      Click on "Campaign Dashboard" to start.`,
      canClickTarget: false,
      buttons: [
        {action() {
          return this.show('step2');
        }, text: 'Campaign Dashboard'},
      ],
    }, {
      id: 'sad',
      title: 'ahhhhhw...',
      text: `If I could I would give you a hug right now.<br><br>
      Sometimes life hands you a bunch of lemons - you choose how to use said lemons.<br><br>
      Just keep your head up champ, things can only get better from here.<br><br>
      When you are ready to start click on "Campaign Dashboard"`,
      canClickTarget: false,
      buttons: [
        {action() {
          return this.show('step2');
        }, text: 'Campaign Dashboard'},
      ],
    }, {
      id: 'step2',
      title: 'Campaign Dashboard',
      text: `This is your own personal Campaign Dashboard.<br><br>
      Here you can see all of your your campaigns and loads of information about them at a glace.<br><br>
      <i>Every time you log in you will be taken to your dashboard.</i>`,
      attachTo: {element: '.mainS2', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return that.SMS.collapsed = false, that.SMS.toggle(false), this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step4',
      title: 'Moving On',
      text: `Because this is a quick onboarding tour which will navigate through all of Panec Connect with you - we still have quite a few stops ahead of us.<br><br>
      <i>- Consequently I will be keeping each step brief and to the point. <br><br>
      <mark>For more interactive & comprehensive tutorials try the other tutorials <strong>after</strong> completing this one.</mark><br><br>
      Next up is sending SMS.</i><br><br>`,
      canClickTarget: false,
      buttons: [
        {action() {
          return that.router.navigate(['/sms/send/single-sms']), this.next();
        }, text: 'To SMS'},
      ],
    }, {
      id: 'stepInfo',
      title: 'Side Navigation',
      text: `I noticed you noticing this side bar expanding the SMS tab.<br><br>
      As we navigate to all the different pages I will expand the relevant tabs so you can also get a feel for where each page is located.`,
      attachTo: {element: '.sidenav', on: 'right'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step5',
      title: 'Fun Fact: SMS',
      text: `<i>SMS - Stands for "Short Message Service." SMS is used to send text messages to mobile phones.<br><br> 
      The messages can typically be up to 160 characters in length, though some services use 5-bit mode, 
      which supports 224 characters.<br><br>
      SMS was originally created for phones that use GSM (Global System for Mobile) 
      communication, but now all the major cell phone systems support it.<i>`,
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Fun to Know'},
      ],
    }, {
      id: 'step5a',
      title: 'Send Single SMS',
      text: `Here you can manually enter messages and details<br>- Perfect for sending small quantities at a time.<br><br>
      Sending a Single SMS consists of:<br>
      <ul>
      <li>A suitable message.</li>
      <li>A recipient's cellphone number.</li>
      <li>Choosing either to send the message instantly or on a scheduled date and time.</li>
      <li>Messages are then loaded into your <mark>local queue</mark>.
      <ul>
      <li>*Note that you can add multiple messages to your <mark>local queue</mark> before submitting to be sent.</li>
      <li>*Note that your <mark>local queue</mark> still needs to be approved and submitted.</li>
      </ul>
      </li>
      </ul>`,
      attachTo: {element: '.mainS5', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return that.router.navigate(['/sms/send/from-phonebook']), this.next();
        }, text: 'Bulk SMS'},
      ],
    }, {
      id: 'step6',
      title: 'Send to phone books',
      text: `Here you can send a message to one or multiple phone books.<br>
      <i>- This is perfect for sending to specific groups of people.</i><br><br>
      Sending a bulk message to your phone books is easy:<br>
      <ul>
      <li>Provide a suitable message.</li>
      <li>Select one or multiple of your existing phone books.</li>
      <li>Select one or multiple contacts from the selected phone book(s).</li>
      <li>Choose to either send your message(s) instantly or to schedule the send for a specific time and date</li>
      </ul>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS6', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/sms/send/upload-file']), this.next();
        }, text: 'Upload SMS File'},
      ],
    }, {
      id: 'step7',
      title: 'Upload Message File',
      text: `Here you can upload a message file containing a ton of cellphone numbers and their messages.<br><br>
      <i>- We say 'File' but really we mean Excel files (.xlsx or .xls).</i><br><br>
      Sending bulk batches via uploading a file consists of:
      <ul>
      <li>Selecting your Excel file (ColA: Cellphone numbers | ColB: Messages).</li>
      <li>Uploading said Excel file.</li>
      <li>I will then process your file, removing and bad rows along the way.</li>
      <li>You will then be shown a preview of your file (first 10 entries) along with a total count.</li>
      <li>Then you simply submit your queue if you approve.</i>
      </ul>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS7', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/sms/send/shortcode-campaign']), this.next();
        }, text: 'Reply entries'},
      ],
    }, {
      id: 'step8',
      title: 'Send To Shortcode Campaigns',
      text: `Here you can send a bulk message to ALL entries for a chosen campaign.<br>
      <i>- This is ideal for reaching your entire customer base - all at once.</i><br><br>
      Sending bulk batches to Campaign Entries:
      <ul>
      <li>Enter a suitable message for your audience.</li>
      <li>Select which campaign's entries should be the target.</li>
      <li>Choose to either send your batch instantly or to schedule the send for a specific time and date</li>
      </ul>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS8', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/sms/send/phone-book/view']), this.next();
        }, text: 'Phone Book Management'},
      ],
    }, {
      id: 'step9',
      title: 'Your Phone Books',
      text: `Here you can effectively manage all of your phone books.<br><br>
      Managing your phone books consists of:
      <ul>
      <li>A list of all your phone books showing their names and amount of contacts within.</li>
      <li>Remove, Edit and Export your phone books.</li>
      <li>Add, Remove and edit your contacts.</li>
      <li>Contacts can be added manually or by uploading a file.</li>
      </ul>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS9', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/sms/send/phone-book/create']), this.next();
        }, text: 'Create Phone Book'},
      ],
    }, {
      id: 'step10',
      title: 'Create New Phone Book',
      text: `Here you can create your own custom phone books to keep all of your contacts safe.<br><br>
      Creating a new phone book consists of:
      <ul>
      <li>Providing your phone book with a suitable name.</li>
      <li>1x Mandatory filed for cellphone numbers.</li>
      <li>4x Optional customizable fields which you can do with as you please.</li>
      <li>After creating a phone book you can add as many contacts to it as you like on the previous page</li>
      </ul>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS10', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/sms/reports/number-lookup']), this.next();
        }, text: 'SMS Reports'},
      ],
    }, {
      id: 'step11',
      title: 'Number Lookup Report',
      text: `A number lookup report allows you to see all messages to and from a specific number.<br><br>
      Number lookup report consists of:
      <ul>
      <li>Entering the desired number to find.</li>
      <li>Click on "Get Report" Button.</li>
      <li>Results will be displayed in a neat table for your perusal.</li>
      <li>You have the option to export the result set to keep forever.</li>
      </ul>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS11', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/sms/reports/sent-messages']), this.next();
        }, text: 'Sent SMS'},
      ],
    }, {
      id: 'step12',
      title: 'Sent Messages Report',
      text: `Sent Messages Report allows you to get complete details on any messages sent/queued from your account.<br><br>
      Sent Messages report consists of:
      <ul>
      <li>Selecting a desired month (current month & 3x prior months).</li>
      <li>The message delivery stats will display only the totals and status per day.</li>
      <li>You can then download a detailed report for the selected month.</li>
      </ul>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS12', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/sms/reports/reply-messages']), this.next();
        }, text: 'Reply Messages'},
      ],
    }, {
      id: 'step13',
      title: 'Reply Message Report',
      text: `Reply Message Reports allow you to see your reply messages on the fly as they get received.<br><br>
      You also have the option to download a report for a defined date range.<br><br>
      Reply Message report consists of:
      <ul>
      <li>The Reply SMS Live feed which shows reply messages as they get received.</li>
      <li>The detailed report generator which returns a full report for the date range selected.</li>
      <li>You can download said report to see both your outbound messages and replies on them.</li>
      </ul>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS13', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/sms/scheduled/messages/single']), this.next();
        }, text: 'Scheduled Single Messages'},
      ],
    }, {
      id: 'step14',
      title: 'Scheduled Single Messages',
      text: `This page allows you to see and manage all the messages you have scheduled to be sent.<br><br>
      Scheduled Single messages are defined by not having a batch name - Mainly from sending single SMS messages.<br><br>
      Scheduled Single messages consists of:
      <ul>
      <li>A list of all your scheduled single SMS messages.</li>
      <li>You can reschedule messages to be sent instantly.</li>
      <li>You also have the option to remove a faulty message from this queue.</li>
      </ul>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS14', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/sms/scheduled/messages/named']), this.next();
        }, text: 'Scheduled Message Batches'},
      ],
    }, {
      id: 'step15',
      title: 'Scheduled Message Batches',
      text: `This page allows you to see and manage all the message batches that you have scheduled to be sent.<br><br>
      Scheduled Message Batches are defined by having a batch name - Mainly from sending Bulk SMS messages.<br><br>
      Scheduled Message Batches consists of:
      <ul>
      <li>A list of all your scheduled SMS batches.</li>
      <li>You can view each batch's individual messages respectively.</li>
      <li>You have the option to remove single faulty messages from batches respectively.</li>
      <li>You can also reschedule entire batches to be sent at a date & time of your choosing.</li>
      <li>Lastly you can also either schedule batches to be sent instantly or remove it completely</li>
      </ul>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS15', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/sms/templates/manage']), this.next();
        }, text: 'Your SMS Templates'},
      ],
    }, {
      id: 'step15extra',
      title: 'SMS Templates',
      text: `This page displays all of your SMS Templates. You can edit, add or remove any of your templates from here.<br><br>
      SMS Templates are nifty predefined messages which you create. You can effortlessly use them to fill your message field whenever you send SMS.<br><br>
      Your SMS Templates consists of:
      <ul>
      <li>A list of all your SMS Templates.</li>
      <li>Details of each existing Template - Name, Message and character count.</li>
      <li>You have the option to remove, add to or edit your SMS Templates.</li>
      <li>Using your templates is very simple.</li>
      </ul>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS15extra', on: 'bottom'},
      buttons: [
        {action() {
          return that.SMS.collapsed = true, that.SMS.toggle(true),
          that.router.navigate(['/shortcode/campaign/new/keyword']), this.next();
        }, text: 'Shortcodes & Campaigns'},
      ],
    }, {
      id: 'step16',
      title: 'Shortcode',
      text: `That concludes our brief oversight on SMS and it's features.<br><br>
      Now we will be looking at Shortcode and Campaigns.<br><br>
      There are 2x different campaign types:
      <ul>
      <li>Shortcode Campaign: These are generally one-size-fits-all solutions which you can customize to meet your exact requirement.</li>
      <li>Instant Winner Campaign: These need to be set up by our IT team as they require a little more technical setup.</li>
      <li>*To setup an instant winner campaign please contact us.</li>
      </ul>`,
      canClickTarget: false,
      buttons: [
        {action() {
          return that.SCC.collapsed = false, that.SCC.toggle(false), this.next();
        }, text: 'Shortcode Campaign'},
      ],
    }, {
      id: 'step17',
      title: 'New Shortcode Campaign',
      text: `This is your Wizard to create any new Shortcode Campaigns.<br><br>
      You will be guided through the Seven steps to create your own Shortcode Campaign.<br><br>
      Creating a new Shortcode Campaign consists of:
      <ul>
      <li>Basic campaign details - Name, operational dates and description.</li>
      <li>Shortcode and reply messages.</li>
      <li>Keywords used to distinguish entries.</li>
      <li>Setup your reports.</li>
      <li>Setup SMS2Email functionality.</li>
      <li>Blacklist banned numbers from entering.</li>
      <li>And finally reading the Terms & Conditions</li>
      </ul>`,
      canClickTarget: false,
      attachTo: {element: '.mainS17', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/shortcode/campaign/manage']), this.next();
        }, text: 'Manage Campaigns'},
      ],
    }, {
      id: 'step18',
      title: 'Campaign Management',
      text: `Here you can change the details of your campaigns at any time.<br><br>
      <i>*Kindly note that select fields can not be altered after campaign creation ie: campaign start date and shortcode.</i><br><br>
      Managing your Campaigns consists of:
      <ul>
      <li>Mostly similar to creating a new campaign: </li>
      <li>Basic campaign details - Name, operational dates and description.</li>
      <li>Reply messages.</li>
      <li>Keywords used to distinguish entries.</li>
      <li>Manage your reports.</li>
      <li>Manage SMS2Email functionality.</li>
      <li>Manage Blacklisted numbers.</li>
      </ul>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS18', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/shortcode/campaign/live-reader']), this.next();
        }, text: 'Campaign Live Reader'},
      ],
    }, {
      id: 'step19',
      title: 'Campaign Live Reader',
      text: `Your campaign Live Reader will provide you with an interface that displays all your campaign entries as they come in.<br><br>
      <i>*Kindly note that entries will be refreshed every 15 seconds.</i><br><br>
      Campaign Live Reader consists of:
      <ul>
      <li>A live feed of entries across all your campaigns.</li>
      <li>This list will be limited to the latest 1000 entries.</li>
      </ul>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS19', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/shortcode/campaign/random-winner']), this.next();
        }, text: 'Random Winner Generator'},
      ],
    }, {
      id: 'step20',
      title: 'Random Winner Generator',
      text: `This page allows you to generate a list of specified amount of completely random winners from any of your campaigns' entries.<br><br>
      Random Winner Generator consists of:
      <ul>
      <li>Selecting one of your existing campaigns.</li>
      <li>Indicate the amount of winners that you require.</li>
      <li>Then indicate the date range from which to select winners from.</li>
      <li>Your winners will be generated and displayed.</li>
      <li>Finally, you have the option to download your list of winners file.</li>
      </ul>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS20', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/shortcode/campaign/reports/received-sms']), this.next();
        }, text: 'Campaign Reports'},
      ],
    }, {
      id: 'step21',
      title: 'Campaign Entries',
      text: `Here you can instantly download a detailed report containing all entries for a selected campaign.<br><br>
      Campaign Entries consists of:
      <ul>
      <li>Selecting one of your existing campaigns.</li>
      <li>Then click on "Generate Report" button.</li>
      <li>Your report will be created and start to download once finished.</li>
      </ul>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS21', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/shortcode/campaign/reports/report']), this.next();
        }, text: 'Full Campaign Report'},
      ],
    }, {
      id: 'step22',
      title: 'Campaign Report Generator',
      text: `This page generates a full comprehensive report on the selected campaign. <br><br>
      A Full Campaign Report consists of:
      <ul>
      <li>All details of your campaign.</li>
      <li>A breakdown of total entries per network carrier.</li>
      <li>Entries total displayed per hour, daily, weekly and monthly intervals.</li>
      <li>Entry totals per keyword.</li>
      </ul>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS22', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/shortcode/campaign/reports/ticket-usage']), this.next();
        }, text: 'Ticket Usage'},
      ],
    }, {
      id: 'step23',
      title: 'Campaign Ticket Usage',
      text: `Here you can view details related to your ticket usage.<br><br>
      <i>*Kindly note that this feature is only applicable for Instant Winner Campaigns.</i><br><br>
      Campaign Ticket Usage consists of:
      <ul>
      <li>Selecting one of your existing campaigns.</li>
      <li>You will then see the total-, used-, and remaining tickets.</li>
      <li>You can also search for a specific ticket code to see ticket details and winner details.</li>
      </ul>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS23', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/shortcode/campaign/reports/valid-entries']), this.next();
        }, text: 'Valid Entries'},
      ],
    }, {
      id: 'step24',
      title: 'Valid Entries',
      text: `This page generates a detailed report containing all valid entries for a selected campaign.<br><br>
      <i>*Kindly note that this feature is only applicable for Instant Winner Campaigns.</i><br><br>
      Campaign Valid Entries consists of:
      <ul>
      <li>Selecting one of your existing campaigns.</li>
      <li>Then clicking on "Generate Report".</li>
      <li>Once your report has been generated it will start downloading.</li>
      </ul>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS24', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/email2sms/setup']), that.SCC.collapsed = true, that.SCC.toggle(true),
          that.E2S.collapsed = false, that.E2S.toggle(false), this.next();
        }, text: 'Email2SMS'},
      ],
    }, {
      id: 'step24e2s',
      title: 'Email2SMS Setup',
      text: `From this page you can activate and manage your Email2SMS service.<br><br>
      <i>Email2SMS allows you to send emails containing numbers and messages which will then be translated into SMS messages and sent out.</i><br><br>
      Email2SMS consists of:
      <ul>
      <li>Submit a request to opt in for this service.</li>
      <li>Once your request has been approved you will be notified.</li>
      <li>You may then add or remove email addresses allowed to use this service.</li>
      </ul>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS2E', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/email2sms/report']), this.next();
        }, text: 'Email2SMS Usage Report'},
      ],
    }, {
      id: 'step24e2s2',
      title: 'Email2SMS Usage Report',
      text: `Once your Email2SMS service has been activated your usage stats will be displayed on this page.<br><br>
      Email2SMS Usage Report consists of:
      <ul>
      <li>Monthly Email Address SLA.</li>
      <li>Amount of messages send from each registered email address respectively.</li>
      <li>Estimated amount to be billed.</li>
      </ul>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS2E2', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/general/privacy-policy']), that.E2S.collapsed = true, that.E2S.toggle(true), this.next();
        }, text: 'General Stuff'},
      ],
    }, {
      id: 'step25',
      title: 'General Stuff',
      text: `That concludes our brief oversight on Shortcodes & Campaigns.<br><br>
      Next up we have some general pages that are frequently overlooked:
      <ul>
      <li>Privacy Policy.</li>
      <li>Terms and Conditions.</li>
      <li>Frequently Asked Questions.</li>
      </ul>`,
      canClickTarget: false,
      buttons: [
        {action() {
          return that.GNR.collapsed = false, that.GNR.toggle(false), this.next();
        }, text: 'Privacy Policy'},
      ],
    }, {
      id: 'step26',
      title: 'Privacy Policy',
      text: `If you take a minute to read through our Privacy Policy you will notice that we take your privacy very serious.<br><br>
      This page lays out Panec Connect Privacy Policy. You can even download this if you like to download stuff.`,
      canClickTarget: false,
      attachTo: {element: '.mainS26', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/general/terms-and-conditions']), this.next();
        }, text: 'Terms & Conditions'},
      ],
    }, {
      id: 'steploadshow',
      title: 'Can I just say...',
      text: `So far you have been exceptionally pleasant during this tour.<br><br>`,
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'I try'},
      ],
    }, {
      id: 'step27',
      title: 'Terms & Conditions',
      text: `The infamous "I have read the Ts&Cs" - That one white lie everyone tells.<br><br>
      This page lays out Panec Connect Terms and Conditions. You can also download this if TnCs are your type of thing.`,
      canClickTarget: false,
      attachTo: {element: '.mainS27', on: 'bottom'},
      buttons: [
        {action() {
          return that.router.navigate(['/general/frequently-asked-questions']), this.next();
        }, text: 'FAQ'},
      ],
    }, {
      id: 'step28',
      title: 'Frequently Asked Questions',
      text: `If I failed to address any questions that you still might have - You can't go wrong here.<br><br>
      This page lays out Panec Connect FAQ.<br><br>
      <i>- I know what you are thinking: "Can I download this too?" The answer is No, unfortunately you can not download the FAQ because of it's ever-changing nature.</i>`,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 500);
        });
      },
      attachTo: {element: '.mainS28', on: 'bottom'},
      buttons: [
        {action() {
          return that.GNR.collapsed = true, that.GNR.toggle(true), this.next();
        }, text: 'Credit Balance'},
      ],
    }, {
      id: 'step29',
      title: 'Credit Balance',
      text: `Your available credit balance will always be displayed here.<br><br>
      Sending SMS messages require sufficient credits.<br><br>
      <i>To purchase credits you can click here at any time.</i>`,
      canClickTarget: false,
      attachTo: {element: '.mainS29', on: 'bottom'},
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step30',
      title: 'The Basics - Check!',
      text: `Congratulations! You know know the basics of Panec Connect.<br><br>
      For any further information please complete our 3x Interactive Tutorials.<br><br>
      You can find these at the bottom right of the page by clicking on the blue "i".`,
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Outro'},
      ],
    }, {
      id: 'step30',
      title: 'All done!',
      text: `This concludes our guided introduction tutorial.<br><br>
      You have been the best! Thank you for taking a moment to get to know me better.`,
      canClickTarget: false,
      buttons: [
        {action() {
          return this.complete();
        }, text: 'Fin.'},
      ],
    },
    ]);
    Maintour.start();
  }
}
