import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';

import {ConfirmComponent} from '../../../modals/other/confirm/confirm.component';
import {SuccessModalComponent} from '../../../modals/other/success-modal/success-modal.component';
import {ErrorComponent} from '../../../modals/other/error/error.component';
import {InvoiceComponent} from '../../../modals/invoice/invoice.component';

import {CreditsService} from '../../../services/credits.service';
import {UserService} from '../../../services/user.service';
import {DatePipe} from '@angular/common';
import {LoaderService} from '../../../services/loader.service';

@Component({
  selector: 'app-buy-credits',
  templateUrl: './buy-credits.component.html',
  styleUrls: ['./buy-credits.component.scss'],
})
export class BuyCreditsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();

  modalRef: MDBModalRef;

  pipe = new DatePipe('en-ZA'); // Use your own locale

  public colors: Array<any>;
  private packageArr: Array<any>;

  private resp: any;
  private invResp: any;
  private billingDetails: any;
  private requestInfo: any;

  purchaseIndividualForm: FormGroup;
  purchaseCompanyForm: FormGroup;

  public submitted: boolean;
  public billingType: boolean;
  public confirm: boolean;
  public selected: boolean;
  public loading: boolean;
  public loadingfail: boolean;

  selectedPackage: string;


  constructor(
    private creditsService: CreditsService,
    private userService: UserService,
    private fb: FormBuilder,
    private modalService: MDBModalService,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }


  ngOnInit() {
    this.loading = false;
    this.loadingfail = false;
    this.colors = [];
    this.packageArr = [];
    this.resp = {};
    this.billingDetails = {};
    this.requestInfo = {};
    this.submitted = false;
    this.billingType = false;
    this.confirm = false;
    this.selected = false;
    this.selectedPackage = '';
    this.invResp = {
      eft: {
        eft_personName: '',
        eft_phone: '',
        eft_cell: '',
        eft_email: '',
        eft_dteCreated: '',
        eft_id: '',
        user_id: '',
      },
      package: {
        smsp_desc: '',
        smsp_unitPrice: '',
      },
    };
    this.getPackages();

    this.purchaseIndividualForm = this.fb.group({
      user_pName: ['', [Validators.required, Validators.maxLength(50)]],
      user_pCell: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern(/^(\+[0-9]|[0-9])*$/)]],
      user_pEmail: ['', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/), Validators.maxLength(100)]],
      user_physAddr: ['', [Validators.required, Validators.maxLength(200)]],
      user_physAddrArea: ['', [Validators.required, Validators.maxLength(50)]],
      user_physAddrCity: ['', [Validators.required, Validators.maxLength(50)]],
      user_physAddrAreaCode: ['', [Validators.required, Validators.maxLength(20)]],
    });

    this.purchaseCompanyForm = this.fb.group({
      user_tradingName: ['', [Validators.required, Validators.maxLength(50)]],
      user_vatNum: ['', [Validators.maxLength(10), Validators.minLength(10), Validators.pattern(/^([0-9]|[0-9])*$/)]],
      user_accPerson: ['', [Validators.required, Validators.maxLength(50)]],
      user_email: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
      user_phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern(/^(\+[0-9]|[0-9])*$/)]],
      user_physAddr: ['', [Validators.required, Validators.maxLength(200)]],
      user_physAddrArea: ['', [Validators.required, Validators.maxLength(50)]],
      user_physAddrCity: ['', [Validators.required, Validators.maxLength(50)]],
      user_physAddrAreaCode: ['', [Validators.required, Validators.maxLength(20)]],
    });
  }

  getPackages() {
    this.creditsService.getCreditPackages()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              for (let col = 0; col < (this.resp.count / 2 + 1); col++) {
                this.colors.push({top: 'aqua-gradient', stripe: 'green-striped'});
                this.colors.push({top: 'purple-gradient', stripe: 'purple-striped'});
              }
              for (let index = 0; index < this.resp.count; index++) {
                const element = this.resp.rows[index];
                this.packageArr[index] = element;
              }
              this.resp = {};
            },
            (err) => {
              this.loadingfail = true;
            },
        );
  }


  onSelectPackage(id) {
    this.selected = true;
    this.selectedPackage = id;
    this.getUserBillingDetails();
  }

  getUserBillingDetails() {
    this.userService.getAccountDetails()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.billingDetails = data;
              this.purchaseIndividualForm.setValue({
                user_pName: this.billingDetails.user.user_pName,
                user_pCell: this.billingDetails.user.user_pCell,
                user_pEmail: this.billingDetails.user.user_pEmail,
                user_physAddr: this.billingDetails.user.user_physAddr,
                user_physAddrArea: this.billingDetails.user.user_physAddrArea,
                user_physAddrCity: this.billingDetails.user.user_physAddrCity,
                user_physAddrAreaCode: this.billingDetails.user.user_physAddrAreaCode,
              });

              this.purchaseCompanyForm.setValue({
                user_tradingName: this.billingDetails.user.user_tradingName,
                user_vatNum: this.billingDetails.user.user_vatNum,
                user_accPerson: this.billingDetails.user.user_accPerson,
                user_phone: this.billingDetails.user.user_phone,
                user_email: this.billingDetails.user.user_email,
                user_physAddr: this.billingDetails.user.user_physAddr,
                user_physAddrArea: this.billingDetails.user.user_physAddrArea,
                user_physAddrCity: this.billingDetails.user.user_physAddrCity,
                user_physAddrAreaCode: this.billingDetails.user.user_physAddrAreaCode,
              });
            },
            (err) => {
              this.ngOnInit();
            },
        );
  }

  onSubmitIndividual() {
    this.submitted = true;

    if (this.purchaseIndividualForm.invalid) {
      this.openModalErrorInput();
      return;
    }

    this.openModalConfirmPersonal();
  }

  onSubmitCompany() {
    this.submitted = true;

    if (this.purchaseCompanyForm.invalid) {
      this.openModalErrorInput();
      return;
    }

    this.openModalConfirmCompany();
  }

  purchaseIndividualCredit() {
    this.confirm = true;
    this.submitted = false;
    this.requestInfo = {
      smsp_id: this.packageArr[this.selectedPackage].smsp_id,
      eft_personName: this.purchaseIndividualForm.value.user_pName,
      eft_phone: this.purchaseIndividualForm.value.user_pCell,
      eft_email: this.purchaseIndividualForm.value.user_pEmail,
      eft_phyAddr:
      this.purchaseIndividualForm.value.user_physAddr + ', ' +
      this.purchaseIndividualForm.value.user_physAddrArea + ', ' +
      this.purchaseIndividualForm.value.user_physAddrCity + ', ' +
      this.purchaseIndividualForm.value.user_physAddrAreaCode,
      eft_tradingAs: 'N/A - Personal Request',
      eft_VatNo: '',
      smsp_desc: this.packageArr[this.selectedPackage].smsp_desc,
      smsp_unitPrice: this.packageArr[this.selectedPackage].smsp_unitPrice,
      billingType: 0,
    };
    this.onSubmitPurchase();
  }

  purchaseCompanyCredit() {
    this.confirm = true;
    this.submitted = false;
    this.requestInfo = {
      smsp_id: this.packageArr[this.selectedPackage].smsp_id,
      eft_personName: this.purchaseCompanyForm.value.user_accPerson,
      eft_phone: this.purchaseCompanyForm.value.user_phone,
      eft_email: this.purchaseCompanyForm.value.user_email,
      eft_phyAddr:
      this.purchaseCompanyForm.value.user_physAddr + ', ' +
      this.purchaseCompanyForm.value.user_physAddrArea + ', ' +
      this.purchaseCompanyForm.value.user_physAddrCity + ', ' +
      this.purchaseCompanyForm.value.user_physAddrAreaCode,
      eft_tradingAs: this.purchaseCompanyForm.value.user_tradingName,
      eft_VatNo: this.purchaseCompanyForm.value.user_vatNum,
      smsp_desc: this.packageArr[this.selectedPackage].smsp_desc,
      smsp_unitPrice: this.packageArr[this.selectedPackage].smsp_unitPrice,
      billingType: 1,
    };
    this.onSubmitPurchase();
  }

  onSubmitPurchase() {
    this.creditsService.purchaseCreditPackage(this.requestInfo)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data) => {
          this.invResp = data;
          this.openModalProFormaInvoice();
          this.ngOnInit();
        },
        (err) => {
          this.confirm = false;
        },
        );
    // this.selected = false;
    // this.confirm = false;
  }

  get i() {
    return this.purchaseIndividualForm.controls;
  }
  get p() {
    return this.purchaseCompanyForm.controls;
  }

  modalOptionsInvoice = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: true,
    class: 'modal-dialog modal-notify modal-info modal-lg',
    containerClass: 'modal fade-right overflow-auto',
    animated: true,
    data: {
      content: {},
    },
  }

  openModalProFormaInvoice() {
    this.modalOptionsInvoice.data.content = {
      eft_personName: this.invResp.eft.eft_personName,
      eft_phone: this.invResp.eft.eft_phone,
      eft_cell: this.invResp.eft.eft_cell,
      eft_email: this.invResp.eft.eft_email,
      eft_dteCreated: this.pipe.transform(this.invResp.eft.eft_dteCreated, 'yyyy-MM-dd'),
      eft_id: this.invResp.eft.eft_id,
      user_id: this.invResp.eft.user_id,
      eft_VatNo: this.invResp.eft.eft_VatNo,
      smsp_desc: this.invResp.package.smsp_desc,
      smsp_unitPrice: this.invResp.package.smsp_unitPrice,
      user_name: this.invResp.user.user_name,
      eft_phyAddr: this.invResp.eft.eft_phyAddr,
    };
    this.modalRef = this.modalService.show(InvoiceComponent, this.modalOptionsInvoice);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.ngOnInit();
          }
        });
  }


  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', amount: 0, description: '', btnText: ''},
    },
  }

  openModalConfirmPersonal() {
    this.modalOptions.data = {
      heading: 'Are you sure?',
      content: {
        heading: 'Are you sure you want to submit this',
        amount: 0,
        description: ' credit purchase request?',
        btnText: 'Yes',
      },
    };
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.purchaseIndividualCredit();
          }
        });
  }

  openModalConfirmCompany() {
    this.modalOptions.data = {
      heading: 'Are you sure?',
      content: {
        heading: 'Are you sure you want to submit this',
        amount: 0,
        description: ' credit purchase request?',
        btnText: 'Yes',
      },
    };
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.purchaseCompanyCredit();
          }
        });
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', btnText: ''},
    },
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Request submitted',
      content: {
        heading: 'Your Purchase request has been submitted for approval. You will be informed via email as soon as it has been processed.',
        btnText: 'Thank you',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }

  modalOptionsErrorInput = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
    },
  }

  openModalErrorInput() {
    this.modalOptionsErrorInput.data = {
      heading: 'Oops...',
      content: {
        heading: 'Please ensure that you have provided all required details before proceeding.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure all required fields are provided.',
        fix2: 'Look for the red text to guide you.',
        fix3: 'Then try again.',
        btnText: 'lets see',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  openModalErrorTimeout() {
    this.modalOptionsErrorInput.data = {
      heading: 'Update Failed Successfully...',
      content: {
        heading: 'I seem to have lost you there for a moment...',
        suggest: 'Suggested Actions:',
        fix1: 'Please check your network connectivity.',
        fix2: 'Try to refresh your browser.',
        fix3: 'Then try again.',
        btnText: 'it happens',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
