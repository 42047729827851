import {Component, OnInit} from '@angular/core';
import {LoaderService} from '../../../services/loader.service';

@Component({
  selector: 'app-view-optout',
  templateUrl: './view-optout.component.html',
  styleUrls: ['./view-optout.component.scss'],
})
export class ViewOptoutComponent implements OnInit {
  public loading: boolean;
  public edit: boolean;
  public currentState: string;
  public listId: {ougr_id: number};

  constructor(
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }


  ngOnInit() {
    this.loading = true;
    this.edit = false;
    this.currentState = '';
    this.listId = {ougr_id: 0};
  }

  onEditedList(editData: {edit: boolean, ougr_name: string, ougr_id: number}) {
    this.edit = editData.edit;
    this.currentState = editData.ougr_name;
    this.listId = {ougr_id: editData.ougr_id};
  }
}
