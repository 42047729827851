import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';

import {LoginService} from '../../login.service';
import {SuccessModalComponent} from '../../modals/other/success-modal/success-modal.component';
import {ErrorComponent} from '../../modals/other/error/error.component';
import {LoaderService} from '../../services/loader.service';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss'],
})
export class ActivateAccountComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  action: Subject <any> = new Subject();

  registerForm: FormGroup;
  public submitted: boolean;
  content: {user_id: number};
  private payload: any;
  private resp: any;
  public loading: boolean;
  public loadingtext: string;
  public codeTrim: string;

  constructor(
    public modalRef2: MDBModalRef,
    public modalRef: MDBModalRef,
    private fb: FormBuilder,
    private loginService: LoginService,
    private modalService: MDBModalService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }


  ngOnInit() {
    this.codeTrim = '';
    this.submitted = false;
    this.loadingtext = '';
    this.payload = {};
    this.registerForm = this.fb.group({
      user_activate_code: ['', [Validators.required, Validators.maxLength(20), Validators.pattern(/^([0-9]|[a-z]|[A-Z]|[" "])*$/)]],
    });
  }

  get p() {
    return this.registerForm.controls;
  }
  get user_activate_code() {
    return this.registerForm.get('user_activate_code');
  }

  onSubmit() {
    this.submitted = true;

    // stop if invalid
    if (this.registerForm.invalid) {
      this.openModalError();
      return;
    }

    this.activateUser();
  }

  activateUser() {
    this.codeTrim = this.user_activate_code.value.replace(/\s/, '');
    this.loadingtext = 'Activating Your Account...';
    this.payload = {user_id: this.content.user_id, user_activate_code: this.codeTrim};
    this.loginService.activateAccount(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data) => {
          this.resp = data;
          if (!this.resp.error) {
            this.modalRef2.hide();
            this.action.next('true');
            this.openModalSuccess();
          } else {
            this.openModalErrorWrongCode();
          }
        });
  }


  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', btnText: ''},
    },
  }


  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Activation Successful!',
      content: {
        heading: 'Everything looks good! Your account has been successfully activated. You may now login with your new account.',
        btnText: 'Awesome',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }


  modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
    },
  }


  openModalError() {
    this.modalOptionsError.data = {
      heading: 'Oops...',
      content: {
        heading: 'There seems to be a problem with your activation code.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you have provided your activation code.',
        fix2: 'Ensure that your activation code is valid.',
        fix3: 'Look for the red text indicating errors.',
        btnText: 'lets see',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }

  openModalErrorWrongCode() {
    this.modalOptionsError.data = {
      heading: 'Well that didn\'t work...',
      content: {
        heading: 'The code that you have provided does not appear to be valid.',
        suggest: 'Suggested Actions:',
        fix1: 'Verify that you have entered your activation code correctly.',
        fix2: 'Your activation code can be found in the email that was sent to you when you registered your account.',
        fix3: 'Try again.',
        btnText: 'lets see',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }


  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

