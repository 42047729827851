/* eslint-disable max-len */
/* eslint-disable no-multi-str */
import {Injectable} from '@angular/core';
import Shepherd from 'shepherd.js';
import {Router} from '@angular/router';

const smsReportTour = new Shepherd.Tour({
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
    classes: 'shadow-md bg-purple-dark',
    scrollTo: {behavior: 'smooth', block: 'center'},
  },
  confirmCancel: false,
  // confirmCancelMessage: '',
  exitOnEsc: false,
  keyboardNavigation: false,
  useModalOverlay: true,
});

@Injectable({
  providedIn: 'root',
})
export class TutSmsReportsService {
  constructor(private router: Router) { }

  smsReportTour() {
    const that = this;
    smsReportTour.addSteps([{
      id: 'start',
      title: 'Using SMS Reports',
      text: 'This is an interactive tutorial and will walk you through everything you need to know to use the SMS Reports efficiently.<br><br>\
      As always I will be pointing at stuff, and telling you some things about the stuff im pointing at. At the end you will have mastered the art of Interpreting SMS Reports.<br><br>\
      <mark>- At some steps I will ask you to interact with the page before we can move on. These interactive actions will be highlighted like this</mark>',
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'Step1',
      title: 'Breakdown',
      text: 'In this tutorial we will be examining the 3x different SMS Reports.<br><br>\
      First up will be the Number Lookup Report.<br><br>\
      Followed by the Sent/Queued Messages Report.<br><br>\
      And we will round everything off with Reply SMS Report.',
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Lets Begin'},
      ],
    }, {
      id: 'step1',
      title: 'Number Lookup Report',
      text: 'Finds all sent- and reply messages to and from a specific number.<br><br>\
      <i>- I am sure you will find this to be quite simple to do.</i><br><br>',
      attachTo: {element: '.mainS11', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step2',
      title: 'Number Field',
      text: 'Here you enter the number which you would like to lookup.<br><br>\
      <i>- This number should start with a "0". Ensure that this number contains exactly 10 digits.</i><br><br>',
      attachTo: {element: '.t2', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step3',
      title: 'Get Report Button',
      text: 'After entering a number you would just click on this button.<br><br>\
      <i>- I will take care of the rest.</i><br><br>',
      attachTo: {element: '.t3', on: 'left'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step4',
      title: 'Results Table',
      text: 'After completing your search I will display your lookup results in a table.<br><br>\
      This results will include the following:<ul>\
      <li>The Cellphone Number</li>\
      <li>Message that was sent</li>\
      <li>Date that message was sent</li>\
      <li>Message delivery status</li>\
      <li>The reply message if there is one</li>\
      <li>The date of receiving the reply message</li>\
      </ul>\
      <i>- If I do find any reply messages I will include them.</i><br><br>',
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step4',
      title: 'Export Button',
      text: 'Once your report has been generated you also have the option of exporting the results as an Excel file.<br><br>\
      <i>- I know; Easy-peasy right?</i><br><br>',
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step5',
      title: 'As Simple As That',
      text: 'This concludes our guide on Looking Up Numbers.<br><br>\
      Next we will look at your Sent/Queued Messages Report',
      canClickTarget: false,
      buttons: [
        {action() {
          return that.router.navigate(['/sms/reports/sent-messages']), this.next();
        }, text: 'Sent/Queued Report'},
      ],
    }, {
      id: 'step6',
      title: 'Sent Messages Report',
      text: 'This page generates a report starting on the current month and ranges to 3 months prior to the current month.<br><br>\
      <i>- So that adds up to 4 months in total.</i><br><br>\
      <mark>- Lets give it a moment or two to load.</mark>',
      attachTo: {element: '.mainS12', on: 'top'},
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 400);
        });
      },
      buttons: [
        {action() {
          return this.next();
        }, text: 'Wait...'},
      ],
    }, {
      id: 'stepCheck',
      title: 'Sorry for keeping you...',
      text: 'This report may take some time to load, depending on the amount of messages & different days sent on etc...<br><br>',
      canClickTarget: false,
      buttons: [
        {action() {
          if (document.getElementById('loadCheck') === null) {
            return this.show('stepError');
          } else {
            return this.show('step7');
          }
        }, text: 'It\s Okay'},
      ],
    }, {
      id: 'stepError',
      title: '"Patience Is Virtue"',
      text: 'It seems like the Report has not finished loading yet.<br><br>\
    Please be patient for a little longer...',
      canClickTarget: false,
      buttons: [
        {action() {
          return this.show('stepCheck');
        }, text: 'Wait Some More...'},
      ],
    }, {
      id: 'step7',
      title: 'Month Select',
      text: 'On initial load of this page the current month will automatically be loaded by default.<br><br>\
      You can click on this box to select up to 3 months prior to the current month.<br><br>\
      <i>- This may sometimes take some time because who knows how many messages you sent, but hey thats what we are here to find out.</i><br><br>',
      attachTo: {element: '.t2', on: 'right'},
      canClickTarget: false,
      modalOverlayOpeningPadding: 5,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step8',
      title: 'Monthly Summary',
      text: 'This table will summarize all of your sent messages for the selected month.<br><br>\
    <i>- "What do all of these even mean" I hear you ask.</i><br><br>\
    <i>- Not to worry, we will go through each of them now.</i><br><br>',
      attachTo: {element: '.t3', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step9',
      title: 'Total Sent Messages',
      text: 'This indicates the amount of messages that you submitted to be sent. Regardless of delivery status or if they have been scheduled.<br><br>',
      attachTo: {element: '.t4', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step10',
      title: 'Total Delivered Messages',
      text: 'This indicates the amount of messages that has been sent and successfully been delivered to the recipient\'s device.<br><br>\
    It should be noted that scheduled messages that have yet to be sent will not be included here.<br><br>\
    <i>- The first Back To The Future was better than number 2.</i><br><br>',
      attachTo: {element: '.t5', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step11',
      title: 'Network Delivered Messages',
      text: 'This indicates the amount of messages that has been sent and successfully accepted by the network but not yet delivered to the recipient\'s handset.<br><br>',
      attachTo: {element: '.t6', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step12',
      title: 'Total Rejected Messages',
      text: 'This indicates the amount of messages that has been sent and then rejected by the network.<br><br>\
    <i>- In most cases this would be due to the number not existing anymore.</i><br><br>',
      attachTo: {element: '.t7', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step13',
      title: 'Queued/Scheduled',
      text: 'This indicates a whole range of different status codes.<br><br>\
    Mostly queued & scheduled messages will be included here.<br><br>\
    <i>- besides that none of the others should be of interest to us as it gets very technical.</i><br><br>',
      attachTo: {element: '.t8', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step14',
      title: 'Summary Graph',
      text: 'This is a graph that will summarize all of the amounts in the table that we just went through.<br><br>\
    You can click on any of the headings at the top of this graph to show or hide the various values.<br><br>\
    <i>- Personally I prefer colorful images over monotone numbers.</i><br><br>',
      attachTo: {element: '.t9', on: 'left'},
      canClickTarget: true,
      modalOverlayOpeningPadding: 10,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step15',
      title: 'Daily Summary Table',
      text: 'This is a table will summarize all of the amounts in the table above.<br><br>\
    The difference being that this table shows the totals for each individual day of the selected month.<br><br>\
    <i>- For your convenience I will only display the days that messages were sent on.</i><br><br>',
      attachTo: {element: '.t10', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step16',
      title: 'What do these mean?',
      text: 'As much as I enjoy our time together - I don\'t want to waste yours.<br><br>\
    Consequently I won\'t be going thought these as they correspond to the meanings of the table at the top which we covered earlier.<br><br>\
    <i>- Saving you time. Saving you money. Putting you first.</i><br><br>',
      attachTo: {element: '.t11', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step17',
      title: 'Download Table Button',
      text: 'This button allows you to export the daily summary table at the bottom of the page.<br><br>\
    This will only export the currently selected month & totals for each day.<br><br>',
      attachTo: {element: '.t12', on: 'left'},
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step18',
      title: 'Download Report Button',
      text: 'This button will generate a full detailed report of all sent messages in the month you have selected<br><br>\
    This includes all messages that you sent and all relevant details.<br><br>',
      attachTo: {element: '.t13', on: 'left'},
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step19',
      title: 'Two Down - One To Go',
      text: 'This concludes our guide on Sent And Queued Messages Report.<br><br>\
    Next we will look at your Reply Messages Report',
      canClickTarget: false,
      buttons: [
        {action() {
          return that.router.navigate(['/sms/reports/reply-messages']), this.next();
        }, text: 'Replies Report'},
      ],
    }, {
      id: 'step20',
      title: 'Reply SMS Report',
      text: 'Let me start by saying that the Reply SMS Report has got 2x main components.<br><br>\
    Firstly you will have the "Reply SMS Live Feed".<br><br>\
    Additionally you have "Reply SMS Report".<br><br>\
    <i>We will look a little closer at what makes these special.</i>',
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step21',
      title: 'Reply SMS Live Feed',
      text: 'This is a nifty little fellow that will show you any reply messages as they come in.<br><br>\
    No need to refresh or fuss - Just stare at the screen.<br><br>',
      attachTo: {element: '.mainS13', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step22',
      title: 'Live Data',
      text: 'As soon as new replies get received I will add it to the top of your replies list.<br><br>\
    Keeping you in the loop as things happen.<br><br>',
      attachTo: {element: '.s18', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step23',
      title: 'Manual Refresh',
      text: 'If you want to take matters into your own hands - you can always click on this button to force a refresh.<br><br>\
    *Note that this will not stop me from doing my automated refreshes.<br><br>',
      attachTo: {element: '.s19', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step24',
      title: 'Changing It Up',
      text: 'The Live Feed will only display replies of the current day.<br><br>\
    Lets say you want to see who sent you what last week - then you would flip this switch.<br><br>\
    <mark>Please flip this switch over to "Generate Report" to continue.</mark>',
      attachTo: {element: '.s20', on: 'bottom'},
      advanceOn: {selector: '.s20a', event: 'click'},
    }, {
      id: 'step25',
      title: 'Reply SMS Report',
      text: 'You should now see a different layout - This is were you can generate detailed reports of all reply messages.<br><br>\
    It\'s simple really: You just give me a date range by selecting a start and end date.<br><br>',
      attachTo: {element: '.mainS13', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step26',
      title: 'Date Range Selectors',
      text: 'Here you just need to select the starting date and ending date respectively by clicking on these 2x fields.<br><br>',
      attachTo: {element: '.s22', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step27',
      title: 'Download Report',
      text: 'Then you just simply click on this button. I will create a report containing all reply messages received within the date range that you have selected.<br><br>\
    Once your report is ready it will start downloading automatically.',
      attachTo: {element: '.s23', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step28',
      title: 'Well Done!',
      text: 'You have just successfully completed The SMS Report Tutorial.<br><br>\
    <i>This concludes our tutorial - I enjoy these little moments we share =)</i><br><br>\
    <mark>*** Don\'t forget to check out the other 3x in depth SMS tutorials covering Sending SMS, Phone Books and Scheduled SMS respectively.</mark>',
      canClickTarget: false,
      buttons: [
        {action() {
          return this.complete();
        }, text: 'End Tutorial'},
      ],
    },
    ]);


    smsReportTour.start();
  }
}
