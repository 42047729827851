import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {UserLoginModel} from './models/user-login-model';
import {UserLoginModelOtp} from './models/user-login-otp-model';
import {Observable, throwError, BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../environments/environment';

const url = environment.baseUrl;

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private AuthorizationSubject: BehaviorSubject<UserLoginModel>;
  private AuthorizationSubjectOtp: BehaviorSubject<UserLoginModelOtp>;
  public Authorization: Observable<UserLoginModel>;
  public AuthorizationOtp: Observable<UserLoginModelOtp>;
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'skip': 'true',
    }),
  };

  constructor(private httpClient: HttpClient) {
    this.AuthorizationSubject = new BehaviorSubject<UserLoginModel>(JSON.parse(localStorage.getItem('Authorization')));
    this.AuthorizationSubjectOtp = new BehaviorSubject<UserLoginModelOtp>(JSON.parse(localStorage.getItem('Authorization')));

    this.Authorization = this.AuthorizationSubject.asObservable();
    this.AuthorizationOtp = this.AuthorizationSubjectOtp.asObservable();
  }

  public get AuthorizationValue(): UserLoginModel {
    return this.AuthorizationSubject.value;
  }

  public get AuthorizationValueOTP(): UserLoginModelOtp {
    return this.AuthorizationSubjectOtp.value;
  }

  // sendMessage(messageContent): Observable<UserLoginModel> {
  //   return this.httpClient.post<UserLoginModel>(this.url + '/v1/users/login', JSON.stringify(messageContent), this.httpOptions)
  //   .pipe(
  //     tap(res => {
  //     localStorage.setItem('token', res.token);
  //     }),
  //     catchError(this.handleError)
  //   );
  // }

  login(messageContent): Observable<UserLoginModel> {
    return this.httpClient.post<UserLoginModel>(url + '/v1/users/login', JSON.stringify(messageContent), this.httpOptions)
        .pipe(
            map((user) => {
              if (user && user.token) {
                localStorage.setItem('Authorization', JSON.stringify(user.token));
                this.AuthorizationSubject.next(user);
              }
              return user;
            }));
  }


  loginOTP(messageContent): Observable<UserLoginModelOtp> {
    return this.httpClient.post<UserLoginModelOtp>(url + '/v1/users/otp/login', JSON.stringify(messageContent), this.httpOptions)
        .pipe(
            map((user) => {
              if (user && user.token) {
                localStorage.setItem('Authorization', JSON.stringify(user.token));
                this.AuthorizationSubjectOtp.next(user);
              }
              return user;
            }));
  }


  activateAccount(payload) {
    return this.httpClient.post(url + '/v1/users/account/activate', payload);
  }


  // login(username: string, password: string) {
  //   return this.httpClient.post<any>(this.url + '/v1/users/login', { username, password }, this.httpOptions)
  //   .pipe(
  //     map(user => {
  //       if (user && user.token) {
  //         localStorage.setItem('Authorization', JSON.stringify(user));
  //         this.AuthorizationSubject.next(user);
  //       }
  //       return user;
  //     }));
  // }

  logout() {
    localStorage.removeItem('Authorization');
    this.AuthorizationSubject.next(null);
  }


  // Error handling
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
