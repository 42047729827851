import {Component, OnInit} from '@angular/core';
import {MDBModalRef} from 'ng-uikit-pro-standard';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {UserService} from '../../../services/user.service';
import {LoaderService} from '../../../services/loader.service';

@Component({
  selector: 'app-sms2email-modal',
  templateUrl: './sms2email-modal.component.html',
  styleUrls: ['./sms2email-modal.component.scss'],
})
export class Sms2emailModalComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject();

  private billingDetails: any;
  public submitted: boolean;
  public loading: boolean;

  public resp: any;

  public step1: boolean;
  public step2: boolean;
  public step3: boolean;

  public heading: string;

  billingDetailsForm: FormGroup;

  action: Subject <any> = new Subject();

  constructor(public frame: MDBModalRef,
    private billingDetailsService: UserService,
    private fb: FormBuilder,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }

  ngOnInit() {
    this.heading = 'Email2SMS Activation Request';
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.billingDetails = {};
    this.submitted = false;
    this.resp = {};

    this.billingDetailsForm = this.fb.group({
      user_tradingName: ['', [Validators.required, Validators.maxLength(50)]],
      user_vatNum: ['', [Validators.maxLength(10), Validators.minLength(10), Validators.pattern(/^([0-9]|[0-9])*$/)]],
      user_accPerson: ['', [Validators.required, Validators.maxLength(50)]],
      user_email: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
      user_phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern(/^(\+[0-9]|[0-9])*$/)]],
      user_physAddr: ['', [Validators.required, Validators.maxLength(200)]],
      user_physAddrArea: ['', [Validators.required, Validators.maxLength(50)]],
      user_physAddrCity: ['', [Validators.required, Validators.maxLength(50)]],
      user_physAddrAreaCode: ['', [Validators.required, Validators.maxLength(20)]],
    });

    this.loadBillingDetails();
  }

  loadBillingDetails() {
    this.billingDetailsService.getAccountDetails()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.billingDetails = data;
              this.billingDetailsForm.setValue({
                user_tradingName: this.billingDetails.user.user_tradingName,
                user_vatNum: this.billingDetails.user.user_vatNum,
                user_accPerson: this.billingDetails.user.user_accPerson,
                user_email: this.billingDetails.user.user_email,
                user_phone: this.billingDetails.user.user_phone,
                user_physAddr: this.billingDetails.user.user_physAddr,
                user_physAddrArea: this.billingDetails.user.user_physAddrArea,
                user_physAddrCity: this.billingDetails.user.user_physAddrCity,
                user_physAddrAreaCode: this.billingDetails.user.user_physAddrAreaCode,
              });
            },
            (err) => {},
        );
  }

  get p() {
    return this.billingDetailsForm.controls;
  }


  onYesClick() {
    this.heading = 'Billing Details Confirmation';
    this.step1 = false;
    this.step2 = true;
    this.step3 = false;
  }

  onConfirmClick() {
    this.submitted = true;
    if (this.billingDetailsForm.invalid) {
      return;
    }

    this.billingDetailsService.requestEmail2SMSActivation()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              // console.log(this.resp)
              this.step1 = false;
              this.step2 = false;
              this.step3 = true;
            },
            (err) => {
              return;
            },
        );
  }

  onThankYouClick() {
    this.frame.hide();
    this.action.next('true');
  }
}
