import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {MdbTableDirective, MdbTablePaginationComponent} from 'ng-uikit-pro-standard';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {IMyOptions} from 'ng-uikit-pro-standard';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import {DatePipe} from '@angular/common';

import {ConfirmComponent} from '../../../modals/other/confirm/confirm.component';
import {SuccessModalComponent} from '../../../modals/other/success-modal/success-modal.component';
import {UploadBlacklistModalComponent} from '../../../modals/file/upload-blacklist-modal/upload-blacklist-modal.component';
import {ErrorComponent} from '../../../modals/other/error/error.component';

import {AdminService} from '../../../services/admin.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoaderService} from '../../../services/loader.service';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss'],
})
export class CampaignsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, {static: false}) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, {static: false}) mdbTable: MdbTableDirective;
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  campaign: {camp_id: number};
  private selectedUserId: {user_id: number};
  public selectedCampaignId: {camp_id: number};
  private selectedScId: {sc_id: number};
  private modalOptionsBl: any;
  private resp: any;
  private resp2: any;
  private respSc: any;
  private finalResp: any;
  private blacklistResp: any;
  private keywordToDelete: any;
  private reportToDelete: any;
  private reportToAdd: any;
  private numberToDelete: any;
  private numberToAdd: any;
  private payload: any;
  private payload2: any;
  public loading: boolean;
  public loadingfail: boolean;

  public step2flagTestSC: boolean;


  public userSelected: boolean;
  public campaignSelected: boolean;
  public selected:boolean;
  public submitted:boolean;
  public keywordAdded:boolean;
  public blacklistManual: boolean;
  private sms2email: boolean;
  private camp_status: boolean;
  public campIsInstant: boolean;

  public campaignName: string;
  private shortcodeInfo: string;
  private reportScheduleTypeChecker: string;
  private tempemail: string;


  private initialScId: number;
  private reportSchedule: number;
  private conflictingKeywordCount: number;
  public flag_winnerCrit: number;
  public maxVisibleItems: number;

  private keywordArr: Array<any>;
  public previous: Array<any>;
  private campaigns: Array<any>;
  private keywordList: Array<any>;
  private reportDays: Array<any>;
  private blacklistList: Array<any>;
  private users: Array<any>;
  private scArr: Array<any>;


   // DATE & TIME FORMATS
   pipe = new DatePipe('en-ZA'); // Use your own locale
   // DATE & TIME FORMATS

  campaignForm: FormGroup;
  step1Form: FormGroup;
  step2Form: FormGroup;
  step3Form: FormGroup;
  step4Form: FormGroup;
  step5Form: FormGroup;
  step6Form: FormGroup;

  step1InstantForm: FormGroup;
  step2InstantForm: FormGroup;
  stepXInstantForm: FormGroup;

  optionsUsers: Array<any>;
  optionsCampaigns: Array<any>;
  optionsShortcodes: Array<any>;
  optionsSmsValue: Array<any>;

  optionsDays = [
    {value: 'Monday', label: 'Monday'},
    {value: 'Tuesday', label: 'Tuesday'},
    {value: 'Wednesday', label: 'Wednesday'},
    {value: 'Thursday', label: 'Thursday'},
    {value: 'Friday', label: 'Friday'},
    {value: 'Saturday', label: 'Saturday'},
    {value: 'Sunday', label: 'Sunday'},
  ];

    optionsTicketPosVal: Array<any> = [
      {value: 1, label: 1},
      {value: 2, label: 2},
      {value: 3, label: 3},
    ];

    optionsWinType: Array<any> = [
      {value: 1, label: 'Daily'},
      {value: 2, label: 'Monthly'},
      {value: 3, label: 'Yearly'},
      {value: 4, label: 'Entire Campaign'},
    ];

  headKeywords = ['Keyword', 'Description', 'Delete'];
  headReports = ['Day', 'Time', 'Delete'];
  headBlacklist = ['#', 'Number', 'Delete'];

  public myDatePickerOptions: IMyOptions = {
    todayBtnTxt: 'Today',
    clearBtnTxt: 'Clear',
    closeBtnTxt: 'Close',
    closeAfterSelect: true,
    editableDateField: false,
    disableUntil: {year: 0, month: 0, day: 0},
  };

  constructor(
    private campaignService: AdminService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private modalService: MDBModalService,
    private router: Router,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }


  ngOnInit() {
    this.step2flagTestSC = false;
    this.loadingfail = false;
    this.modalOptionsBl = {};
    this.resp = {};
    this.respSc = {};
    this.resp2 = {};
    this.blacklistResp = {};
    this.keywordToDelete = {};
    this.reportToDelete = {};
    this.reportToAdd = {};
    this.numberToDelete = {};
    this.payload = {};
    this.payload2 = {};
    this.selectedUserId = {user_id: 0};
    this.selectedCampaignId = {camp_id: 0};
    this.selectedScId = {sc_id: 0};
    this.keywordArr = [];
    this.previous = [];
    this.campaigns = [];
    this.keywordList = [];
    this.reportDays = [];
    this.blacklistList = [];
    this.users = [];
    this.scArr = [];
    this.initialScId = 0;
    this.reportSchedule = 0;
    this.conflictingKeywordCount = 0;
    this.flag_winnerCrit = 0;
    this.maxVisibleItems = 8;
    this.userSelected = false;
    this.campaignSelected = false;
    this.blacklistManual = false;
    this.selected = false;
    this.submitted = false;
    this.keywordAdded = false;
    this.camp_status = false;
    this.campIsInstant = false;
    this.sms2email = false;
    this.campaignName = '';
    this.shortcodeInfo = '';
    this.reportScheduleTypeChecker = '';
    this.tempemail = '';


    this.loadAllUsers();
    this.campaign = {
      camp_id: this.route.snapshot.params['camp_id'],
    };

    this.campaignForm = this.fb.group({
      user_id: ['', Validators.required],
      camp_id: ['', Validators.required],
    });

    this.step1Form = this.fb.group({
      camp_name: ['', [Validators.required, Validators.maxLength(70), Validators.pattern(/^([0-9]|[a-z]|[A-Z]|[ ])+$/)]],
      camp_desc: ['', [Validators.required, Validators.maxLength(200)]],
      camp_startDte: ['', [Validators.required, Validators.pattern(/^([0-9]{4})\-([0-9]{2})\-([0-9]{2})$/)]],
      camp_endDte: ['', [Validators.required, Validators.pattern(/^([0-9]{4})\-([0-9]{2})\-([0-9]{2})$/)]],
      camp_startTime: ['', [Validators.required, Validators.pattern(/^([0-9]{2})\:([0-9]{2})\:{0,1}([0-9]{0,2})$/)]],
      camp_endTime: ['', [Validators.required, Validators.pattern(/^([0-9]{2})\:([0-9]{2})\:{0,1}([0-9]{0,2})$/)]],
    });


    this.step1InstantForm = this.fb.group({
      flag_normAdv: [0, Validators.required],
      ticket_code_msgPos: ['', Validators.required],
    });

    this.step2Form = this.fb.group({
      sc_id: ['', Validators.required],
      camp_replyMsg: ['', Validators.maxLength(160)],
      camp_replyMsgEnd: ['Sorry, this competition is closed.', [Validators.required, Validators.maxLength(160)]],
    });

    this.step2InstantForm = this.fb.group({
      notWin_replyMsg: ['', [Validators.required, Validators.maxLength(160)]],
      prize_won_replyMsg: ['', [Validators.required, Validators.maxLength(160)]],
      invalid_ticket_replyMsg: ['', [Validators.required, Validators.maxLength(160)]],
      used_ticket_replyMsg: ['', [Validators.required, Validators.maxLength(160)]],
      invalid_msg_replyMsg: ['', [Validators.required, Validators.maxLength(160)]],
    });

    this.step3Form = this.fb.group({
      ckeyw_word: ['', [Validators.required, Validators.maxLength(30), Validators.pattern(/^([0-9]|[a-z]|[A-Z])*$/)]],
      ckeyw_desc: ['', [Validators.required, Validators.maxLength(100)]],
    });

    this.stepXInstantForm = this.fb.group({
      max_winPerCellno: ['', [Validators.required, Validators.maxLength(10), Validators.pattern(/^[0-9]*$/)]],
      max_winPerCellno_type_id: [0, Validators.required],
      max_total_winners: ['', [Validators.required, Validators.maxLength(10), Validators.pattern(/^[0-9]*$/)]],
      prizeIsAirtime: [false, Validators.required],
      per_entry_winnerCount: ['', [Validators.required, Validators.maxLength(10), Validators.pattern(/^[0-9]*$/)]],
    });

    this.step4Form = this.fb.group({
      camp_ReportMail: ['', [Validators.required, Validators.pattern(/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/)]],
      camp_rep_day: ['', [Validators.required, Validators.maxLength(45)]],
      camp_rep_hour: ['', [Validators.required, Validators.maxLength(45)]],
    });

    this.step5Form = this.fb.group({
      email_to: ['', [Validators.required, Validators.maxLength(45), Validators.pattern(/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/)]],
      email_cc: ['', [Validators.required, Validators.maxLength(45), Validators.pattern(/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/)]],
    });

    this.step6Form = this.fb.group({
      blacklist_cellno: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20), Validators.pattern(/^(\+[0-9]|[0-9])*$/)]],
    });
    this.selectedUser();


    if (this.campaign.camp_id !== undefined) {
      this.campaignForm.setValue({
        camp_id: this.campaign.camp_id,
      });
    }
  }

  ngAfterViewInit() {

  }

  get s() {
    return this.campaignForm.controls;
  }

  get s1() {
    return this.step1Form.controls;
  }
  get s1I() {
    return this.step1InstantForm.controls;
  }
  get s2() {
    return this.step2Form.controls;
  }
  get s2I() {
    return this.step2InstantForm.controls;
  }
  get s3() {
    return this.step3Form.controls;
  }
  get s4() {
    return this.step4Form.controls;
  }
  get sxI() {
    return this.stepXInstantForm.controls;
  }
  get s5() {
    return this.step5Form.controls;
  }
  get s6() {
    return this.step6Form.controls;
  }

  // STEP 1 GETTERS
  get camp_name() {
    return this.step1Form.get('camp_name');
  }
  get camp_desc() {
    return this.step1Form.get('camp_desc');
  }
  get camp_startDte() {
    return this.step1Form.get('camp_startDte');
  }
  get camp_endDte() {
    return this.step1Form.get('camp_endDte');
  }
  get camp_startTime() {
    return this.step1Form.get('camp_startTime');
  }
  get camp_endTime() {
    return this.step1Form.get('camp_endTime');
  }

  // INSTANT WINNER GETTERS
  get ticket_code_msgPos() {
    return this.step1InstantForm.get('ticket_code_msgPos');
  }
  get flag_normAdv() {
    return this.step1InstantForm.get('flag_normAdv');
  }

  get notWin_replyMsg() {
    return this.step2InstantForm.get('notWin_replyMsg');
  }
  get prize_won_replyMsg() {
    return this.step2InstantForm.get('prize_won_replyMsg');
  }
  get invalid_ticket_replyMsg() {
    return this.step2InstantForm.get('invalid_ticket_replyMsg');
  }
  get used_ticket_replyMsg() {
    return this.step2InstantForm.get('used_ticket_replyMsg');
  }
  get invalid_msg_replyMsg() {
    return this.step2InstantForm.get('invalid_msg_replyMsg');
  }

  get max_winPerCellno() {
    return this.stepXInstantForm.get('max_winPerCellno');
  }
  get max_winPerCellno_type_id() {
    return this.stepXInstantForm.get('max_winPerCellno_type_id');
  }
  get max_total_winners() {
    return this.stepXInstantForm.get('max_total_winners');
  }
  get prizeIsAirtime() {
    return this.stepXInstantForm.get('prizeIsAirtime');
  }
  get per_entry_winnerCount() {
    return this.stepXInstantForm.get('per_entry_winnerCount');
  }

  // STEP 2 GETTERS
  get camp_replyMsg() {
    return this.step2Form.get('camp_replyMsg');
  }
  get camp_replyMsgEnd() {
    return this.step2Form.get('camp_replyMsgEnd');
  }

  // STEP 3 GETTERS
  get ckeyw_word() {
    return this.step3Form.get('ckeyw_word');
  }
  get ckeyw_desc() {
    return this.step3Form.get('ckeyw_desc');
  }

  // STEP 4 GETTERS
  get camp_ReportMail() {
    return this.step4Form.get('camp_ReportMail');
  }
  get camp_rep_day() {
    return this.step4Form.get('camp_rep_day');
  }
  get camp_rep_hour() {
    return this.step4Form.get('camp_rep_hour');
  }

  // STEP 5 GETTERS
  get email_to() {
    return this.step5Form.get('email_to');
  }
  get email_cc() {
    return this.step5Form.get('email_cc');
  }

  // STEP 6 GETTERS
  get blacklist_cellno() {
    return this.step6Form.get('blacklist_cellno');
  }


  loadAllUsers() {
    this.campaignService.getAllUsers()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              for (let index = 0; index < this.resp.count; index++) {
                const element = this.resp.rows[index];
                this.users.push({
                  value: element.user_id,
                  label: element.user_name,
                });
              }
              this.optionsUsers = this.users;
              this.users = [];
              this.resp = {};
            },
            (err) => {
              this.loadingfail = true;
            },
        );
  }

  selectedUser() {
    this.campaignForm.get('user_id').valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (value) => {
          this.selectedUserId = {user_id: value};
          this.optionsCampaigns = [];
          this.scArr = [];
          this.getShortcodes();
          this.loadAllCampaigns();
          this.selectedCampaign();
        });
  }


  loadAllCampaigns() {
    this.campaignService.getUserCampaigns(this.selectedUserId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              for (let index = 0; index < this.resp.count; index++) {
                const element = this.resp.rows[index];
                this.campaigns.push({
                  value: element.camp_id,
                  label: element.camp_name,
                });
              }
              this.optionsCampaigns = this.campaigns;
              this.resp = {};
              this.campaigns = [];
              this.userSelected = true;
            },
        );
  }

  selectedCampaign() {
    this.campaignForm.get('camp_id').valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (value) => {
          this.campaignSelected = true;
          this.selectedCampaignId = {camp_id: value};
          this.getSelectedCampaignDetails();
          this.reportDays = [];
        });
  }

  getSelectedCampaignDetails() {
    this.campaignService.getSelectedCampaignDetails(this.selectedCampaignId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              this.campaignName = this.resp.campaign.camp_name;
              this.selectedScId = {sc_id: this.resp.campaign.sc_id};
              this.initialScId = this.resp.campaign.sc_id;
              this.camp_status = !this.resp.campaign.camp_status;

              this.step1Form.setValue({
                camp_name: this.resp.campaign.camp_name,
                camp_desc: this.resp.campaign.camp_desc,
                camp_startDte: this.pipe.transform(this.resp.campaign.camp_startDte, 'yyyy-MM-dd'),
                camp_endDte: this.pipe.transform(this.resp.campaign.camp_endDte, 'yyyy-MM-dd'),
                camp_startTime: this.pipe.transform(this.resp.campaign.camp_startDte, 'HH:mm:ss'),
                camp_endTime: this.pipe.transform(this.resp.campaign.camp_endDte, 'HH:mm:ss'),
              });

              this.step2Form.setValue({
                sc_id: this.resp.campaign.sc_id,
                camp_replyMsg: this.resp.campaign.camp_replyMsg,
                camp_replyMsgEnd: this.resp.campaign.camp_replyMsgEnd,
              });

              if (this.resp.campaign.camp_isInstant === 1) {
                this.setInstant();
              } else {
                this.campIsInstant = false;
              }

              this.reportScheduleTypeChecker = this.resp.campaign.camp_hasReport;
              this.checkForEmailReports();
              this.getKeywords();
              this.selectedShortcode();

              if (this.resp.campaign.camp_hasEmail === 1) {
                this.sms2email = true;
                if (this.resp.email.count === 0) {
                  this.step5Form.setValue({
                    email_to: '',
                    email_cc: '',
                  });
                } else {
                  this.step5Form.setValue({
                    email_to: this.resp.email.rows[0].email_to,
                    email_cc: this.resp.email.rows[0].email_cc,
                  });
                }
              } else {
                this.sms2email = false;
              }
              this.getBlacklisted();
            },
            (err) => {
              this.ngOnInit();
            },
        );
  }

  getShortcodes() {
    this.campaignService.getSharedAndDedicatedShortcodes(this.selectedUserId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.respSc = data;
              if (this.respSc.dedicated.count > 0) {
                this.scArr[0] = {value: '', label: 'Dedicated', group: true};
                for (let a = 0; a < this.respSc.dedicated.count; a++) {
                  const elementa = this.respSc.dedicated.rows[a];
                  this.scArr.push({
                    value: elementa.sc_id,
                    label: (elementa.sc_number + ' - Value = R' + elementa.sc_value),
                  });
                }
              }
              this.scArr.push({value: '', label: 'Shared', group: true});
              for (let b = 0; b < this.respSc.shared.count; b++) {
                const elementb = this.respSc.shared.rows[b];
                this.scArr.push({
                  value: elementb.sc_id,
                  label: (elementb.sc_number + ' - Value = R' + elementb.sc_value),
                });
              }
              this.optionsSmsValue = this.scArr;
              this.respSc = {};
              this.scArr = [];
            },
            (err) => {
              this.loadingfail = true;
            },
        );
  }

  selectedShortcode() {
    this.step2Form.get('sc_id').valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (value) => {
          this.selectedScId = {sc_id: value};
          this.step2flagTestSC = true;
          if (this.selectedScId.sc_id === this.initialScId) {
            this.initialScId = 0;
            return;
          }
        });
  }

  getKeywordsCheck2() {
    this.keywordArr = this.keywordList;
    this.payload = {
      sc_id: this.selectedScId.sc_id,
      keywordArr: this.keywordArr,
      camp_id: this.selectedCampaignId.camp_id,
      user_id: this.selectedUserId.user_id,
    };
    this.campaignService.changeShortcodeWithKeywords(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp2 = data;
              if (this.resp2.resp === true) {
                this.openModalSuccess();
                this.step2flagTestSC = false;
                this.keywordList = this.resp2.newKeywords;
                return;
              } else {
                this.conflictingKeywordCount = this.resp2.count;
                this.openModalErrorKeywordConflict();
                this.step2flagTestSC = false;
                return;
              }
            },
        );
  }


  setInstant() {
    this.campIsInstant = true;
    this.step1InstantForm.setValue({
      flag_normAdv: this.resp.instant.hasTicketCode,
      ticket_code_msgPos: (this.resp.instant.ticket_code_msgPos + 1),
    });
    this.step2InstantForm.setValue({
      notWin_replyMsg: this.resp.instant.notWin_replyMsg,
      prize_won_replyMsg: this.resp.instant.prize_won_replyMsg,
      invalid_ticket_replyMsg: this.resp.instant.invalid_ticket_replyMsg,
      used_ticket_replyMsg: this.resp.instant.used_ticket_replyMsg,
      invalid_msg_replyMsg: this.resp.instant.invalid_msg_replyMsg,
    });
    if (this.resp.instant.max_ceiling_type_id === 1 && this.resp.instant.win_sel_type_id === 1) {
      this.flag_winnerCrit = 0;
    }
    if (this.resp.instant.max_ceiling_type_id === 1 && this.resp.instant.win_sel_type_id === 2) {
      this.flag_winnerCrit = 1;
    }
    if (this.resp.instant.max_ceiling_type_id === 2 && this.resp.instant.win_sel_type_id === 2) {
      this.flag_winnerCrit = 2;
    }
    this.stepXInstantForm.setValue({
      max_winPerCellno: this.resp.instant.max_winPerCellno,
      max_winPerCellno_type_id: this.resp.instant.max_winPerCellno_type_id,
      max_total_winners: this.resp.instant.max_total_winners,
      prizeIsAirtime: this.resp.instant.prizeIsAirtime,
      per_entry_winnerCount: this.resp.instant.per_entry_winnerCount,
    });
  }

  checkForEmailReports() {
    if (this.reportScheduleTypeChecker === '1') {
      this.reportSchedule = 1;
      this.getReportDays();
    } else
    if (this.reportScheduleTypeChecker === '2') {
      this.reportSchedule = 2;
      this.getReportDays();
    } else {
      this.reportSchedule = 0;
    }
  }

  getReportDays() {
    this.step4Form.setValue({
      camp_ReportMail: this.resp.campaign.camp_ReportMail,
      camp_rep_day: '',
      camp_rep_hour: '',
    });
    this.reportDays = [];
    for (let index = 0; index < this.resp.reports.count; index++) {
      const element = this.resp.reports.rows[index];
      this.reportDays.push({
        camp_rep_id: element.camp_rep_id,
        camp_rep_day: element.camp_rep_day,
        camp_rep_hour: element.camp_rep_hour,
      });
    }
  }

  getKeywords() {
    this.keywordList = [];
    if (this.resp.keywords.count !== 0) {
      this.shortcodeInfo = (this.resp.keywords.rows[0].Shortcode.sc_number + ' - Value = R' + this.resp.keywords.rows[0].Shortcode.sc_value);
      for (let index = 0; index < this.resp.keywords.count; index++) {
        const element = this.resp.keywords.rows[index];
        this.keywordList.push({
          ckeyw_word: element.ckeyw_word,
          ckeyw_desc: element.ckeyw_desc,
          ckeyw_id: element.ckeyw_id,
        });
      }
    }
  }

  getBlacklisted() {
    this.blacklistList = [];
    for (let index = 0; index < this.resp.blacklist.rows.length; index++) {
      const element = this.resp.blacklist.rows[index];
      this.blacklistList.push({
        blacklist_id: element.blacklist_id,
        blacklist_cellno: element.blacklist_cellno,
        camp_id: element.camp_id,
      });
    }
    this.mdbTable.setDataSource(this.blacklistList);
    this.blacklistList = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(5);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }

  onAddKeyword() {
    this.keywordAdded = true;

    // stop if invalid
    if (this.step3Form.invalid) {
      return;
    }

    if (!this.selectedScId) {
      this.openModalErrorNoKeywordSelected();
      this.form3Clear();
      return;
    }
    this.getKeywordsCheck();
  }

  getKeywordsCheck() {
    this.payload = {
      sc_id: this.selectedScId.sc_id,
      ckeyw_word: this.ckeyw_word.value,
      user_id: this.selectedUserId.user_id,
      camp_id: this.selectedCampaignId.camp_id,
      ckeyw_desc: this.ckeyw_desc.value,
    };
    this.campaignService.tryToAddKeywordToShortcode(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              if (!this.resp.created) {
                this.openModalErrorKeywordAlreadyOnShortcode();
                this.form3Clear();
                return;
              } else {
                this.addKeywordToList();
                return;
              }
            },
        );
  }

  addKeywordToList() {
    this.keywordList.push({
      ckeyw_id: this.resp.keyword.ckeyw_id,
      ckeyw_word: this.resp.keyword.ckeyw_word,
      ckeyw_desc: this.resp.keyword.ckeyw_desc,
    });
    this.form3Clear();
  }

  onDeleteOne(id) {
    if (this.keywordList.length === 1) {
      this.doDedicatedCheck(id);
    } else {
      this.keywordToDelete = {
        ckeyw_id: this.keywordList[id].ckeyw_id,
        camp_id: this.selectedCampaignId.camp_id,
      };
      this.campaignService.deleteKeywordFromCampaign(this.keywordToDelete)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
              (data) => {
                this.openModalSuccessDeleteKeyword();
                this.keywordList.splice(id, 1);
              },
          );
    }
  }

  doDedicatedCheck(id) {
    this.campaignService.getCheckIfShortcodeSharedOrDedicated(this.selectedScId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              if (this.resp.sc_type === 'd') {
                this.keywordToDelete = {
                  ckeyw_id: this.keywordList[id].ckeyw_id,
                  camp_id: this.selectedCampaignId.camp_id,
                };
                this.campaignService.deleteKeywordFromCampaign(this.keywordToDelete)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe(
                        (data) => {
                          this.openModalSuccessDeleteKeyword();
                          this.keywordList.splice(id, 1);
                        },
                    );
              } else {
                this.openModalErrorCantDeleteLastKeyword();
              }
            },
        );
  }

  form3Clear() {
    this.step3Form.reset();
    this.keywordAdded = false;
  }

  onAddReportDay() {
    if (this.reportSchedule === 1) {
      this.reportDays = [];
    }
    this.reportToAdd = {
      camp_id: this.selectedCampaignId.camp_id,
      camp_rep_day: this.camp_rep_day.value,
      camp_rep_hour: this.camp_rep_hour.value,
      reportSchedule: this.reportSchedule,
    };
    this.campaignService.setNewCampaignReportDays(this.reportToAdd)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              if (this.resp.report === 'No Reports') {
                this.reportDays = []; return;
              }
              if (this.reportSchedule === 2) {
                this.camp_rep_day.reset; this.camp_rep_hour.reset;
              }
              if (!this.resp.report.length) {
                this.reportDays.push({
                  camp_rep_day: this.resp.report.camp_rep_day,
                  camp_rep_hour: this.resp.report.camp_rep_hour,
                  camp_rep_id: this.resp.report.camp_rep_id,
                });
              } else {
                this.reportDays = [];
                for (let index = 0; index < this.resp.report.length; index++) {
                  const element = this.resp.report[index];
                  this.reportDays[index] = {
                    camp_rep_day: element.camp_rep_day,
                    camp_rep_hour: element.camp_rep_hour,
                    camp_rep_id: element.camp_rep_id,
                  };
                }
              }
            },
        );
  }

  onDeleteOneReportDay(id) {
    this.reportToDelete = {
      camp_rep_id: this.reportDays[id].camp_rep_id,
    };
    this.campaignService.deleteOneReportDay(this.reportToDelete)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.reportDays.splice(id, 1);
            },
        );
  }

  onAddBlacklistNumber() {
    if (this.step6Form.invalid) {
      this.openModalErrorNoNumberProvided();
      return;
    }
    this.numberToAdd = {
      camp_id: this.selectedCampaignId.camp_id,
      blacklist_cellno: this.blacklist_cellno.value,
    };
    this.campaignService.addSingleBlacklistNumber(this.numberToAdd)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.blacklistResp = data;
              this.blacklistList.push({
                blacklist_id: this.blacklistResp.blacklist_id,
                blacklist_cellno: this.blacklistResp.blacklist_cellno,
              });
              this.mdbTable.setDataSource(this.blacklistList);
              this.blacklistList = this.mdbTable.getDataSource();
              this.previous = this.mdbTable.getDataSource();
            },
        );
    this.step6Form.reset();
  }

  onDeleteOneBlacklist(id) {
    this.numberToDelete = {
      blacklist_id: this.blacklistList[id].blacklist_id,
    };
    this.campaignService.deleteSingleBlacklistNumber(this.numberToDelete)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.blacklistList.splice(id, 1);
              this.mdbTable.setDataSource(this.blacklistList);
              this.blacklistList = this.mdbTable.getDataSource();
              this.previous = this.mdbTable.getDataSource();
            },
        );
  }

  onAddBlacklist() {
    this.modalOptionsBl = {
      data: {
        content: {
          camp_id: this.selectedCampaignId.camp_id,
        },
      },
    };
    this.openModalBlacklistUpload();
  }

  openModalBlacklistUpload() {
    this.modalRef = this.modalService.show(UploadBlacklistModalComponent, this.modalOptionsBl);

    this.modalRef.content.action.subscribe( (result: any) => {
      if (result === true) {
        this.getSelectedCampaignDetails();
      }
    });
  }

  onUpdateCampaign() {
    if (this.camp_ReportMail.invalid && (this.reportSchedule === 1 || this.reportSchedule === 2)) {
      this.openModalErrorTab4();
      return;
    }

    if (this.reportSchedule !== 2) {
      this.onAddReportDay();
    }


    if (!this.campIsInstant) {
      this.updateKeywordCampaign();
    } else if (this.campIsInstant) {
      this.updateInstantCampaign();
    }
  }

  updateKeywordCampaign() {
    this.submitted = true;

    if (this.step1Form.invalid) {
      this.openModalErrorTab1();
      return;
    }

    if (this.step2Form.invalid) {
      this.openModalErrorTab2();
      return;
    }

    if (this.reportSchedule !== 0 && this.camp_ReportMail.errors) {
      this.openModalErrorTab4();
      return;
    }

    if (this.sms2email === true && this.email_to.errors) {
      this.openModalErrorTab5();
      return;
    }

    if (this.camp_startDte.value === this.camp_endDte.value) {
      if (this.camp_startTime.value >= this.camp_endTime.value) {
        this.openModalErrorTimeError();
        return;
      }
    }

    if (this.camp_startDte.value > this.camp_endDte.value) {
      this.openModalErrorDateError();
      return;
    }

    this.payload = {
      camp_isInstant: this.campIsInstant,
      camp_id: this.selectedCampaignId.camp_id,
      camp_name: this.camp_name.value,
      camp_desc: this.camp_desc.value,
      camp_startDte: this.camp_startDte.value + ' ' + this.camp_startTime.value,
      camp_endDte: this.camp_endDte.value + ' ' + this.camp_endTime.value,
      camp_status: this.camp_status,
      camp_replyMsg: this.camp_replyMsg.value,
      camp_replyMsgEnd: this.camp_replyMsgEnd.value,
      camp_ReportMail: this.camp_ReportMail.value,
      camp_hasReport: this.reportSchedule,
      camp_hasEmail: this.sms2email,
      email_to: this.email_to.value,
      email_cc: this.email_cc.value,
    };
    this.campaignService.updateUserCampaign(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.finalResp = data;
              if (this.finalResp.error) {
                this.openModalErrorCampNameConflict();
                return;
              } else {
                this.openModalSuccessUpdate();
                this.ngOnInit();
              }
            },
        );
  }


  updateInstantCampaign() {
    this.submitted = true;

    if (this.campIsInstant) {
      if (this.step1InstantForm.invalid) {
        this.openModalErrorTab1();
        return;
      }
      if (this.step2InstantForm.invalid) {
        this.openModalErrorTab2();
        return;
      }
      if (this.stepXInstantForm.invalid) {
        this.openModalErrorTab7();
        return;
      }
      if (this.camp_replyMsgEnd.invalid) {
        this.openModalErrorTab2();
        return;
      }
    } else {
      if (this.step2Form.invalid) {
        this.openModalErrorTab2();
        return;
      }
    }

    if (this.step1Form.invalid) {
      this.openModalErrorTab1();
      return;
    }

    if (this.reportSchedule !== 0 && this.camp_ReportMail.errors) {
      this.openModalErrorTab4();
      return;
    }

    if (this.sms2email === true && this.email_to.errors) {
      this.openModalErrorTab5();
      return;
    }

    if (this.camp_startDte.value === this.camp_endDte.value) {
      if (this.camp_startTime.value >= this.camp_endTime.value) {
        this.openModalErrorTimeError();
        return;
      }
    }

    if (this.camp_startDte.value > this.camp_endDte.value) {
      this.openModalErrorDateError();
      return;
    }

    if (this.reportSchedule === 0) {
      this.tempemail = '';
    } else {
      this.tempemail = this.camp_ReportMail.value;
    }
    this.payload = {
      camp_isInstant: this.campIsInstant,
      camp_status: this.camp_status,
      camp_id: this.selectedCampaignId.camp_id,
      camp_name: this.camp_name.value,
      camp_desc: this.camp_desc.value,
      camp_startDte: this.camp_startDte.value,
      camp_endDte: this.camp_endDte.value,
      flag_normAdv: this.flag_normAdv.value,
      ticket_code_msgPos: this.ticket_code_msgPos.value,
      sc_id: this.selectedScId.sc_id,
      notWin_replyMsg: this.notWin_replyMsg.value,
      prize_won_replyMsg: this.prize_won_replyMsg.value,
      camp_replyMsgEnd: this.camp_replyMsgEnd.value,
      invalid_ticket_replyMsg: this.invalid_ticket_replyMsg.value,
      used_ticket_replyMsg: this.used_ticket_replyMsg.value,
      invalid_msg_replyMsg: this.invalid_msg_replyMsg.value,
      camp_hasReport: this.reportSchedule,
      camp_ReportMail: this.tempemail,
      camp_hasEmail: this.sms2email,
      flag_winnerCrit: this.flag_winnerCrit,
      max_winPerCellno: this.max_winPerCellno.value,
      max_winPerCellno_type_id: this.max_winPerCellno_type_id.value,
      max_total_winners: this.max_total_winners.value,
      per_entry_winnerCount: this.per_entry_winnerCount.value,
      prizeIsAirtime: this.prizeIsAirtime.value,
      email_to: this.email_to.value,
      email_cc: this.email_cc.value,
    };
    this.campaignService.updateUserCampaign(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.finalResp = data;
              if (this.finalResp.error) {
                this.openModalErrorCampNameConflict();
                return;
              } else {
                this.openModalSuccessUpdate();
                this.ngOnInit();
              }
            },
        );
  }

  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', amount: 0, description: '', btnText: ''},
    },
  }

  openModalConfirm() {
    this.modalOptions.data = {
      heading: 'Are you sure?',
      content: {
        heading: 'Are you sure you want to update this campaign with the details you have changed?',
        amount: 0,
        description: '',
        btnText: 'Yes',
      },
    };
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.onUpdateCampaign();
          }
        });
    this.conflictingKeywordCount = 0;
  }

  openModalConfirmBack() {
    this.modalOptions.data = {
      heading: 'Are you sure?',
      content: {
        heading: 'If you go back any unsaved changes will be discarded.',
        amount: 0,
        description: ' Please confirm.',
        btnText: 'Confirm',
      },
    };
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.ngOnInit();
          }
        });
  }

  openModalConfirmShortcodeChange() {
    this.modalOptions.data = {
      heading: 'Really Really Change Shortcode?',
      content: {
        heading: 'You are about to change the shortcode value, ',
        amount: 0,
        description: 'the current keywords will be registered on the new shortcode.',
        btnText: 'Yes',
      },
    };
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.getKeywordsCheck2();
          }
        });
  }

  openModalConfirmKeywordDelete(id) {
    this.modalOptions.data = {
      heading: 'Really Delete this keyword?',
      content: {
        heading: 'You are about to delete this ',
        amount: 1,
        description: ' keyword. Is this your intention?',
        btnText: 'Yup',
      },
    };
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.onDeleteOne(id);
          }
        });
  }

  openModalConfirmUnscheduleReports() {
    this.modalOptions.data = {
      heading: 'Are you sure?',
      content: {
        heading: 'You are about to unschedule all the current scheduled report days. ',
        amount: 0,
        description: 'Click on "No" for Yes.',
        btnText: '"No"',
      },
    };
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.onAddReportDay();
          }
        });
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', btnText: ''},
    },
  }


  openModalSuccess() {
    this.payload2 = {
      keywordArr: this.keywordList,
      camp_id: this.selectedCampaignId.camp_id,
      sc_id: this.selectedScId.sc_id,
      user_id: this.selectedUserId.user_id,
    };

    this.campaignService.changeShortcodeWithKeywords(this.payload2)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {

            },
        );
    this.modalOptionsSuccess.data = {
      heading: 'Keywords Compatible with Shortcode!',
      content: {
        heading: 'Fantastic - These keywords have been removed from the old shortcode and added to the new shortcode',
        btnText: 'Thank you',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }

  openModalSuccessUpdate() {
    this.modalOptionsSuccess.data = {
      heading: 'Great Success!',
      content: {
        heading: 'SUPER-DUPER! Everything went well and changes have been saved. Good on you!',
        btnText: 'Superb',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }

  openModalSuccessDeleteKeyword() {
    this.modalOptionsSuccess.data = {
      heading: 'Going, going, gone...',
      content: {
        heading: 'I have meticulously removed that keyword that you wanted gone.',
        btnText: 'cancel',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }

  modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
    },
  }

  openModalErrorCampNameConflict() {
    this.modalOptionsError.data = {
      heading: 'Quite the popular name...',
      content: {
        heading: 'It appears that your selected campaign name "' + this.camp_name.value + '" is already in use. This is a slight issue because in a world of order we can\'t afford to have conflicting campaign names.',
        suggest: 'Suggested Actions:',
        fix1: 'Try a different campaign name.',
        fix2: 'Try slight variations eg. "' + this.camp_name.value + '7676" or "' + this.camp_name.value + 'abc"',
        fix3: 'Then try again.',
        btnText: '#noConflict',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }

  openModalErrorDateError() {
    this.modalOptionsError.data = {
      heading: 'Fallacy!',
      content: {
        heading: 'The Ending date that you have chosen may not precede the Starting date.',
        suggest: 'Suggested Actions:',
        fix1: 'Try setting the end date to be on or after the starting date.',
        fix2: 'Try taking the laws of Space & Time into consideration.',
        fix3: 'Then try again.',
        btnText: 'Let\'s see',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }

  openModalErrorTimeError() {
    this.modalOptionsError.data = {
      heading: 'Fallacy!',
      content: {
        heading: 'Because both dates are on the same day you are required to set the ending time to after the starting time.',
        suggest: 'Suggested Actions:',
        fix1: 'Try setting the ending time to be after the starting time.',
        fix2: 'Try taking the laws of Space & Time into consideration.',
        fix3: 'Then try again.',
        btnText: 'Let\'s see',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }


  openModalErrorKeywordConflict() {
    this.modalOptionsError.data = {
      heading: 'Keyword Collision...',
      content: {
        heading: 'The keywords could not be changed due to conflict. There are ' + this.conflictingKeywordCount + ' conflicting keywords.',
        suggest: 'Suggested Actions:',
        fix1: 'Try a different shortcode.',
        fix2: 'Try different keywords.',
        fix3: 'Change the other keywords which are causing these conflicts.',
        btnText: '#noConflict',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }

  openModalErrorNoKeywordSelected() {
    this.modalOptionsError.data = {
      heading: 'Missing Keyword (404)...',
      content: {
        heading: 'It would seem like you haven\'t provided a keyword at all.',
        suggest: 'Suggested Actions:',
        fix1: 'Think of a keyword.',
        fix2: 'Type in said keyword.',
        fix3: 'Then try again.',
        btnText: '(201)',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }


  openModalErrorKeywordAlreadyOnShortcode() {
    this.modalOptionsError.data = {
      heading: 'Well that went horrible...',
      content: {
        heading: 'That keyword you thought of (' + this.resp.keyword.ckeyw_word + ') was also thought of by another human and is in use on this specific shortcode.',
        suggest: 'Suggested Actions:',
        fix1: 'Think of a different keyword.',
        fix2: 'Try using a different shortcode.',
        fix3: 'Then try again.',
        btnText: 'be better',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }


  openModalErrorCantDeleteLastKeyword() {
    this.modalOptionsError.data = {
      heading: 'Wax on - Wax off...',
      content: {
        heading: 'It looks like you are trying to remove the only registered keyword. I can not allow you to do that Mr. Anderson...',
        suggest: 'Suggested Actions:',
        fix1: 'Try adding another keyword then try again.',
        fix2: 'Resist the urge to try and delete the last keyword.',
        fix3: 'Never stare directly into the sun.',
        btnText: 'oh okay',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }


  openModalErrorNoNumberProvided() {
    this.modalOptionsError.data = {
      heading: 'That number doesn\'t exist...',
      content: {
        heading: 'Listen, I am always eager for your commands, but how can I blacklist that which does not exist?',
        suggest: 'Suggested Actions:',
        fix1: 'Next time try providing a number for me to blacklist.',
        fix2: '...',
        fix3: '...',
        btnText: 'oh okay',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }

  openModalErrorTab1() {
    this.modalOptionsError.data = {
      heading: 'Invalid Input...',
      content: {
        heading: 'There seems to be some kind of input error on tab 1 - Campaign Details.',
        suggest: 'Suggested Actions:',
        fix1: 'Navigate to Tab 1.',
        fix2: 'Look for red text indicating invalid fields',
        fix3: 'Then try again',
        btnText: 'darn',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }

  openModalErrorTab2() {
    this.modalOptionsError.data = {
      heading: 'Nope...',
      content: {
        heading: 'There seems to be some kind of input error on tab 2 - Shortcode & Reply Messages.',
        suggest: 'Suggested Actions:',
        fix1: 'Head on over to Tab 2.',
        fix2: 'Look for red text indicating invalid fields',
        fix3: 'Then try again',
        btnText: 'dang',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }

  openModalErrorTab4() {
    this.modalOptionsError.data = {
      heading: 'Whoops...',
      content: {
        heading: 'There seems to be some kind of input error on tab 4 - Report Settings.',
        suggest: 'Suggested Actions:',
        fix1: 'Shimmy on over to Tab 4.',
        fix2: 'Look for red text indicating invalid fields',
        fix3: 'Then try again',
        btnText: 'I will',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }

  openModalErrorTab5() {
    this.modalOptionsError.data = {
      heading: '@Email error...',
      content: {
        heading: 'There seems to be some kind of input error on tab 5 - SMS2Email Settings.',
        suggest: 'Suggested Actions:',
        fix1: 'Waddle on over to Tab 5.',
        fix2: 'Look for red text indicating invalid fields',
        fix3: 'Then try again',
        btnText: 'I will',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }

  openModalErrorTab7() {
    this.modalOptionsError.data = {
      heading: 'If (Err) => {throw(err)}',
      content: {
        heading: 'There seems to be some kind of input error on tab 7 - Winner Options.',
        suggest: 'Suggested Actions:',
        fix1: 'Goto Tab 7.',
        fix2: 'Look for red text indicating invalid fields',
        fix3: 'Then try again',
        btnText: 'I will',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }


  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
