import {ConnectionService} from '../../connection.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';

import {ConfirmComponent} from '../../modals/other/confirm/confirm.component';
import {SuccessModalComponent} from '../../modals/other/success-modal/success-modal.component';
import {ErrorComponent} from '../../modals/other/error/error.component';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;
  contactForm: FormGroup;
  public loading: boolean;

  public submitted: boolean;

  constructor(private fb: FormBuilder, private connectionService: ConnectionService, private modalService: MDBModalService) {
  }

  ngOnInit() {
    this.loading = false;
    this.submitted = false;
    this.contactForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(30)]],
      cell: ['', [Validators.required, Validators.minLength(10), Validators.pattern(/^(\+[0-9]|[0-9])*$/), Validators.maxLength(10)]],
      mail: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
      company: ['', [Validators.required, Validators.maxLength(30)]],
      message: ['', Validators.maxLength(300)],
    });
  }

  get p() {
    return this.contactForm.controls;
  }


  onSubmit() {
    this.submitted = true;
    if (this.contactForm.invalid) {
      this.openModalError();
      return;
    }

    this.openModalConfirm();
  }

  onSend() {
    this.loading = true;
    this.connectionService.sendMessageLanding(this.contactForm.value)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.openModalSuccess();
          this.contactForm.reset();
          this.ngOnDestroy();
          this.ngOnInit();
        },
        (err) => {
          this.loading = false;
        },
        );
  }

  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', amount: 0, description: '', btnText: ''},
    },
  }

  openModalConfirm() {
    this.modalOptions.data = {
      heading: 'Confirm Message?',
      content: {
        heading: 'Are you sure you want to send this message?',
        amount: 0,
        description: '',
        btnText: 'Yes Please',
      },
    };
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.onSend();
          }
        });
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', btnText: ''},
    },
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Done and done!',
      content: {
        heading: 'Your message was sent successfully, we will contact you as soon as possible.',
        btnText: 'Thank you',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }

  modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
    },
  }


  openModalError() {
    this.modalOptionsError.data = {
      heading: 'Oops!',
      content: {
        heading: 'Please ensure that you have completed all of the required fields then try again.',
        suggest: 'Suggested Actions:',
        fix1: 'Fill out all fields.',
        fix2: 'Provide a message.',
        fix3: 'Try again.',
        btnText: 'Okay',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }


  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
