/* eslint-disable linebreak-style */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

const baseUrl = environment.phonebooksUrl;

@Injectable({
  providedIn: 'root',
})
export class PhonebookService {
  token = localStorage.getItem('Authorization');

  httpOptions = {
    headers: {},
  };


  constructor(private httpClient: HttpClient) {}

  getUserPhonebooks() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.get(baseUrl, this.httpOptions);
    } return;
  }


  getUserPhonebooksAndCount() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.get(baseUrl + '/count', this.httpOptions);
    } return;
  }

  checkPhonebookForConflicts(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + '/contacts/dupe', payload, this.httpOptions);
    } return;
  }


  checkPhonebookForConflictsOnlyEdit(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + '/contacts/dupe2', payload, this.httpOptions);
    } return;
  }

  createNewPhonebook(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl, payload, this.httpOptions);
    } return;
  }

  deletePhonebook(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.put(baseUrl + '/remove', id, this.httpOptions);
    } return;
  }

  getPhonebookContacts(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + '/contacts', id, this.httpOptions);
    } return;
  }

  // ////////////////////////////////////////////////////////////////////////////////////////////
  // BLACKLIST WINNERS FUNCTIONS
  getWinnersBlacklist() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.get(baseUrl + '/winners/blacklist', this.httpOptions);
    } return;
  }

  editBlacklistWinner(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.put(baseUrl + '/winners/blacklist', payload, this.httpOptions);
    } return;
  }

  addBlacklistWinner(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + '/winners/blacklist', payload, this.httpOptions);
    } return;
  }

  removeBlacklistWinner(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.put(baseUrl + '/winners/blacklist/del', payload, this.httpOptions);
    } return;
  }

  getPhonebooksContacts(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + '/contacts/multi', id, this.httpOptions);
    } return;
  }

  getSelectedContactDetails(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + '/contacts/detail', id, this.httpOptions);
    } return;
  }

  updateContact(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.put(baseUrl + '/contacts', payload, this.httpOptions);
    } return;
  }

  deleteContact(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.put(baseUrl + '/contacts/delete', id, this.httpOptions);
    } return;
  }

  addContact(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + '/contacts/add', payload, this.httpOptions);
    } return;
  }
}
