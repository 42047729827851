import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';

import {ConfirmComponent} from '../../modals/other/confirm/confirm.component';
import {SuccessModalComponent} from '../../modals/other/success-modal/success-modal.component';
import {ErrorComponent} from '../../modals/other/error/error.component';
import {UserService} from '../../services/user.service';
import {LoaderService} from '../../services/loader.service';

@Component({
  selector: 'app-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss'],
})
export class BillingDetailsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  private billingDetails: any;
  public submitted: boolean;
  public tutorialActive: boolean;
  // public userName: string;
  public loading: boolean;
  public loadingfail: boolean;

  billingDetailsForm: FormGroup;

  constructor(
    private billingDetailsService: UserService,
    private fb: FormBuilder,
    private modalService: MDBModalService,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }

  ngOnInit() {
    this.billingDetails = {};
    this.submitted = false;
    this.tutorialActive = false;
    this.loadingfail = false;
    // this.userName = '';

    this.billingDetailsForm = this.fb.group({
      user_pName: ['', [Validators.required, Validators.maxLength(50)]],
      user_pEmail: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
      user_pCell: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern(/^(\+[0-9]|[0-9])*$/)]],
      user_tradingName: ['', [Validators.required, Validators.maxLength(50)]],
      user_vatNum: ['', [Validators.maxLength(10), Validators.minLength(10), Validators.pattern(/^([0-9]|[0-9])*$/)]],
      user_accPerson: ['', [Validators.required, Validators.maxLength(50)]],
      user_email: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
      user_phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern(/^(\+[0-9]|[0-9])*$/)]],
      user_physAddr: ['', [Validators.required, Validators.maxLength(200)]],
      user_physAddrArea: ['', [Validators.required, Validators.maxLength(50)]],
      user_physAddrCity: ['', [Validators.required, Validators.maxLength(50)]],
      user_physAddrAreaCode: ['', [Validators.required, Validators.maxLength(20)]],
    });

    this.loadBillingDetails();
  }

  loadBillingDetails() {
    this.billingDetailsService.getAccountDetails()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.billingDetails = data;
              this.billingDetailsForm.setValue({
                user_pName: this.billingDetails.user.user_pName,
                user_pEmail: this.billingDetails.user.user_pEmail,
                user_pCell: this.billingDetails.user.user_pCell,
                user_tradingName: this.billingDetails.user.user_tradingName,
                user_vatNum: this.billingDetails.user.user_vatNum,
                user_accPerson: this.billingDetails.user.user_accPerson,
                user_email: this.billingDetails.user.user_email,
                user_phone: this.billingDetails.user.user_phone,
                user_physAddr: this.billingDetails.user.user_physAddr,
                user_physAddrArea: this.billingDetails.user.user_physAddrArea,
                user_physAddrCity: this.billingDetails.user.user_physAddrCity,
                user_physAddrAreaCode: this.billingDetails.user.user_physAddrAreaCode,
              });
              // this.userName = this.billingDetails.user.user_name;
            },
            (err) => {
              console.error(err);
              this.loadingfail = true;
            },
        );
  }

  updateBillingDetails() {
    this.billingDetailsService.updateAccountDetails(this.billingDetailsForm.value)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.openModalSuccess();
          this.ngOnInit();
        });
  }

  get p() {
    return this.billingDetailsForm.controls;
  }

  onSubmitBillingDetails() {
    this.submitted = true;
    if (this.billingDetailsForm.invalid) {
      this.openModalError();
      return;
    }
    this.openModalConfirm();
  }

  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', amount: 0, description: '', btnText: ''},
    },
  }

  openModalConfirm() {
    this.modalOptions.data = {
      heading: 'Are you sure?',
      content: {
        heading: 'Are you sure you want to save all changes',
        amount: 0,
        description: ' to your profile?',
        btnText: 'Yes',
      },
    };
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.updateBillingDetails();
          }
        });
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', btnText: ''},
    },
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Update Successful',
      content: {
        heading: 'Your profile was updated successfully',
        btnText: 'Thank you',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }

  modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
    },
  }

  openModalError() {
    this.modalOptionsError.data = {
      heading: 'Oops...',
      content: {
        heading: 'Please ensure that you have provided all required details before proceeding.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure all required fields are provided.',
        fix2: 'Look for the red text to guide you.',
        fix3: 'Then try again.',
        btnText: 'lets see',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {

  }
}
