import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject, interval} from 'rxjs';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {DatePipe} from '@angular/common';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import {LoaderService} from '../../../services/loader.service';
import {ActivatedRoute, Router} from '@angular/router';

import {ReportsCampaignsService} from '../../../services/reports-campaigns.service';
import {CampaignService} from '../../../services/campaign.service';
const counter = interval(300);
@Component({
  selector: 'app-campaign-report',
  templateUrl: './campaign-report.component.html',
  styleUrls: ['./campaign-report.component.scss'],
})
export class CampaignReportComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  private unsubCounter: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  campaignForm: FormGroup;
  optionsCampaigns: Array<any>;

  public scale = 0.5;
  campaign: {camp_id: number};
  public loaded: boolean;
  public loading: boolean;
  public loadingfail: boolean;
  public selected: boolean;
  public userName: string;
  public totalCount: number;
  public dateStamp: any;
  public keyArrCount: Array<any>;
  public fileNameToSave: string;
  public payload: any;
  private resp: any;
  private tempArr: Array<any>;
  public campaignDetail: any;
  public networkArr: Array<any>;
  private selectedCampaignId: any;
  pipe = new DatePipe('en-ZA'); // Use your own locale
  now = Date.now();
  startdate;
  enddate;
  nowdate;
  public statusText: string;

  public headNetworks = ['Month', 'MTN', 'Vodacom', 'CellC', 'TelkomM', 'Other', 'Total'];

  public status = [
    'Starting up report generator....',
    'Starting up report generator........',
    'Starting up report generator............',
    'Starting up report generator................',
    'Starting up report generator................started!',
    'Crunching some numbers',
    'Crunching some numbers....',
    'Crunching some numbers........',
    'Crunching some numbers............',
    'Crunching some numbers................',
    'Crunching some numbers................crunched!',
    'Stopping to smell the roses',
    'Stopping to smell the roses....',
    'Stopping to smell the roses........',
    'Stopping to smell the roses............',
    'Stopping to smell the roses................',
    'Stopping to smell the roses................hhhhmmm!',
    'You sure have a lot of entries',
    'You sure have a lot of entries....',
    'You sure have a lot of entries........',
    'You sure have a lot of entries............',
    'You sure have a lot of entries................',
    'You sure have a lot of entries................WOW!',
    'Testing user patience',
    'Testing user patience....',
    'Testing user patience........',
    'Testing user patience............',
    'Testing user patience................',
    'Testing user patience................failed!',
    'Ran out of loading text...',
  ]

  // CHART TYPES
  public hourlyType: string = 'line';
  public timeslotType: string = 'pie';
  public dayOfWeekType: string = 'bar';
  public weeklyType: string = 'bar';
  public monthlyType: string = 'bar';
  public keywordType: string = 'bar';
  public dailyType: string = 'bar';
  // ///////////////////////////////////////////////////////////////////////////////////

  // CHART DATASETS
  public hourlyDatasets: Array<any> = [
    {data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Hourly Message Totals'},
  ];
  public timeslotDatasets: Array<any> = [
    {data: [0, 0, 0, 0, 0], label: 'Time Slot Message Totals'},
  ];
  public dayOfWeekDatasets: Array<any> = [
    {data: [0, 0, 0, 0, 0, 0, 0], label: 'Day of the week Message Totals'},
  ];
  public weeklyDatasets: Array<any> = [
    {data: [], label: 'Weekly Message Totals'},
  ];
  public monthlyDatasets: Array<any> = [
    {data: [], label: 'Monthly Message Totals'},
  ];
  public keywordDatasets: Array<any> = [
    {data: [], label: 'Keyword Message Totals'},
  ];
  public dailyDatasets: Array<any> = [
    {data: [], label: 'Daily Message Totals'},
  ];
  // ///////////////////////////////////////////////////////////////////////////////////

  // CHART LABELS
  public hourlyLabels: Array<any> = [
    '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00',
    '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00',
  ];
  public timeslotLabels: Array<any> = ['06:00 - 09:00', '09:00 - 12:00', '12:00 - 15:00', '15:00 - 18:00', '18:00 - 21:00'];
  public dayOfWeekLabels: Array<any> = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  public weeklyLabels: Array<any> = [];
  public monthlyLabels: Array<any> = [];
  public keywordLabels: Array<any> = [];
  public dailyLabels: Array<any> = [];
  // ///////////////////////////////////////////////////////////////////////////////////

  // CHART COLORS
  public allColors: Array<any> = [
    {
      backgroundColor: [
        'rgba(3, 169, 244, 0.7)', 'rgba(244, 67, 54, 0.7)', 'rgba(233, 30, 99, 0.7)', 'rgba(156, 39, 176, 0.7)', 'rgba(63, 81, 181, 0.7)', 'rgba(0, 188, 212, 0.7)',
        'rgba(0, 150, 136, 0.7)', 'rgba(76, 175, 80, 0.7)', 'rgba(205, 220, 57, 0.7)', 'rgba(255, 235, 59, 0.7)', 'rgba(255, 152, 0, 0.7)', 'rgba(121, 85, 72, 0.7)',
        'rgba(158, 158, 158, 0.7)', 'rgba(96, 125, 139, 0.7)', 'rgba(0, 0, 0, 0.7)', 'rgba(62, 69, 81, 0.7)',
        'rgba(3, 169, 244, 0.7)', 'rgba(244, 67, 54, 0.7)', 'rgba(233, 30, 99, 0.7)', 'rgba(156, 39, 176, 0.7)', 'rgba(63, 81, 181, 0.7)', 'rgba(0, 188, 212, 0.7)',
        'rgba(0, 150, 136, 0.7)', 'rgba(76, 175, 80, 0.7)', 'rgba(205, 220, 57, 0.7)', 'rgba(255, 235, 59, 0.7)', 'rgba(255, 152, 0, 0.7)', 'rgba(121, 85, 72, 0.7)',
        'rgba(158, 158, 158, 0.7)', 'rgba(96, 125, 139, 0.7)', 'rgba(0, 0, 0, 0.7)', 'rgba(62, 69, 81, 0.7)',
      ],
      borderColor: [
        'rgba(3, 169, 244, 0.3)', 'rgba(244, 67, 54, 0.3)', 'rgba(233, 30, 99, 0.3)', 'rgba(156, 39, 176, 0.3)', 'rgba(63, 81, 181, 0.3)', 'rgba(0, 188, 212, 0.3)',
        'rgba(0, 150, 136, 0.3)', 'rgba(76, 175, 80, 0.3)', 'rgba(205, 220, 57, 0.3)', 'rgba(255, 235, 59, 0.3)', 'rgba(255, 152, 0, 0.3)', 'rgba(121, 85, 72, 0.3)',
        'rgba(158, 158, 158, 0.3)', 'rgba(96, 125, 139, 0.3)', 'rgba(0, 0, 0, 0.3)', 'rgba(62, 69, 81, 0.3)',
        'rgba(3, 169, 244, 0.3)', 'rgba(244, 67, 54, 0.3)', 'rgba(233, 30, 99, 0.3)', 'rgba(156, 39, 176, 0.3)', 'rgba(63, 81, 181, 0.3)', 'rgba(0, 188, 212, 0.3)',
        'rgba(0, 150, 136, 0.3)', 'rgba(76, 175, 80, 0.3)', 'rgba(205, 220, 57, 0.3)', 'rgba(255, 235, 59, 0.3)', 'rgba(255, 152, 0, 0.3)', 'rgba(121, 85, 72, 0.3)',
        'rgba(158, 158, 158, 0.3)', 'rgba(96, 125, 139, 0.3)', 'rgba(0, 0, 0, 0.3)', 'rgba(62, 69, 81, 0.3)',
      ],
      borderWidth: 2,
    },
  ]
  public hourlyColors: Array<any> = [
    {
      backgroundColor: 'rgba(105, 0, 132, .2)',
      borderColor: 'rgba(200, 99, 132, .7)',
      borderWidth: 2,
    },
  ];
  // ///////////////////////////////////////////////////////////////////////////////////
  public HourlyOptions: any = {
    responsive: true,
    title: {display: true, fontSize: 25, fontColor: '#000', padding: 50, text: 'Hourly Message Totals:'},
    layout: {padding: {right: 50}},
    scales: {
      xAxes: [{scaleLabel: {fontSize: 20, display: true, labelString: 'Hour of Day', fontStyle: 'bold'}}],
      yAxes: [{ticks: {beginAtZero: true}, scaleLabel: {fontSize: 20, display: true, labelString: 'SMS Count', fontStyle: 'bold'}}]},
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
          weight: 'bold',
        },
      },
    },
  };
  public pieOptions: any = {
    responsive: true,
    title: {display: true, fontSize: 25, fontColor: '#000', padding: 50, text: 'Time Slot Message Totals:'},
    plugins: {
      datalabels: {
        font: {
          size: 20,
          weight: 'bold',
          color: '#000',
        },
      },
    },
  };
  public DayOptions: any = {
    responsive: true,
    title: {display: true, fontSize: 25, fontColor: '#000', padding: 50, text: 'Day Of The Week Message Totals:'},
    scales: {
      xAxes: [{scaleLabel: {fontSize: 20, display: true, labelString: 'Day of Week', fontStyle: 'bold'}}],
      yAxes: [{ticks: {beginAtZero: true}, scaleLabel: {fontSize: 20, display: true, labelString: 'SMS Count', fontStyle: 'bold'}}]},
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
          weight: 'bold',
        },
      },
    },
  };
  public WeekOptions: any = {
    responsive: true,
    title: {display: true, fontSize: 25, fontColor: '#000', padding: 50, text: 'Weekly Message Totals:'},
    scales: {
      xAxes: [{scaleLabel: {fontSize: 20, display: true, labelString: 'Weeks (7-Day intervals)', fontStyle: 'bold'}}],
      yAxes: [{ticks: {beginAtZero: true}, scaleLabel: {fontSize: 20, display: true, labelString: 'SMS Count', fontStyle: 'bold'}}]},
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
          weight: 'bold',
        },
      },
    },
  };
  public MonthOptions: any = {
    responsive: true,
    title: {display: true, fontSize: 25, fontColor: '#000', padding: 50, text: 'Monthly Message Totals:'},
    scales: {
      xAxes: [{scaleLabel: {fontSize: 20, display: true, labelString: 'Month of Year', fontStyle: 'bold'}}],
      yAxes: [{ticks: {beginAtZero: true}, scaleLabel: {fontSize: 20, display: true, labelString: 'SMS Count', fontStyle: 'bold'}}]},
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
          weight: 'bold',
        },
      },
    },
  };
  public KeywordOptions: any = {
    responsive: true,
    title: {display: true, fontSize: 25, fontColor: '#000', padding: 50, text: 'Keyword Message Totals:'},
    scales: {
      xAxes: [{scaleLabel: {fontSize: 20, display: true, labelString: 'Keyword', fontStyle: 'bold'}}],
      yAxes: [{ticks: {beginAtZero: true}, scaleLabel: {fontSize: 20, display: true, labelString: 'SMS Count', fontStyle: 'bold'}}]},
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
          weight: 'bold',
        },
      },
    },
  };
  public DailyOptions: any = {
    responsive: true,
    title: {display: true, fontSize: 25, fontColor: '#000', padding: 50, text: 'Daily Message Totals:'},
    scales: {
      xAxes: [{scaleLabel: {fontSize: 20, display: true, labelString: 'Day of Year', fontStyle: 'bold'}}],
      yAxes: [{ticks: {beginAtZero: true}, scaleLabel: {fontSize: 20, display: true, labelString: 'SMS Count', fontStyle: 'bold'}}]},
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        rotation: -90,
        font: {
          size: 15,
          weight: 'bold',
        },
      },
    },
  }


  constructor(
    private reportsCampaigns: ReportsCampaignsService,
    private campaignService: CampaignService,
    private fb: FormBuilder,
    private modalService: MDBModalService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }


  ngOnInit() {
    this.fileNameToSave = '';
    this.loading = false;
    this.loadingfail = false;
    this.loaded = false;
    this.selected = false;
    this.userName = '';
    this.totalCount = 0;
    this.keyArrCount = [];
    this.payload = {};
    this.resp = {};
    this.tempArr = [];
    this.campaignDetail = {};
    this.networkArr = [];
    this.selectedCampaignId = 0;
    this.statusText = 'Starting up report generator';
    this.campaignForm = this.fb.group({
      camp_id: ['', Validators.required],
    });

    this.hourlyDatasets = [{data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Hourly Message Totals'}];
    this.timeslotDatasets = [{data: [0, 0, 0, 0, 0], label: 'Time Slot Message Totals'}];
    this.dayOfWeekDatasets = [{data: [0, 0, 0, 0, 0, 0, 0], label: 'Day of the week Message Totals'}];
    this.weeklyDatasets = [{data: [], label: 'Weekly Message Totals'}];
    this.monthlyDatasets = [{data: [], label: 'Monthly Message Totals'}];
    this.keywordDatasets = [{data: [], label: 'Keyword Message Totals'}];
    this.dailyDatasets = [{data: [], label: 'Daily Message Totals'}];

    this.weeklyLabels = [];
    this.monthlyLabels = [];
    this.keywordLabels = [];
    this.dailyLabels = [];

    this.loadAllCampaigns();

    this.campaign = {
      camp_id: this.route.snapshot.params['camp_id'],
    };

    this.selectedCampaign();

    if (this.campaign.camp_id !== undefined) {
      this.campaignForm.setValue({
        camp_id: this.campaign.camp_id,
      });
    }
  }

  loadAllCampaigns() {
    this.campaignService.getUserCampaigns()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              for (let index = 0; index < this.resp.count; index++) {
                const element = this.resp.rows[index];
                this.tempArr[index] = {
                  value: element.camp_id,
                  label: element.camp_name,
                };
              }
              this.optionsCampaigns = this.tempArr;
              this.tempArr = [];
              this.resp = {};
            },
            (err) => {
              this.loadingfail = true;
            },
        );
  }

  selectedCampaign() {
    this.campaignForm.get('camp_id').valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (value) => {
          this.selectedCampaignId = {camp_id: value};
          this.loadAllCampaignStats();
          counter
              .pipe(takeUntil(this.unsubCounter))
              .subscribe((n) => {
                if (n < this.status.length) {
                  this.statusText = this.status[n];
                } else {
                  n = 5;
                }
              });
          this.selected = true;
        });
  }

  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }

  loadAllCampaignStats() {
    this.payload = this.selectedCampaignId;
    this.reportsCampaigns.getSelectedCampaignStats(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              this.userName = this.resp.userName;
              this.startdate = this.pipe.transform(this.resp.campaign.camp_startDte, 'yyyy-MM-dd');
              this.enddate = this.pipe.transform(this.resp.campaign.camp_endDte, 'yyyy-MM-dd');
              this.nowdate = this.pipe.transform(this.now, 'yyyy-MM-dd');
              this.dateStamp = this.pipe.transform(this.now, 'yyyy-MM-dd HH:mm:ss');
              if (this.nowdate > this.enddate) {
                this.nowdate = this.enddate;
              }
              this.campaignDetail = {
                rperiod: this.startdate + ' to ' + this.nowdate,
                name: this.resp.campaign.camp_name,
                cperiod: this.startdate + ' to ' + this.enddate,
                scode: this.resp.shortcode.sc_number,
                svalue: this.resp.shortcode.sc_desc.substring(2),
              };
              this.fileNameToSave = this.campaignDetail.name + '_Report_.pdf';
              this.networkArr = this.resp.networkArr.reverse();
              this.totalCount = this.networkArr[this.networkArr.length-1].count;
              this.hourlyDatasets = [{data: this.resp.hourlyArr, label: 'Total Messages'}];
              this.timeslotDatasets = [{data: this.resp.timeslotArr, label: 'Total Messages'}];
              this.dayOfWeekDatasets = [{data: this.resp.dayArr, label: 'Total Messages'}];
              // this.resp.weeklyCountArr.splice(0,1);
              this.weeklyDatasets = [{data: this.resp.weeklyCountArr, label: 'Total Messages'}];
              for (let index = 0; index < this.resp.weeklyDatesArr.length; index++) {
                const element = this.resp.weeklyDatesArr[index];
                this.weeklyLabels[index] = element;
              }
              this.monthlyDatasets = [{data: this.resp.monthlyArr, label: 'Total Messages'}];
              for (let index1 = 0; index1 < this.resp.monthlyArrMonths.length; index1++) {
                const element = this.resp.monthlyArrMonths[index1];
                this.monthlyLabels[index1] = element;
              }
              this.dailyDatasets = [{data: this.resp.dailyArrCount, label: 'Total Messages'}];
              for (let index2 = 0; index2 < this.resp.dailyArrDays.length; index2++) {
                const element = this.resp.dailyArrDays[index2];
                this.dailyLabels[index2] = element;
              }

              for (let index3 = 0; index3 < this.resp.finalKeyArr.length; index3++) {
                const element = this.resp.finalKeyArr[index3].smsi_keyword;
                const element2 = this.resp.finalKeyArr[index3].count;
                this.keywordLabels[index3] = element;
                this.keyArrCount[index3] = element2;
              }
              this.keywordDatasets = [{data: this.keyArrCount, label: 'Total Messages'}];
              this.loaded = true;
              this.unsubCounter.next();
              this.ngUnsubscribe.next();
            },
            (err) => {
              this.ngOnInit();
            },
        );
  }

  resetPage() {
    this.router.navigate(['/shortcode/campaign/reports/report']);
    this.ngOnInit();
  }

  modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
    },
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
