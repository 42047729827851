/* eslint-disable max-len */
import {Component, OnInit, OnDestroy, Input, ViewChild, AfterViewInit, ChangeDetectorRef, HostListener} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MdbTablePaginationComponent, MdbTableDirective, MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import {PhonebookService} from '../../../../../services/phonebook.service';
import {ContactModalComponent} from '../../../../../modals/edit/contact-modal/contact-modal.component';
import {UploadModalComponent} from '../../../../../modals/file/upload-modal/upload-modal.component';

import {ConfirmComponent} from '../../../../../modals/other/confirm/confirm.component';
import {SuccessModalComponent} from '../../../../../modals/other/success-modal/success-modal.component';
import {ErrorComponent} from '../../../../../modals/other/error/error.component';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() phonebookToEdit: {pbid: number, col1: string, col2: string, col3: string, col4: string};
  @ViewChild(MdbTablePaginationComponent, {static: true}) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, {static: true}) mdbTable: MdbTableDirective;
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  private resp;
  public contacts: any = [];
  private contactsPrevious: any = [];
  private col1head;
  private col2head;
  private col3head;
  private col4head;
  public contactsHeadings = [];
  private modalOptions = {};

  public searchText: string;
  public previous: string;

  private tempArr: any = [];
  private contactToDelete: any = [];
  private contactToEdit: any = [];

  constructor(
    private phonebookService: PhonebookService,
    private cdRef: ChangeDetectorRef,
    private modalService: MDBModalService,
  ) { }

  @HostListener('input') oninput() {
    this.mdbTablePagination.searchText = this.searchText;
  }

  ngOnInit() {
    this.searchText = '';
    this.previous = '';

    this.resp = {};
    this.getContacts();
    this.col1head = this.phonebookToEdit.col1;
    this.col2head = this.phonebookToEdit.col2;
    this.col3head = this.phonebookToEdit.col3;
    this.col4head = this.phonebookToEdit.col4;
    this.contactsHeadings = ['Contact Number', this.col1head, this.col2head, this.col3head, this.col4head, 'Remove Contact', 'Edit Contact'];
  }
  getContacts() {
    this.phonebookService.getPhonebookContacts({ucgoup_id: this.phonebookToEdit.pbid})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              for (let index = 0; index < this.resp.count; index++) {
                const element = this.resp.rows[index];
                this.contacts[index] = element;
              }
              this.mdbTable.setDataSource(this.contacts);
              this.contacts = this.mdbTable.getDataSource();
              this.previous = this.mdbTable.getDataSource();
            },
        );
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.contacts = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.contacts = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.mdbTable.searchDataObservable(this.searchText).subscribe(() => {
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
    });
  }

  onEditContact(id) {
    this.modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: '',
      containerClass: '',
      animated: true,
      data: {
        title: 'Edit Contact',
        headings: {
          col1head: this.col1head,
          col2head: this.col2head,
          col3head: this.col3head,
          col4head: this.col4head,
        },
        content: {
          contact_cell: this.contacts[id].contact_cell,
          col1: this.contacts[id].contact_col1,
          col2: this.contacts[id].contact_col2,
          col3: this.contacts[id].contact_col3,
          col4: this.contacts[id].contact_col4,
          contact_id: this.contacts[id].contact_id,
          ucgroup_id: this.contacts[id].ucgroup_id,
        },
        buttonText: 'Update Contact',
      },
    };
    this.openModal();
  }

  onDeleteContact(id) {
    if (this.contacts.length === 1) {
      this.openModalErrorInput();
      return;
    }

    this.phonebookService.deleteContact({contact_id: this.contacts[id].contact_id})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.contacts.splice(id, 1);
              this.openModalSuccessDelete();
              this.ngOnInit();
            },
        );
  }

  onAddContact() {
    this.modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: '',
      containerClass: '',
      animated: true,
      data: {
        title: 'Add New Contact',
        headings: {
          col1head: this.col1head,
          col2head: this.col2head,
          col3head: this.col3head,
          col4head: this.col4head,
        },
        content: {
          contact_cell: '',
          col1: '',
          col2: '',
          col3: '',
          col4: '',
          contact_id: 0,
          ucgroup_id: this.phonebookToEdit.pbid,
        },
        buttonText: 'Add Contact',
      },
    };
    this.openModal();
  }
  openModal() {
    this.modalRef = this.modalService.show(ContactModalComponent, this.modalOptions);

    this.modalRef.content.action.subscribe( (result: any) => {
      if (result === true) {
        this.openModalSuccess();
        this.ngOnInit();
      }
    });
  }


  onAddContactList() {
    this.modalOptions = {
      data: {
        heading: {
          col1: this.col1head,
          col2: this.col2head,
          col3: this.col3head,
          col4: this.col4head,
        },
        content: {
          ucgroup_id: this.phonebookToEdit.pbid,
        },
      },
    };
    this.openModalFileUpload();
  }

  openModalFileUpload() {
    this.modalRef = this.modalService.show(UploadModalComponent, this.modalOptions);

    this.modalRef.content.action.subscribe( (result: any) => {
      if (result === true) {
        this.openModalSuccessUpload();
        this.ngOnInit();
      }
    });
  }


    modalOptionsConfirm = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-dialog modal-notify modal-info',
      containerClass: 'modal fade',
      animated: true,
      data: {
        heading: '',
        content: {heading: '', amount: 0, description: '', btnText: ''},
      },
    }

    openModalConfirmDelete(id: any) {
      this.modalOptionsConfirm.data = {
        heading: 'Really remove this contact?',
        content: {
          heading: 'Are you sure you want to permanently remove this contact, friend and companion',
          amount: 0,
          description: '? This is a permanent action! Once I have your confirmation I can start with the termination process...',
          btnText: 'I really want to',
        },
      };
      this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptionsConfirm);
      this.modalRef.content.action
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe( (result: any) => {
            if (result) {
              this.onDeleteContact(id);
            }
          });
    }

    modalOptionsSuccess = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-dialog modal-notify modal-success',
      containerClass: 'modal fade',
      animated: true,
      data: {
        heading: '',
        content: {heading: '', btnText: ''},
      },
    }

    openModalSuccess() {
      this.modalOptionsSuccess.data = {
        heading: 'More contacts!',
        content: {
          heading: 'I just verified and your contact has been successfully updated (and/or) saved... Today - adding contacts; Tomorrow - Ruling the world!',
          btnText: 'Acknowledge',
        },
      };
      this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
    }

    openModalSuccessDelete() {
      this.modalOptionsSuccess.data = {
        heading: 'Taken care of!',
        content: {
          heading: 'That thing you wanted me to take care of - The thing regarding that other contact. It has been done, no evidence linking back to you.',
          btnText: 'Good riddance',
        },
      };
      this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
    }

    openModalSuccessUpload() {
      this.modalOptionsSuccess.data = {
        heading: 'Because time is money.',
        content: {
          heading: 'Just letting you know that I just finished reading through all of your contacts in your file. All of them seemed legit so I went ahead and added them '+
          'to your phonebook.',
          btnText: 'All done',
        },
      };
      this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
    }


    modalOptionsErrorInput = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-dialog modal-notify modal-danger',
      containerClass: 'modal fade',
      animated: true,
      data: {
        heading: '',
        content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
      },
    }

    openModalErrorInput() {
      this.modalOptionsErrorInput.data = {
        heading: 'Oops...',
        content: {
          heading: 'It seems like you are trying to remove the last contact in your phonebook.',
          suggest: 'Suggested Actions:',
          fix1: 'For technical reasons this is commonly viewed as a illegal move.',
          fix2: 'If you have to remove this contact - Try adding another contact first then try again.',
          fix3: 'Alternatively you can remove the entire phonebook and start anew.',
          btnText: 'Okay',
        },
      };
      this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
    }


    ngOnDestroy(): any {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }

    ngAfterViewInit() {
      this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
      this.cdRef.detectChanges();
    }
}
