/* eslint-disable max-len */
/* eslint-disable no-multi-str */
import {Injectable} from '@angular/core';
import Shepherd from 'shepherd.js';

const campWinnerTour = new Shepherd.Tour({
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
    classes: 'shadow-md bg-purple-dark',
    scrollTo: {behavior: 'smooth', block: 'center'},
  },
  confirmCancel: false,
  // confirmCancelMessage: '',
  exitOnEsc: false,
  keyboardNavigation: false,
  useModalOverlay: true,
});

@Injectable({
  providedIn: 'root',
})
export class TutRandomWinnerService {
  constructor() { }

  campWinnerTour() {
    campWinnerTour.addSteps([{
      id: 'start',
      title: 'Generating Random Winners',
      text: 'This is an interactive tutorial that explains how to generate random winners from a campaign.<br><br>\
      As always It\'s a pleasure to assist you. At the end you will be exceptionally good at making people winners!<br><br>\
      <mark>- At some steps I might ask you to interact with the page before we can move on. These interactive actions will be highlighted like this</mark>',
      buttons: [
        {action() {
          return this.next();
        }, text: 'Let\'s Begin'},
      ],
    }, {
      id: 'step1',
      title: 'Random Winner Generator',
      text: 'Here you can generate a specified amount of winners randomly.<br><br>\
      <i>Everyone likes to win!</i>',
      attachTo: {element: '.mainS20', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step2',
      title: 'Time Range Selection',
      text: 'Firstly, you need to specify the time range from which to select winners from.<br><br>\
      <i>Currently the switch is set to "Total Campaign". This means that I will be using all entries from the moment that your campaign has opened until the moment your campaign closed.</i>',
      attachTo: {element: '.s2', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step3',
      title: 'Select a Campaign',
      text: 'Here you should select one of your campaign to generate winners from.<br><br>',
      attachTo: {element: '.s3', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step4',
      title: 'Amount of Winners',
      text: 'Then you indicate the amount of winners that I should generate.<br><br>',
      attachTo: {element: '.s4', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step5',
      title: 'Generate Random Winners',
      text: 'Finally, you then click on the "Generate Random Winners" button.<br><br>',
      attachTo: {element: '.s5', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step6',
      title: 'The List of Winners',
      text: 'The list of generated winners will then be displayed in a table.<br><br>\
      You can also export this list as an excel file.',
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step7',
      title: 'Oh yeah',
      text: '"But what about flipping the Winner Selection switch to Selected Time?" - I hear you ask.<br><br>\
      I\'m glad you asked! This would have looked horribly on my performance review.',
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step8',
      title: 'Selected Time',
      text: 'Flipping this switch over to "Selected Time" will allow you to specify an exact time range from which to select winners instead of using the entire campaign live span.<br><br>\
      <mark>Please flip this switch over to "Selected Time" to continue.</mark>',
      attachTo: {element: '.s8', on: 'bottom'},
      advanceOn: {selector: '.s8a', event: 'click'},
      canClickTarget: true,
    }, {
      id: 'step9',
      title: 'Start and End Times',
      text: 'Now these two date fields are visible. You are now able to specify a precise time range.<br><br>\
      <i>I hope you are happy now.</i>',
      attachTo: {element: '.s9', on: 'top'},
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 200);
        });
      },
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'I am'},
      ],
    }, {
      id: 'step10',
      title: 'Well Done!',
      text: 'You have just successfully completed this tour.<br><br>\
      <i>This concludes our tutorial.</i><br><br>',
      buttons: [
        {action() {
          return this.complete();
        }, text: 'Fin'},
      ],
    },
    ]);


    campWinnerTour.start();
  }
}
