
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-price-other',
  templateUrl: './price-other.component.html',
  styleUrls: ['./price-other.component.scss'],
})
export class PriceOtherComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
