import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';


import {LoginService} from '../login.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
        private router: Router,
        private authenticationService: LoginService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const Authorization = this.authenticationService.AuthorizationValue;
    if (Authorization) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/landing'], {queryParams: {returnUrl: state.url}});
    return false;
  }
}
