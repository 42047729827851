import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

import {Dashboard} from '../models/dashboard-model';

const apiURL = environment.dashboardUrl;

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  token = localStorage.getItem('Authorization');

  httpOptions = {
    headers: {},
  };

  constructor(private httpClient: HttpClient) {}

  public getDashContent(): Observable<Dashboard[]> {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };

      return this.httpClient.get<Dashboard[]>(apiURL, this.httpOptions);
    } return;
  }
}
