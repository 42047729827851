/* eslint-disable linebreak-style */
import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {PhonebookService} from '../../../services/phonebook.service';
import {LoaderService} from '../../../services/loader.service';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss'],
})
export class ContactModalComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  action: Subject<any> = new Subject();

  editContactForm: FormGroup;
  public content: {contact_cell: string, col1: string, col2: string, col3: string, col4: string, contact_id: number, ucgroup_id: number};
  public headings: any;
  public title: string;
  public buttonText: any;
  public submitted: boolean;
  public contactDuplicate: boolean;
  public loading: boolean;
  public loadingtext: string;
  private payload: any;
  private payload2: any;
  private resp: any;

  constructor(public modalRef: MDBModalRef, private fb: FormBuilder, private phonebookService: PhonebookService, private modalService: MDBModalService,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }


  ngOnInit() {
    this.contactDuplicate = false;
    this.resp = {};
    this.payload2 = {};
    this.submitted = false;
    this.loadingtext = '';
    this.editContactForm = this.fb.group({
      contact_cell: [this.content.contact_cell, [Validators.required, Validators.pattern(/^(0|27)[0-9]{9}$/)]],
      col1: [this.content.col1, [Validators.required, Validators.maxLength(50)]],
      col2: [this.content.col2, [Validators.required, Validators.maxLength(50)]],
      col3: [this.content.col3, [Validators.required, Validators.maxLength(50)]],
      col4: [this.content.col4, [Validators.required, Validators.maxLength(50)]],
    });
  }

  get p() {
    return this.editContactForm.controls;
  }
  get contact_cell() {
    return this.editContactForm.get('contact_cell');
  }
  get col1() {
    return this.editContactForm.get('col1');
  }
  get col2() {
    return this.editContactForm.get('col2');
  }
  get col3() {
    return this.editContactForm.get('col3');
  }
  get col4() {
    return this.editContactForm.get('col4');
  }

  onUpdateContact() {
    this.submitted = true;

    if (this.headings.col1head && this.col1.invalid) {
      return;
    }
    if (this.headings.col2head && this.col2.invalid) {
      return;
    }
    if (this.headings.col3head && this.col3.invalid) {
      return;
    }
    if (this.headings.col4head && this.col4.invalid) {
      return;
    }
    if (this.contact_cell.invalid) {
      return;
    }

    this.payload = {
      contact_id: this.content.contact_id,
      contact_cell: this.contact_cell.value,
      contact_col1: this.col1.value,
      contact_col2: this.col2.value,
      contact_col3: this.col3.value,
      contact_col4: this.col4.value,
      ucgroup_id: this.content.ucgroup_id,
    };

    this.checkIfDuplicateNumber();
  }

  checkIfDuplicateNumber() {
    if (this.content.contact_id === 0) {
      if (this.contact_cell.value[0] === '0') {
        this.payload2 = {
          ucgroup_id: this.content.ucgroup_id,
          cellArr: [this.contact_cell.value, this.contact_cell.value.replace(0, 27)],
        };
      } else if (this.contact_cell.value[0] === '2') {
        this.payload2 = {
          ucgroup_id: this.content.ucgroup_id,
          cellArr: [this.contact_cell.value, this.contact_cell.value.replace(27, 0)],
        };
      }

      this.phonebookService.checkPhonebookForConflicts(this.payload2)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
              (data) => {
                this.resp = data;
                if (this.resp.count === 0) {
                  this.contactDuplicate = false;
                  this.updateContact();
                } else {
                  this.contactDuplicate = true;
                  return;
                }
              },
          );
    } else {
      if (this.contact_cell.value[0] === '0') {
        this.payload2 = {
          ucgroup_id: this.content.ucgroup_id,
          cellArr: [this.contact_cell.value, this.contact_cell.value.replace(0, 27)],
          contact_id: this.content.contact_id,
        };
      } else if (this.contact_cell.value[0] === '2') {
        this.payload2 = {
          ucgroup_id: this.content.ucgroup_id,
          cellArr: [this.contact_cell.value, this.contact_cell.value.replace(27, 0)],
          contact_id: this.content.contact_id,
        };
      }

      this.phonebookService.checkPhonebookForConflictsOnlyEdit(this.payload2)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
              (data) => {
                this.resp = data;
                if (this.resp.count === 0) {
                  this.contactDuplicate = false;
                  this.updateContact();
                } else {
                  this.contactDuplicate = true;
                  return;
                }
              },
          );
    }
  }

  updateContact() {
    if (this.content.contact_id !== 0) {
      this.loadingtext = 'Updating Contact...';
      this.phonebookService.updateContact(this.payload)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
              (data) => {
                this.action.next(true);
                this.modalRef.hide();
              },
          );
    } else {
      this.loadingtext = 'Creating Contact...';
      this.phonebookService.addContact(this.payload)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
              (data) => {
                this.action.next(true);
                this.modalRef.hide();
              });
    }
  }

  modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
    },
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
