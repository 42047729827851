import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

const baseUrl = environment.xlsxReportUrl;

@Injectable({
  providedIn: 'root',
})
export class XlsxService {
  token = localStorage.getItem('Authorization');

  httpOptions = {
    headers: {},
  };

  httpOptionsDownload = {
    responseType: 'blob',
    headers: {},
  }


  constructor(private httpClient: HttpClient) {}

  public ReceivedSms(payload) {
    if (this.token !== null) {
      const httpOptionsDownload = {
        responseType: 'blob' as 'json',
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + '/sms/reply', payload, httpOptionsDownload);
    } return;
  }

  public CampaignEntries(payload) {
    if (this.token !== null) {
      const httpOptionsDownload = {
        responseType: 'blob' as 'json',
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + '/campaign/entries', payload, httpOptionsDownload);
    } return;
  }

  public CampaignResponses(payload) {
    if (this.token !== null) {
      const httpOptionsDownload = {
        responseType: 'blob' as 'json',
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + '/campaign/response', payload, httpOptionsDownload);
    } return;
  }

  public CampaignValidEntries(payload) {
    if (this.token !== null) {
      const httpOptionsDownload = {
        responseType: 'blob' as 'json',
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + '/campaign/valid', payload, httpOptionsDownload);
    } return;
  }

  public SentSms(payload) {
    if (this.token !== null) {
      const httpOptionsDownload = {
        responseType: 'blob' as 'json',
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + '/sms/sent', payload, httpOptionsDownload);
    } return;
  }


  public SentEmail2Sms(payload) {
    if (this.token !== null) {
      const httpOptionsDownload = {
        responseType: 'blob' as 'json',
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
        },
      };
      return this.httpClient.post(baseUrl + '/email2sms/sent', payload, httpOptionsDownload);
    } return;
  }

// public async ReceivedSms(): Promise<Blob> {
//   const file =  await this.httpClient.post<Blob>(
//     baseUrl + '/sms/reply',
//     {responseType: 'blob' as 'json'}).toPromise();
//   return file;
// }
}
