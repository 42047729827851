/* eslint-disable no-multi-str */
/* eslint-disable max-len */
/* eslint-disable no-invalid-this */
import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {MdbTableDirective, MdbTablePaginationComponent} from 'ng-uikit-pro-standard';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {IMyOptions, MDBModalRef, MDBModalService, TabsetComponent} from 'ng-uikit-pro-standard';
import {DatePipe} from '@angular/common';
import {ConfirmComponent} from '../../modals/other/confirm/confirm.component';
import {SuccessModalComponent} from '../../modals/other/success-modal/success-modal.component';
import {ErrorComponent} from '../../modals/other/error/error.component';
import Shepherd from 'shepherd.js';
import {UploadBlacklistModalComponent} from '../../modals/file/upload-blacklist-modal/upload-blacklist-modal.component';
import {CampaignService} from '../../services/campaign.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoaderService} from '../../services/loader.service';

const tour = new Shepherd.Tour({
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
    classes: 'shadow-md bg-purple-dark',
    scrollTo: {behavior: 'smooth', block: 'center'},
  },
  confirmCancel: false,
  exitOnEsc: false,
  keyboardNavigation: false,
  useModalOverlay: true,
});

@Component({
  selector: 'app-manage-campaign',
  templateUrl: './manage-campaign.component.html',
  styleUrls: ['./manage-campaign.component.scss'],
})
export class ManageCampaignComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MdbTablePaginationComponent, {static: false}) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, {static: false}) mdbTable: MdbTableDirective;
  @ViewChild('staticTabs', {static: false}) staticTabs: TabsetComponent;
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  public myDatePickerOptions: IMyOptions = {
    todayBtnTxt: 'Today',
    clearBtnTxt: 'Clear',
    closeBtnTxt: 'Close',
    closeAfterSelect: true,
    editableDateField: false,
    disableUntil: {year: 0, month: 0, day: 0},
  };

  public maxVisibleItems: number;
  public previous: Array<any>;
  public blacklistCount: number;

  public campaign: {camp_id: number};
  private modalOptions: {data: {content: {camp_id: number}}};
  private resp: any;
  private respBl: any;
  private campResp: any;
  private instantResp: any;
  private keywordResp: any;
  private reportResp: any;
  private emailResp: any;
  private blacklistResp: any;
  private tempResp: any;

  public initialEndDate: string;
  public endDteIsChanged: boolean;
  public selected: boolean;
  public submitted: boolean;
  public keywordAdded: boolean;
  public campIsInstant: boolean;
  public flag_winnerCrit: number;
  public tutorialActive : boolean;
  public isShortcodeDedicated: boolean;
  public blacklistManual: boolean;
  public loading: boolean;
  public loadingfail: boolean;
  public isShortcodeLessThan: boolean;

  public dateTimeError: boolean;
  private tempemail: string;
  public campName: string;

  public selectedCampaignId: {camp_id: number};
  private selectedScId: {sc_id: number};
  private shortcodeInfo: string;
  private keywordToDelete: {ckeyw_id: number, camp_id: number, sc_id: number};
  private reportToDelete: {camp_rep_id: number};
  private reportToAdd: {camp_id: number, camp_rep_day: string, camp_rep_hour: string, reportSchedule: number};
  private numberToDelete: {blacklist_cellno: string, camp_id: number, blacklist_id: number};
  private numberToAdd: {camp_id: number, blacklist_cellno: string};
  private reportSchedule: number;
  private sms2email: boolean;
  private payload: any;

  private tempArr: Array<any>;
  private keywordList: Array<any>;
  private reportDays: Array<any>;
  private blacklistList: Array<any>;

   // DATE & TIME FORMATS
   pipe = new DatePipe('en-ZA'); // Use your own locale
   now = Date.now();
   dateCreated = this.pipe.transform(this.now, 'yyyy-MM-dd HH:mm:ss');
   scheduledDate = this.pipe.transform(this.now, 'yyyy-MM-dd HH:mm:ss');
   public day = this.pipe.transform(this.now, 'yyyy-MM-dd');
   // DATE & TIME FORMATS

  campaignForm: FormGroup;
  step1Form: FormGroup;
  public checkCampaignName: any;
  public campNameTaken: any;
  step2Form: FormGroup;
  step3Form: FormGroup;
  step4Form: FormGroup;
  step5Form: FormGroup;
  step6Form: FormGroup;

  step1InstantForm: FormGroup;
  step2InstantForm: FormGroup;

  optionsCampaigns: Array<any>;
  optionsShortcodes: Array<any>;
  optionsDays = [
    {value: 'Monday', label: 'Monday'},
    {value: 'Tuesday', label: 'Tuesday'},
    {value: 'Wednesday', label: 'Wednesday'},
    {value: 'Thursday', label: 'Thursday'},
    {value: 'Friday', label: 'Friday'},
    {value: 'Saturday', label: 'Saturday'},
    {value: 'Sunday', label: 'Sunday'},
  ];

    optionsTicketPosVal: Array<any> = [
      {value: 1, label: 1},
      {value: 2, label: 2},
      {value: 3, label: 3},
    ];

    optionsWinType: Array<any> = [
      {value: 1, label: 'Daily'},
      {value: 2, label: 'Monthly'},
      {value: 3, label: 'Yearly'},
      {value: 4, label: 'Entire Campaign'},
    ];

  headReports = ['Day', 'Time', 'Delete'];
  headBlacklist = ['#', 'Number', 'Delete'];

  constructor(
    private campaignService: CampaignService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: MDBModalService,
    private cdRef: ChangeDetectorRef,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }


  ngOnInit() {
    this.endDteIsChanged = false;
    this.initialEndDate = '';
    this.dateTimeError = false;
    this.isShortcodeLessThan = false;
    this.loading = false;
    this.loadingfail = false;
    this.isShortcodeDedicated = false;
    this.campName = '';
    this.tutorialActive = false;
    this.maxVisibleItems = 8;
    this.previous = [];
    this.blacklistCount = 0;
    this.flag_winnerCrit = 0;
    this.modalOptions = {data: {content: {camp_id: 0}}};
    this.selectedCampaignId = {camp_id: 0};
    this.selectedScId = {sc_id: 0};
    this.keywordToDelete = {ckeyw_id: 0, camp_id: 0, sc_id: 0};
    this.reportToDelete = {camp_rep_id: 0};
    this.reportToAdd = {camp_id: 0, camp_rep_day: '', camp_rep_hour: '', reportSchedule: 0};
    this.numberToDelete = {blacklist_cellno: '', camp_id: 0, blacklist_id: 0};
    this.numberToAdd = {camp_id: 0, blacklist_cellno: ''};
    this.resp = {};
    this.tempResp = {};
    this.respBl = {};
    this.campResp = {};
    this.instantResp = {};
    this.keywordResp = {};
    this.emailResp = {};
    this.blacklistResp = {};
    this.reportResp = {};
    this.tempemail = '';
    this.selected = false;
    this.submitted = false;
    this.keywordAdded = false;
    this.campIsInstant = false;
    this.reportSchedule = 0;
    this.shortcodeInfo = '';
    this.sms2email = false;
    this.blacklistManual = false;
    this.payload = {};
    this.tempArr = [];
    this.keywordList = [];
    this.reportDays = [];
    this.blacklistList = [];
    this.myDatePickerOptions.disableUntil.year = (new Date()).getFullYear();
    this.myDatePickerOptions.disableUntil.month = (new Date()).getMonth() + 1;
    this.myDatePickerOptions.disableUntil.day = (new Date()).getDate() - 1;
    this.loadAllCampaigns();

    this.campaign = {
      camp_id: this.route.snapshot.params['camp_id'],
    };

    this.campaignForm = this.fb.group({
      camp_id: ['', Validators.required],
    });

    this.step1Form = this.fb.group({
      camp_name: ['', [Validators.required, Validators.maxLength(70), Validators.pattern(/^([0-9]|[a-z]|[A-Z]|[ ])+$/)]],
      camp_desc: ['', [Validators.required, Validators.maxLength(200)]],
      camp_startDte: [''],
      camp_endDte: ['', [Validators.required, Validators.pattern(/^([0-9]{4})\-([0-9]{2})\-([0-9]{2})$/)]],
      camp_startTime: [''],
      camp_endTime: ['', [Validators.required, Validators.pattern(/^([0-9]{2})\:([0-9]{2})\:{0,1}([0-9]{0,2})$/)]],
    });

    this.step1InstantForm = this.fb.group({
      flag_normAdv: [0],
      ticket_code_msgPos: [''],
    });

    this.step2Form = this.fb.group({
      sc_id: [''],
      camp_replyMsg: ['', Validators.maxLength(160)],
      camp_replyMsgEnd: ['Sorry, this competition is closed.', [Validators.required, Validators.maxLength(160)]],
    });

    this.step2InstantForm = this.fb.group({
      notWin_replyMsg: [''],
      prize_won_replyMsg: [''],
      invalid_ticket_replyMsg: [''],
      used_ticket_replyMsg: [''],
      invalid_msg_replyMsg: [''],
    });

    this.step3Form = this.fb.group({
      ckeyw_word: ['', [Validators.required, Validators.maxLength(30), Validators.pattern(/^\S*$/)]],
      ckeyw_desc: ['', [Validators.required, Validators.maxLength(100)]],
    });

    this.step4Form = this.fb.group({
      camp_ReportMail: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/)]],
      camp_rep_day: ['', [Validators.required, Validators.maxLength(45)]],
      camp_rep_hour: ['09:00', [Validators.required, Validators.maxLength(45)]],
    });

    this.step5Form = this.fb.group({
      email_to: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/)]],
      email_cc: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/)]],
    });

    this.step6Form = this.fb.group({
      blacklist_cellno: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20), Validators.pattern(/^(\+[0-9]|[0-9])*$/)]],
    });

    this.selectedCampaign();
    if (this.campaign.camp_id !== undefined) {
      this.campaignForm.setValue({
        camp_id: this.campaign.camp_id,
      });
    }
  }

  get s1() {
    return this.step1Form.controls;
  }
  get s1I() {
    return this.step1InstantForm.controls;
  }
  get s2() {
    return this.step2Form.controls;
  }
  get s2I() {
    return this.step2InstantForm.controls;
  }
  get s3() {
    return this.step3Form.controls;
  }
  get s4() {
    return this.step4Form.controls;
  }
  get s5() {
    return this.step5Form.controls;
  }
  get s6() {
    return this.step6Form.controls;
  }

  // STEP 1 GETTERS
  get camp_name() {
    return this.step1Form.get('camp_name');
  }
  get camp_desc() {
    return this.step1Form.get('camp_desc');
  }
  get camp_startDte() {
    return this.step1Form.get('camp_startDte');
  }
  get camp_endDte() {
    return this.step1Form.get('camp_endDte');
  }
  get camp_startTime() {
    return this.step1Form.get('camp_startTime');
  }
  get camp_endTime() {
    return this.step1Form.get('camp_endTime');
  }

  // INSTANT WINNER GETTERS
  get ticket_code_msgPos() {
    return this.step1InstantForm.get('ticket_code_msgPos');
  }
  get flag_normAdv() {
    return this.step1InstantForm.get('flag_normAdv');
  }

  get notWin_replyMsg() {
    return this.step2InstantForm.get('notWin_replyMsg');
  }
  get prize_won_replyMsg() {
    return this.step2InstantForm.get('prize_won_replyMsg');
  }
  get invalid_ticket_replyMsg() {
    return this.step2InstantForm.get('invalid_ticket_replyMsg');
  }
  get used_ticket_replyMsg() {
    return this.step2InstantForm.get('used_ticket_replyMsg');
  }
  get invalid_msg_replyMsg() {
    return this.step2InstantForm.get('invalid_msg_replyMsg');
  }

  // STEP 2 GETTERS
  get camp_replyMsg() {
    return this.step2Form.get('camp_replyMsg');
  }
  get camp_replyMsgEnd() {
    return this.step2Form.get('camp_replyMsgEnd');
  }

  // STEP 3 GETTERS
  get ckeyw_word() {
    return this.step3Form.get('ckeyw_word');
  }
  get ckeyw_desc() {
    return this.step3Form.get('ckeyw_desc');
  }

  // STEP 4 GETTERS
  get camp_ReportMail() {
    return this.step4Form.get('camp_ReportMail');
  }
  get camp_rep_day() {
    return this.step4Form.get('camp_rep_day');
  }
  get camp_rep_hour() {
    return this.step4Form.get('camp_rep_hour');
  }

  // STEP 5 GETTERS
  get email_to() {
    return this.step5Form.get('email_to');
  }
  get email_cc() {
    return this.step5Form.get('email_cc');
  }

  // STEP 6 GETTERS
  get blacklist_cellno() {
    return this.step6Form.get('blacklist_cellno');
  }

  resetPage() {
    this.router.navigate(['/shortcode/campaign/manage']);
    this.ngOnInit();
  }


  onBlurCampaignName() {
    this.checkCampaignName = this.camp_name.value;
    this.campaignService.CheckCampaignNameForDuplicatesWithID({camp_name: this.checkCampaignName, camp_id: this.selectedCampaignId.camp_id})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data) => {
          this.resp = data;
          if (this.resp.error) {
            this.campNameTaken = true;
          } else {
            this.campNameTaken = false;
          }
        });
  }

  onKeydown(event) {
    this.campNameTaken = false;
  }

  loadAllCampaigns() {
    this.campaignService.getUserCampaigns()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              for (let index = 0; index < this.resp.count; index++) {
                const element = this.resp.rows[index];
                this.tempArr[index] = {
                  value: element.camp_id,
                  label: element.camp_name,
                };
              }
              this.optionsCampaigns = this.tempArr;
              this.tempArr = [];
              this.resp = {};
            },
            (err) => {
              this.loadingfail = true;
            },
        );
  }

  selectedCampaign() {
    this.campaignForm.get('camp_id').valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (value) => {
          this.selectedCampaignId = {camp_id: value};
          this.getSelectedCampaignDetails();
          this.selected = true;
        // this.getKeywords();
        // this.getBlacklisted();
        // this.reportDays = [];
        });
  }

  getSelectedCampaignDetails() {
    this.campaignService.getSelectedCampaignDetails(this.selectedCampaignId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              this.campResp = this.resp.campaign;
              this.campName = this.campResp.camp_name,
              this.instantResp = this.resp.instant;
              this.keywordResp = this.resp.keywords;
              this.reportResp = this.resp.reports;
              this.emailResp = this.resp.email;
              this.blacklistResp = this.resp.blacklist;
              if (this.resp.shortcode.sc_value < 1.50) {
                this.isShortcodeLessThan = true;
              }
              if (this.resp.shortcode.sc_type === 'd' || this.resp.shortcode.sc_type === 'ds') {
                this.isShortcodeDedicated = true;
              }
              this.shortcodeInfo = (this.resp.shortcode.sc_number + ' - Value = R' + this.resp.shortcode.sc_value.toFixed(2));
              this.resp = {};
              this.selectedScId = {sc_id: this.campResp.sc_id};


              this.setDetails();
              if (this.campResp.camp_isInstant === 1) {
                this.setInstant();
              } else {
                this.campIsInstant = false;
              }

              this.setKeywords();

              if (this.campResp.camp_hasReport === '1') {
                this.reportSchedule = 1;
                this.setReportDays();
              } else if (this.campResp.camp_hasReport === '2') {
                this.reportSchedule = 2;
                this.setReportDays();
              } else {
                this.reportSchedule = 0;
              }

              this.setSms2Email();
              this.setBlacklist();
              this.reportResp = {};
              this.campResp = {};
              this.emailResp = {};
              this.instantResp = {};
              this.keywordResp = {};
              this.blacklistResp = {};
            },
            (err) => {
              this.ngOnInit();
            },
        );
  }

  setDetails() {
    this.step1Form.setValue({
      camp_name: this.campResp.camp_name,
      camp_desc: this.campResp.camp_desc,
      camp_startDte: this.pipe.transform(this.campResp.camp_startDte, 'yyyy-MM-dd'),
      camp_endDte: this.pipe.transform(this.campResp.camp_endDte, 'yyyy-MM-dd'),
      camp_startTime: this.pipe.transform(this.campResp.camp_startDte, 'HH:mm:ss'),
      camp_endTime: this.pipe.transform(this.campResp.camp_endDte, 'HH:mm:ss'),
    });

    this.step2Form.setValue({
      sc_id: this.shortcodeInfo,
      camp_replyMsg: this.campResp.camp_replyMsg,
      camp_replyMsgEnd: this.campResp.camp_replyMsgEnd,
    });

    this.initialEndDate = this.camp_endDte.value;
  }

  setInstant() {
    this.campIsInstant = true;
    this.step1InstantForm.setValue({
      flag_normAdv: this.instantResp.hasTicketCode,
      ticket_code_msgPos: (this.instantResp.ticket_code_msgPos + 1),
    });
    this.step2InstantForm.setValue({
      notWin_replyMsg: this.instantResp.notWin_replyMsg,
      prize_won_replyMsg: this.instantResp.prize_won_replyMsg,
      invalid_ticket_replyMsg: this.instantResp.invalid_ticket_replyMsg,
      used_ticket_replyMsg: this.instantResp.used_ticket_replyMsg,
      invalid_msg_replyMsg: this.instantResp.invalid_msg_replyMsg,
    });
    if (this.instantResp.max_ceiling_type_id === 1 && this.instantResp.win_sel_type_id === 1) {
      this.flag_winnerCrit = 0;
    }
    if (this.instantResp.max_ceiling_type_id === 1 && this.instantResp.win_sel_type_id === 2) {
      this.flag_winnerCrit = 1;
    }
    if (this.instantResp.max_ceiling_type_id === 2 && this.instantResp.win_sel_type_id === 2) {
      this.flag_winnerCrit = 2;
    }
  }

  setKeywords() {
    this.keywordList = [];
    for (let index = 0; index < this.keywordResp.count; index++) {
      const element = this.keywordResp.rows[index];
      this.keywordList.push({
        ckeyw_word: element.ckeyw_word,
        ckeyw_desc: element.ckeyw_desc,
        ckeyw_id: element.ckeyw_id,
      });
    }
  }

  setReportDays() {
    this.reportDays = [];
    this.step4Form.setValue({
      camp_ReportMail: this.campResp.camp_ReportMail,
      camp_rep_day: '',
      camp_rep_hour: '',
    });
    for (let index = 0; index < this.reportResp.count; index++) {
      const element = this.reportResp.rows[index];
      this.reportDays.push({
        camp_rep_id: element.camp_rep_id,
        camp_rep_day: element.camp_rep_day,
        camp_rep_hour: element.camp_rep_hour,
      });
    }
  }

  setSms2Email() {
    if (this.campResp.camp_hasEmail === 1) {
      this.sms2email = true;
      if (this.emailResp.count = 0) {
        this.step5Form.setValue({
          email_to: '',
          email_cc: '',
        });
      } else {
        this.step5Form.setValue({
          email_to: this.emailResp.rows[0].email_to,
          email_cc: this.emailResp.rows[0].email_cc,
        });
      }
    } else {
      this.sms2email = false;
    }
  }

  // *******REMEMBER TO INCORPORATE PAGING INTO THE BLACKLISTS
  // CURRENTLY ONLY SHOWING 10 of total

  setBlacklist() {
    this.blacklistList = [];
    this.blacklistCount = this.blacklistResp.count;
    for (let index = 0; index < this.blacklistResp.rows.length; index++) {
      const element = this.blacklistResp.rows[index];
      this.blacklistList.push({
        blacklist_id: element.blacklist_id,
        blacklist_cellno: element.blacklist_cellno,
        camp_id: element.camp_id,
      });
    }

    this.mdbTable.setDataSource(this.blacklistList);
    this.blacklistList = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);


    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }


  onCheckCampaignType() {
    if (this.campIsInstant) {
      this.updateInstantCampaign();
    } else {
      this.updateKeywordCampaign();
    }
  }

  updateInstantCampaign() {
    this.submitted = true;

    if (this.reportSchedule !== 0 && this.camp_ReportMail.errors) {
      this.openModalErrorReports();
      return;
    }

    if (this.reportSchedule === 2 && this.reportDays.length === 0) {
      this.openModalErrorReportsAtleast1();
    }

    if (this.sms2email === true && this.email_to.errors) {
      this.openModalErrorSms2Email();
      return;
    }

    if (this.reportSchedule === 0) {
      this.tempemail = '';
      this.reportDays = [];
    } else if (this.reportSchedule === 1) {
      this.tempemail = this.camp_ReportMail.value;
      this.reportDays = [
        {camp_rep_day: 'Monday', camp_rep_hour: '07:00', Camp_id: this.selectedCampaignId.camp_id},
        {camp_rep_day: 'Tuesday', camp_rep_hour: '07:00', Camp_id: this.selectedCampaignId.camp_id},
        {camp_rep_day: 'Wednesday', camp_rep_hour: '07:00', Camp_id: this.selectedCampaignId.camp_id},
        {camp_rep_day: 'Thursday', camp_rep_hour: '07:00', Camp_id: this.selectedCampaignId.camp_id},
        {camp_rep_day: 'Friday', camp_rep_hour: '07:00', Camp_id: this.selectedCampaignId.camp_id},
        {camp_rep_day: 'Saturday', camp_rep_hour: '07:00', Camp_id: this.selectedCampaignId.camp_id},
        {camp_rep_day: 'Sunday', camp_rep_hour: '07:00', Camp_id: this.selectedCampaignId.camp_id},
      ];
    } else {
      this.tempemail = this.camp_ReportMail.value;
      for (let index = 0; index < this.reportDays.length; index++) {
        this.reportDays[index].Camp_id = this.selectedCampaignId.camp_id;
      }
    }
    if (this.initialEndDate === this.camp_endDte.value) {
      this.endDteIsChanged = false;
    } else {
      this.endDteIsChanged = true;
    }

    this.payload = {
      camp_isInstant: this.campIsInstant,
      camp_id: this.selectedCampaignId.camp_id,
      camp_ReportMail: this.tempemail,
      camp_hasEmail: this.sms2email,
      email_to: this.email_to.value,
      email_cc: this.email_cc.value,
      reportSchedule: this.reportSchedule,
      reportDays: this.reportDays,
      endDteIsChanged: this.endDteIsChanged,
    };
    this.campaignService.updateUserCampaignInstant(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.openModalSuccess();
              this.ngOnInit();
            },
            (err) => {

            },
        );
  }


  updateKeywordCampaign() {
    this.submitted = true;
    this.dateTimeError = false;

    if (this.campNameTaken) {
      this.openModalErrorStep1Taken();
      return;
    }

    if (this.camp_startDte.value === this.camp_endDte.value && this.camp_startTime.value >= this.camp_endTime.value) {
      this.dateTimeError = true;
      this.openModalErrorEndingDateBeforeStartDate();
      return;
    }

    if (this.camp_startDte.value > this.camp_endDte.value) {
      this.dateTimeError = true;
      this.openModalErrorEndingDateBeforeStartDate();
      return;
    }

    if (this.step1Form.invalid) {
      this.openModalErrorCampDetails();
      return;
    }

    if (this.step2Form.invalid) {
      this.openModalErrorScAndReplies();
      return;
    }

    if (this.reportSchedule !== 0 && this.camp_ReportMail.errors) {
      this.openModalErrorReports();
      return;
    }

    if (this.reportSchedule === 2 && this.reportDays.length === 0) {
      this.openModalErrorReportsAtleast1();
    }

    if (this.sms2email === true && this.email_to.errors) {
      this.openModalErrorSms2Email();
      return;
    }

    if (this.reportSchedule === 0) {
      this.tempemail = '';
      this.reportDays = [];
    } else if (this.reportSchedule === 1) {
      this.tempemail = this.camp_ReportMail.value;
      this.reportDays = [
        {camp_rep_day: 'Monday', camp_rep_hour: '07:00', Camp_id: this.selectedCampaignId.camp_id},
        {camp_rep_day: 'Tuesday', camp_rep_hour: '07:00', Camp_id: this.selectedCampaignId.camp_id},
        {camp_rep_day: 'Wednesday', camp_rep_hour: '07:00', Camp_id: this.selectedCampaignId.camp_id},
        {camp_rep_day: 'Thursday', camp_rep_hour: '07:00', Camp_id: this.selectedCampaignId.camp_id},
        {camp_rep_day: 'Friday', camp_rep_hour: '07:00', Camp_id: this.selectedCampaignId.camp_id},
        {camp_rep_day: 'Saturday', camp_rep_hour: '07:00', Camp_id: this.selectedCampaignId.camp_id},
        {camp_rep_day: 'Sunday', camp_rep_hour: '07:00', Camp_id: this.selectedCampaignId.camp_id},
      ];
    } else {
      this.tempemail = this.camp_ReportMail.value;
      for (let index = 0; index < this.reportDays.length; index++) {
        this.reportDays[index].Camp_id = this.selectedCampaignId.camp_id;
      }
    }

    if (this.initialEndDate === this.camp_endDte.value) {
      this.endDteIsChanged = false;
    } else {
      this.endDteIsChanged = true;
    }

    this.payload = {
      camp_id: this.selectedCampaignId.camp_id,
      camp_name: this.camp_name.value,
      camp_desc: this.camp_desc.value,
      camp_endDte: this.camp_endDte.value + ' ' + this.camp_endTime.value,
      camp_replyMsg: this.camp_replyMsg.value,
      camp_replyMsgEnd: this.camp_replyMsgEnd.value,
      camp_ReportMail: this.tempemail,
      camp_hasEmail: this.sms2email,
      email_to: this.email_to.value,
      email_cc: this.email_cc.value,
      reportSchedule: this.reportSchedule,
      reportDays: this.reportDays,
      endDteIsChanged: this.endDteIsChanged,
    };

    this.campaignService.updateUserCampaignKeyword(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.openModalSuccess();
              this.ngOnInit();
            },
            (err) => {

            },
        );
  }


  onAddKeyword() {
    this.keywordAdded = true;

    // stop if invalid
    if (this.step3Form.invalid) {
      this.openModalErrorNoKeywordOnAdd();
      return;
    }

    if (!this.selectedScId) {
      this.openModalErrorStep3NoShortcode();
      this.form3Clear();
      return;
    }

    if (this.keywordList.length >= 3 && !this.isShortcodeDedicated) {
      this.openModalErrorStep3MaxKeywords();
      this.form3Clear();
      return;
    }
    this.getKeywordsCheck();
  }

  getKeywordsCheck() {
    this.payload = {
      sc_id: this.selectedScId.sc_id,
      camp_id: this.selectedCampaignId.camp_id,
      ckeyw_word: this.ckeyw_word.value,
      ckeyw_desc: this.ckeyw_desc.value,
    };
    this.campaignService.tryToAddNewKeyword(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              if (this.resp.created) {
                // console.log(this.resp)
                this.addKeywordToList();
                return;
              } else {
                this.openModalErrorStep3KeywordTaken();
                this.form3Clear();
                return;
              }
            },
            (err) => {

            },
        );
  }

  addKeywordToList() {
    this.keywordList.push({
      sc_id: this.resp.keyword.sc_id,
      ckeyw_word: this.resp.keyword.ckeyw_word,
      ckeyw_desc: this.resp.keyword.ckeyw_desc,
      ckeyw_id: this.resp.keyword.ckeyw_id,
    });
    this.resp = {};
    this.payload = {};
    this.form3Clear();
  }

  onDeleteOneConfirm(id) {
    if (this.campIsInstant) {
      return;
    }
    this.modalOptionsConfirm.data = {
      heading: 'Really Remove This Keyword?',
      content: {
        heading: 'You are about to remove your keyword - This is a permanent action. ',
        amount: 0,
        description: ' Keywords are added & removed instantly and are not included under "Save All Changes" button.',
        btnText: 'Really Remove It',
      },
    };
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptionsConfirm);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.onDeleteOne(id);
          }
        });
  }

  onDeleteOne(id) {
    if (this.keywordList.length === 1 && !this.isShortcodeDedicated) {
      this.openModalErrorStep3NoKeyword(); // cant delete last keyword
      return;
    }

    this.keywordToDelete = {
      ckeyw_id: this.keywordList[id].ckeyw_id,
      camp_id: this.selectedCampaignId.camp_id,
      sc_id: this.selectedScId.sc_id,
    };
    this.campaignService.deleteKeywordFromCampaign(this.keywordToDelete)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.tempResp = data;
              if (this.tempResp.wasDel) {
                this.keywordList.splice(id, 1);
              } else {
                return window.alert('Whoopsy you already have a campaign without any keywords for this shortcode.');
              }
            },
            (err) => {

            },
        );
  }

  onAddReportDay() {
    if (this.camp_rep_day.valid && this.camp_rep_hour.valid) {
      this.reportDays.push({
        camp_rep_day: this.camp_rep_day.value,
        camp_rep_hour: this.camp_rep_hour.value,
      });
      this.camp_rep_day.reset();
      this.camp_rep_hour.reset();
    } else {
      return;
    }
  }

  onDeleteOneReportDayConfirm(id) {
    this.modalOptionsConfirm.data = {
      heading: 'Really Remove This Report Day?',
      content: {
        heading: 'You are about to remove this report day - This is a permanent action. ',
        amount: 0,
        description: ' Report days are added & removed instantly and are not included under "Save All Changes" button.',
        btnText: 'Really Remove It',
      },
    };
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptionsConfirm);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.onDeleteOneReportDay(id);
          }
        });
  }

  onDeleteOneReportDay(id) {
    this.reportDays.splice(id, 1);
  }


  onAddBlacklist() {
    this.modalOptions = {
      data: {
        content: {
          camp_id: this.selectedCampaignId.camp_id,
        },
      },
    };
    this.openModalBlacklistUpload();
  }

  openModalBlacklistUpload() {
    this.modalRef = this.modalService.show(UploadBlacklistModalComponent, this.modalOptions);

    this.modalRef.content.action.subscribe( (result: any) => {
      if (result === true) {
        //  this.ngOnInit();
        this.refreshBlacklistAfterFileUpload();
      }
    });
  }

  refreshBlacklistAfterFileUpload() {
    this.payload = {
      camp_id: this.selectedCampaignId.camp_id,
    };
    this.campaignService.getNextTenBlacklistNumbers(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              this.blacklistList = [];
              this.blacklistCount = this.resp.count;
              for (let index = 0; index < this.blacklistCount; index++) {
                const element = this.resp.rows[index];
                this.blacklistList.push({
                  blacklist_id: element.blacklist_id,
                  blacklist_cellno: element.blacklist_cellno,
                  camp_id: element.camp_id,
                });
              }
              this.mdbTable.setDataSource(this.blacklistList);
              this.blacklistList = this.mdbTable.getDataSource();
              this.previous = this.mdbTable.getDataSource();
              this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);


              this.mdbTablePagination.calculateFirstItemIndex();
              this.mdbTablePagination.calculateLastItemIndex();
              this.cdRef.detectChanges();
            },
            (err) => this.openModalErrorTimeout(),
        );
  }


  form3Clear() {
    this.step3Form.reset();
    this.keywordAdded = false;
  }


  onAddBlacklistNumber() {
    if (this.blacklist_cellno.invalid) {
      this.openModalErrorNoBlacklistNumberProvided();
      return;
    }
    this.numberToAdd = {
      camp_id: this.selectedCampaignId.camp_id,
      blacklist_cellno: this.blacklist_cellno.value,
    };

    this.campaignService.AddBlacklistNumber(this.numberToAdd)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.respBl = data;
              this.blacklistList.push({
                blacklist_id: this.respBl.blacklist_id,
                blacklist_cellno: this.respBl.blacklist_cellno,
                camp_id: this.respBl.camp_id,
              });
              this.mdbTable.setDataSource(this.blacklistList);
              this.blacklistList = this.mdbTable.getDataSource();
              this.previous = this.mdbTable.getDataSource();
              this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);


              this.mdbTablePagination.calculateFirstItemIndex();
              this.mdbTablePagination.calculateLastItemIndex();
              this.cdRef.detectChanges();
            },
            (err) => {

            },
        );
    this.step6Form.reset();
    // this.getBlacklisted();
  }

  onDeleteOneBlacklist(id) {
    this.numberToDelete = {
      blacklist_cellno: this.blacklistList[id].blacklist_cellno,
      camp_id: this.selectedCampaignId.camp_id,
      blacklist_id: this.blacklistList[id].blacklist_id,
    };

    this.campaignService.DeleteBlacklistNumber(this.numberToDelete)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.blacklistList.splice(id, 1);
            },
            (err) => {

            },
        );

    this.mdbTable.setDataSource(this.blacklistList);
    this.blacklistList = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);


    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }


  modalOptionsConfirm = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', amount: 0, description: '', btnText: ''},
    },
  }

  openModalConfirm() {
    this.modalOptionsConfirm.data = {
      heading: 'Really save changes?',
      content: {
        heading: 'Are you 100% sure that everything is correct?',
        amount: 0,
        description: ' Don\'t worry if you forgot something you can always make changes afterwards.',
        btnText: 'Save my changes',
      },
    };
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptionsConfirm);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.onCheckCampaignType();
          }
        });
  }

  openModalConfirmBack() {
    this.modalOptionsConfirm.data = {
      heading: 'Are you sure?',
      content: {
        heading: 'If you go back any unsaved changes will be discarded.',
        amount: 0,
        description: ' Please confirm.',
        btnText: 'Confirm',
      },
    };
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptionsConfirm);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.resetPage();
          }
        });
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', btnText: ''},
    },
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Congratulations!',
      content: {
        heading: 'All of your changes have been submitted, reviewed and applied.',
        btnText: 'changes made',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }


  modalOptionsErrorInput = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
    },
  }

  openModalErrorCampDetails() {
    this.modalOptionsErrorInput.data = {
      heading: 'Update failed successfully...',
      content: {
        heading: 'It seems like someone might have forgotten about a field or two on Tab #1 - Campaign Details.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you have provided all the required fields.',
        fix2: 'Ensure that all values are valid.',
        fix3: 'Look for any red text screaming at you.',
        btnText: 'To Tab #1!',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  openModalErrorScAndReplies() {
    this.modalOptionsErrorInput.data = {
      heading: 'Update failed successfully...',
      content: {
        heading: 'It seems like someone might have forgotten about a field or two on Tab #2 - Shortcode & Reply Messages.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you have provided all the required reply fields.',
        fix2: 'Ensure that all messages are valid and does not exceed 160 characters.',
        fix3: 'Look for any red text screaming at you.',
        btnText: 'To Tab #2!',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  openModalErrorWinnerOptionsInstant() {
    this.modalOptionsErrorInput.data = {
      heading: 'Update failed successfully...',
      content: {
        heading: 'It seems like someone might have forgotten about a field or two on Tab #7 - Winner Options.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you have provided all the required fields.',
        fix2: 'Ensure that all values are valid.',
        fix3: 'Look for any red text screaming at you.',
        btnText: 'To Tab #7!',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }


  openModalErrorReports() {
    this.modalOptionsErrorInput.data = {
      heading: 'Update failed successfully...',
      content: {
        heading: 'It seems like someone might have forgotten about a field or two on Tab #4 - Report Settings.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you have provided all the required fields.',
        fix2: 'Ensure that all values are valid.',
        fix3: 'Look for any red text screaming at you.',
        btnText: 'To Tab #4!',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  openModalErrorReportsAtleast1() {
    this.modalOptionsErrorInput.data = {
      heading: 'Update failed successfully...',
      content: {
        heading: 'Please ensure that you have at least one scheduled report day.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you have provided at least 1x report day.',
        fix2: 'Or select "Get No Reports" if you don\'t want reports.',
        fix3: '',
        btnText: 'okay',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  openModalErrorStep1Taken() {
    this.modalOptionsErrorInput.data = {
      heading: 'Wait just one second...',
      content: {
        heading: 'The campaign name that you have provided is already in use by one of your other campaigns.',
        suggest: 'Suggested Actions:',
        fix1: 'Please use a different campaign name.',
        fix2: 'Try to add slight variations to remove the conflict.',
        fix3: 'Always remember to Never say "Always" and "Never".',
        btnText: 'Let\'s see',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  openModalErrorSms2Email() {
    this.modalOptionsErrorInput.data = {
      heading: 'Update failed successfully...',
      content: {
        heading: 'It seems like someone might have forgotten about a field or two on Tab #5 - SMS2Email Settings.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you have provided all the required fields.',
        fix2: 'Ensure that all values are valid.',
        fix3: 'Look for any red text screaming at you.',
        btnText: 'To Tab #5!',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  openModalErrorNoKeywordOnAdd() {
    this.modalOptionsErrorInput.data = {
      heading: 'Something feels off...',
      content: {
        heading: 'It seems like you tried to add a keyword and it didn\'t go too well.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you have provided a keyword.',
        fix2: 'Ensure that you have provided a description.',
        fix3: 'Ensure that there are no spaces in your keyword.',
        btnText: 'Fix keyword',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  openModalErrorStep3NoShortcode() {
    this.modalOptionsErrorInput.data = {
      heading: 'Well this is embarrassing...',
      content: {
        heading: 'You don\'t have a shortcode... This is widely considered to be very problematic. It is probably my fault though.',
        suggest: 'Suggested Actions:',
        fix1: 'Select your campaign again.',
        fix2: 'Reload the page.',
        fix3: 'Call support and tell on me =(.',
        btnText: 'broken',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  openModalErrorStep3MaxKeywords() {
    this.modalOptionsErrorInput.data = {
      heading: 'Task failed successfully.',
      content: {
        heading: 'While I admire your drive, we can only allow each campaign to have a maximum of three (3x) keywords.',
        suggest: 'Suggested Actions:',
        fix1: 'Delete one of your 3x keywords to make room for one.',
        fix2: 'Only have 3x keywords.',
        fix3: 'If you really need more - contact us and we\'ll make an exception just for you.',
        btnText: 'Oh Darn',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  openModalErrorStep3KeywordTaken() {
    this.modalOptionsErrorInput.data = {
      heading: 'What are the odds?',
      content: {
        heading: 'There are around 218 632 words in the Oxford English Dictionary‬. Unfortunately, "' + this.ckeyw_word.value + '" is already being used by another campaign on the selected shortcode.',
        suggest: 'Suggested Actions:',
        fix1: 'Consider using a different keyword.',
        fix2: 'Consider using a different shortcode.',
        fix3: '',
        btnText: 'it happens',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  openModalErrorStep3NoKeyword() {
    this.modalOptionsErrorInput.data = {
      heading: 'My apologies...',
      content: {
        heading: 'You can not have less than one(1x) keyword. If you really need to remove this keyword, try to add another one first.',
        suggest: 'Suggested Actions:',
        fix1: 'Add a different keyword first, then delete this one.',
        fix2: 'Consider not removing this keyword.',
        fix3: '',
        btnText: 'okay...',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  openModalErrorNoBlacklistNumberProvided() {
    this.modalOptionsErrorInput.data = {
      heading: '404 number not found',
      content: {
        heading: 'My journey to blacklist that number you wanted was unfruitful.',
        suggest: 'Suggested Actions:',
        fix1: 'Please provide a number.',
        fix2: 'Ensure said number is valid.',
        fix3: 'Try again',
        btnText: 'Fix number',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  openModalErrorEndingDateBeforeStartDate() {
    this.modalOptionsErrorInput.data = {
      heading: 'Warning: Space-Time Violation!',
      content: {
        heading: 'I have just received confirmation that you want to end your campaign before it has even started. This is a direct violation of Section 356, Par.97 of the Laws of Time.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that the start date is equal to or before the ending date.',
        fix2: 'Ensure that the end date is equal to or after the starting date.',
        fix3: 'Don\'t take the little things in life for granted.',
        btnText: 'Crisis Averted',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  openModalErrorTimeout() {
    this.modalOptionsErrorInput.data = {
      heading: 'Update Failed Successfully...',
      content: {
        heading: 'I seem to have lost you there for a moment...',
        suggest: 'Suggested Actions:',
        fix1: 'Please check your network connectivity.',
        fix2: 'Try to refresh your browser.',
        fix3: 'Then try again.',
        btnText: 'it happens',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  //  ,{
  //   id: '',
  //   title: '',
  //   text: '.<br><br>\
  //   .<br><br>\
  //   <i>- </i><br><br>\
  //   <mark>- </mark>',
  //   attachTo: {element: '.t', on: 'top'},
  //   advanceOn: {selector: '.', event: 'click'},
  //   canClickTarget: false,
  //   modalOverlayOpeningPadding: 10,
  //   beforeShowPromise: function() {
  //     return new Promise(function(resolve) {
  //       setTimeout(function() {
  //         resolve();
  //       }, 200);
  //     });
  //   },
  //   buttons: [
  //     {action() {return this.next();},text: 'Next',}
  //   ],
  // }

  ngAfterViewInit() {
    const that = this;
    tour.on('complete', () => {
      that.blacklistManual = false, that.tutorialActive = false;
    });
    tour.on('cancel', () => {
      that.blacklistManual = false, that.tutorialActive = false;
    });
    tour.addSteps([{
      id: 'start',
      title: 'Nice to meet you!',
      text: 'I am SIM\'s Brother CHAT and I can assure you that we are definitely two different people.<br><br>\
      I will be awesome replacement guide for this tutorial. Together we will navigate through the different tabs for effectively managing your campaigns.<br><br>\
      <mark>- For this tutorial I will be disabling any interaction with the page - I don\'t want to mess up a perfectly good campaign.</mark>',
      buttons: [
        {action() {
          return this.next();
        }, text: 'Start Tutorial'},
      ],
    }, {
      id: 'name',
      title: 'Campaign Name',
      text: 'This is the name that you chose for your campaign when creating it.<br><br>\
      You are allowed to change the name of your campaigns at any time - just not now.<br><br>\
      <i>- Personally I think that you currently have a brilliant name.</i><br><br>',
      attachTo: {element: '.bname', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'description',
      title: 'Campaign Description',
      text: 'This is the description that you chose for your campaign when creating it.<br><br>\
      You are also allowed to change this description of your campaign at any time - after we are done here.<br><br>\
      <i>- Your description is also looking snazzy - so lets leave it be.</i><br><br>',
      attachTo: {element: '.bdesc', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'startdate',
      title: 'Campaign Start Date',
      text: 'This indicates the date on which your campaign will start or has started already.<br><br>\
      To ensure the integrity of the space-time continuum I can not allow you to change the past and consequently not this field.<br><br>\
      <i>- The past is set in stone...</i><br><br>',
      attachTo: {element: '.bstartdate', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {
          if (that.campIsInstant) {
            return this.show('enddate');
          } else {
            return this.show('starttime');
          }
        }, text: 'Next'},
      ],
    }, {
      id: 'starttime',
      title: 'Campaign Start Time',
      text: 'This indicates the time on which your campaign will start or has started already.<br><br>\
      To ensure the integrity of the space-time continuum I can still not allow you to change the past and consequently not this field.<br><br>\
      <i>- The past is still set in stone...</i><br><br>',
      attachTo: {element: '.kstarttime', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'enddate',
      title: 'Campaign End Date',
      text: 'This indicates the date on which your campaign will end and be closed.<br><br>\
      You are allowed to change this date at any time to extend your campaign if need be.<br><br>\
      <i>- ...but the future is in your hands!</i><br><br>',
      attachTo: {element: '.benddate', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {
          if (that.campIsInstant) {
            return this.show('campaigntype');
          } else {
            return this.show('endtime');
          }
        }, text: 'Next'},
      ],
    }, {
      id: 'endtime',
      title: 'Campaign End Time',
      text: 'This indicates the time on which your campaign will end and be closed.<br><br>\
      You are allowed to change this time at any time to extend your campaign if need be.<br><br>',
      attachTo: {element: '.kendtime', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {
          that.staticTabs.setActiveTab(2); return this.show('shortcode');
        }, text: 'Next'},
      ],
    }, {
      id: 'campaigntype',
      title: 'Instant Campaign Type',
      text: 'This indicates the type of your instant campaign.<br><br>\
      Normal Campaign: If your campaign is not using any unique code based entries.<br><br>\
      Ticket Based Campaign: If your campaign is running on unique code based entries ie: Barcode numbers, receipt numbers, etc...<br><br>',
      attachTo: {element: '.icampaigntype', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {
          if (that.flag_normAdv.value === 1) {
            return this.show('ticketcodeposition');
          } else {
            that.staticTabs.setActiveTab(2); return this.show('shortcode');
          }
        }, text: 'Next'},
      ],
    }, {
      id: 'ticketcodeposition',
      title: 'Ticket Code Position',
      text: 'This number indicates the position of where the Unique Code will be in the entry SMS.<br><br>\
      Eg: If an entry message would be "Keyword Name Surname UniqueCode" then you would need to select "3" because the Code is then the third word after the keyword.<br><br>\
      You may change this at any time.<br><br>',
      attachTo: {element: '.iticketcodeposition', on: 'top'},
      modalOverlayOpeningPadding: 15,
      canClickTarget: false,
      buttons: [
        {action() {
          that.staticTabs.setActiveTab(2); return this.show('shortcode');
        }, text: 'Next'},
      ],
    }, {
      id: 'shortcode',
      title: 'Shortcode & Value',
      text: 'This is the shortcode that you have assigned to your campaign.<br><br>\
      Unfortunately I can not allow you to change this. If you really need to change this feel free to contact us and we could change this for you.<br><br>\
      <i>- Some choices are permanent.</i><br><br>',
      attachTo: {element: '.bshortcode', on: 'bottom'},
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 200);
        });
      },
      buttons: [
        {action() {
          if (that.campIsInstant) {
            return this.show('closedreply');
          } else {
            return this.show('runningreply');
          }
        }, text: 'Next'},
      ],
    }, {
      id: 'runningreply',
      title: 'Running Reply Message',
      text: 'This is the reply message that gets sent to a successful entry if the campaign is currently live.<br><br>\
      The cost of this message is included in the shortcode value which the entree is charged.<br><br>',
      attachTo: {element: '.krunningreply', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.show('closedreply');
        }, text: 'Next'},
      ],
    }, {
      id: 'closedreply',
      title: 'Closed Reply Message',
      text: 'This is the reply message that gets sent to entries if the campaign is has already passed it\'s end date and is closed.<br><br>\
      The cost of this message is included in the shortcode value which the entree is charged.<br><br>',
      attachTo: {element: '.bclosedreply', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {
          if (that.campIsInstant) {
            return this.show('notwinnerreply');
          } else {
            that.staticTabs.setActiveTab(3); return this.show('keyword');
          }
        }, text: 'Next'},
      ],
    }, {
      id: 'notwinnerreply',
      title: 'Not Winner Reply Message',
      text: 'This is the reply message that gets sent to a successful entry that did not win.<br><br>\
      The cost of this message is included in the shortcode value which the entree is charged.<br><br>',
      attachTo: {element: '.inotwinnerreply', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.show('winnerreply');
        }, text: 'Next'},
      ],
    }, {
      id: 'winnerreply',
      title: 'Winner Reply Message',
      text: 'This is the reply message that gets sent to any entry that has is confirmed as a winner.<br><br>\
      The cost of this message is included in the shortcode value which the entree is charged.<br><br>',
      attachTo: {element: '.iwinnerreply', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {
          if (that.flag_normAdv.value === 1) {
            return this.show('invalidticket');
          } else {
            that.staticTabs.setActiveTab(3); return this.show('keyword');
          }
        }, text: 'Next'},
      ],
    }, {
      id: 'invalidticket',
      title: 'Invalid Ticket Reply Message',
      text: 'This is the reply message that gets sent to entries if the Unique Code provided is not valid.<br><br>\
      The cost of this message is included in the shortcode value which the entree is charged.<br><br>',
      attachTo: {element: '.iinvalidticket', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.show('usedticket');
        }, text: 'Next'},
      ],
    }, {
      id: 'usedticket',
      title: 'Used Ticket Reply Message',
      text: 'This is the reply message that gets sent to entries if the Unique Code provided has already been redeemed.<br><br>\
      The cost of this message is included in the shortcode value which the entree is charged.<br><br>',
      attachTo: {element: '.iusedticket', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.show('invalidmessage');
        }, text: 'Next'},
      ],
    }, {
      id: 'invalidmessage',
      title: 'Invalid Message Reply Message',
      text: 'This is the reply message that gets sent to entries if the Message was not in the correct format.<br><br>\
      The cost of this message is included in the shortcode value which the entree is charged.<br><br>',
      attachTo: {element: '.iinvalidmessage', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {
          that.staticTabs.setActiveTab(3); return this.show('keyword');
        }, text: 'Next'},
      ],
    }, {
      id: 'keyword',
      title: 'Keyword Field',
      text: 'This field is used if you want to add any additional keywords.<br><br>\
      If your shortcode is not dedicated you require at least one keyword and may have no more than 3x keywords.<br><br>',
      attachTo: {element: '.bkeyword', on: 'bottom'},
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 200);
        });
      },
      buttons: [
        {action() {
          return this.show('keyworddesc');
        }, text: 'Next'},
      ],
    }, {
      id: 'keyworddesc',
      title: 'Keyword Description',
      text: 'This field is used to describe the keyword that you want to add.<br><br>\
      A description is required before a new keyword can be added.<br><br>',
      attachTo: {element: '.bkeyworddesc', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.show('addkeyword');
        }, text: 'Next'},
      ],
    }, {
      id: 'addkeyword',
      title: 'Add Keyword Button',
      text: 'Once you have provided a keyword & description you click on this button to verify if the chosen keyword is available.<br><br>\
      If the keyword is available & you have not reach your maximum keyword limit it will be added to your keyword list.<br><br>',
      attachTo: {element: '.baddkeyword', on: 'bottom'},
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [
        {action() {
          return this.show('keywordlist');
        }, text: 'Next'},
      ],
    }, {
      id: 'keywordlist',
      title: 'Keyword List',
      text: 'All of your current keywords are displayed here.<br><br>\
      You can click on "Remove" to remove a keyword from this campaign. *It should be noted that adding and removing of keywords gets processed instantly and \
      is excluded from the "Save All" button.<br><br>',
      attachTo: {element: '.bkeywordlist', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {
          that.staticTabs.setActiveTab(4); return this.show('reportsettings');
        }, text: 'Next'},
      ],
    }, {
      id: 'reportsettings',
      title: 'Report Settings',
      text: 'Here you can choose from 3x different report settings.<br><br>\
      "Get No Reports" will opt you out of receiving any sort of reports via email.<br><br>\
      "Get Daily Reports" will automatically schedule reports to be sent to the email provided every day @ 7am.<br><br>\
      "Customize Reports" will allow you to select specific days & times on which to receive reports.<br><br>',
      attachTo: {element: '.breportsettings', on: 'bottom'},
      modalOverlayOpeningPadding: 10,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 200);
        });
      },
      buttons: [
        {action() {
          if (that.reportSchedule === 0) {
            that.staticTabs.setActiveTab(5); return this.show('sms2email');
          } else {
            return this.show('reportemail');
          }
        }, text: 'Next'},
      ],
    }, {
      id: 'reportemail',
      title: 'Email Address For Reports',
      text: 'If you opt in for reports you need to provide at least one email address here.<br><br>\
      You are allowed to provide multiple email addresses, ensure that they are separated by semicolons ";".<br><br>',
      attachTo: {element: '.breportemail', on: 'bottom'},
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [
        {action() {
          if (that.reportSchedule === 1) {
            that.staticTabs.setActiveTab(5); return this.show('sms2email');
          } else {
            return this.show('reportday');
          }
        }, text: 'Next'},
      ],
    }, {
      id: 'reportday',
      title: 'Adding Custom Days',
      text: 'If you want to add custom days you can select one from here.<br><br>\
      Then you select a time Then you can click on "Add To Report List".<br><br>\
      This can be repeated to suit your specific needs.<br><br>',
      attachTo: {element: '.breportday', on: 'bottom'},
      canClickTarget: false,
      modalOverlayOpeningPadding: 20,
      buttons: [
        {action() {
          return this.show('reporttime');
        }, text: 'Next'},
      ],
    }, {
      id: 'reporttime',
      title: 'Selecting Custom Time',
      text: 'Here you can select a custom time for the day you chose above.<br><br>\
      After you selected a time, you can click on "Add To Report List".<br><br>\
      This can be repeated to suit your specific needs.<br><br>',
      attachTo: {element: '.breporttime', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.show('addreport');
        }, text: 'Next'},
      ],
    }, {
      id: 'addreport',
      title: 'Add To Report List',
      text: 'When you want to add your select report day(s) you click here.<br><br>\
      If you have opted in for daily reports 7x days will be added below @ 07:00 am.<br><br>\
      If you selected a custom day & time it will be added.<br><br>',
      attachTo: {element: '.baddreport', on: 'top'},
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [
        {action() {
          return this.show('reportdays');
        }, text: 'Next'},
      ],
    }, {
      id: 'reportdays',
      title: 'Report Days',
      text: 'All currently registered report days will be listed here.<br><br>\
      *You can remove individual days from here.<br><br>',
      attachTo: {element: '.breportdays', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {
          that.staticTabs.setActiveTab(5); return this.show('sms2email');
        }, text: 'Next'},
      ],
    }, {
      id: 'sms2email',
      title: 'SMS2Email',
      text: 'On this page you have the option of receiving email notifications every time your campaign receives an entry.<br><br>\
      This is ideal for campaigns where human response is required. i.e. A danger alert campaign.<br><br>\
      <i>- This feature is not recommended if you expect to have large amounts of daily entries.</i><br><br>',
      attachTo: {element: '.bsms2email', on: 'bottom'},
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 200);
        });
      },
      buttons: [
        {action() {
          if (that.sms2email) {
            return this.show('emailto');
          } else {
            that.staticTabs.setActiveTab(6); return this.show('blacklistnumber');
          }
        }, text: 'Next'},
      ],
    }, {
      id: 'emailto',
      title: 'Email Address',
      text: 'After opting in for SMS2Email you need to provide an email address.<br><br>\
      You may also add multiple email addresses.<br><br>\
      <i>- If you add multiple addresses you need to separate them with semicolons (;).</i><br><br>',
      attachTo: {element: '.bemailto', on: 'bottom'},
      modalOverlayOpeningPadding: 10,
      canClickTarget: false,
      buttons: [
        {action() {
          return this.show('emailcc');
        }, text: 'Next'},
      ],
    }, {
      id: 'emailcc',
      title: 'Email CC Address',
      text: 'You have the option to provide email addresses to be CCed in these entry alert emails.<br><br>\
      This field is optional.<br><br>\
      <i>- Like with the previous field - If you provide multiple email addresses to CC they need to be separated by semicolons (;).</i><br><br>',
      attachTo: {element: '.bemailcc', on: 'top'},
      modalOverlayOpeningPadding: 10,
      canClickTarget: false,
      buttons: [
        {action() {
          that.staticTabs.setActiveTab(6); return this.show('blacklistnumber');
        }, text: 'Next'},
      ],
    }, {
      id: 'blacklistnumber',
      title: 'Number To Blacklist',
      text: 'Here you may manually enter numbers to be blacklisted & prevented from entering into your campaign.<br><br>',
      attachTo: {element: '.bblacklistnumber', on: 'bottom'},
      modalOverlayOpeningPadding: 10,
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 200);
        });
      },
      buttons: [
        {action() {
          return this.show('addblacklist');
        }, text: 'Next'},
      ],
    }, {
      id: 'addblacklist',
      title: 'Add Number To List',
      text: 'After entering a number you click here to add it to your blacklisted numbers.<br><br>',
      attachTo: {element: '.baddblacklist', on: 'bottom'},
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [
        {action() {
          return that.blacklistManual = true, this.show('uploadblacklist');
        }, text: 'Next'},
      ],
    }, {
      id: 'uploadblacklist',
      title: 'Upload Blacklist File',
      text: 'If you have a file of blacklisted numbers you would like to upload you can click here.<br><br>',
      attachTo: {element: '.buploadblacklist', on: 'bottom'},
      modalOverlayOpeningPadding: 10,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 200);
        });
      },
      canClickTarget: false,
      buttons: [
        {action() {
          return this.show('blacklistlist');
        }, text: 'Next'},
      ],
    }, {
      id: 'blacklistlist',
      title: 'Blacklisted Numbers',
      text: 'Your Blacklisted numbers will be listed here. You can remove a number at any time simply by clicking on remove<br><br>',
      attachTo: {element: '.bblacklistlist', on: 'bottom'},
      modalOverlayOpeningPadding: 10,
      canClickTarget: false,
      buttons: [
        {action() {
          if (that.campIsInstant) {
            that.staticTabs.setActiveTab(7); return this.show('winneroptions');
          } else {
            return this.show('saveall');
          }
        }, text: 'Next'},
      ],
    }, {
      id: 'winneroptions',
      title: 'Winner Options',
      text: 'All: Winners will be randomly selected throughout the entire lifetime of your campaign.<br><br>\
      Interval: Winners will be selected based on the interval which you choose. Eg. Every 19th entry will be a winner.<br><br>\
      Time Based: Winners will be selected based on time intervals. Note that a schedule needs to be provided to our IT team.<br><br>\
      <i>- We will be setting up this campaign based on Intervals.</i><br><br>',
      attachTo: {element: '.iwinneroptions', on: 'right'},
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 200);
        });
      },
      buttons: [
        {action() {
          return this.show('maxwins');
        }, text: 'Next'},
      ],
    }, {
      id: 'maxwins',
      title: 'Max Wins Per Cell',
      text: 'This field will indicate the amount of times a cellphone number can be selected as a winner.<br><br>\
      <i>- Eg: Setting this to "1" means each number can only win once.</i><br><br>',
      attachTo: {element: '.imaxwins', on: 'bottom'},
      modalOverlayOpeningPadding: 10,
      canClickTarget: false,
      buttons: [
        {action() {
          return this.show('maxwintype');
        }, text: 'Next'},
      ],
    }, {
      id: 'maxwintype',
      title: 'Max Wins Per Cell',
      text: 'This field will indicate the amount of times a cellphone number can be selected as a winner.<br><br>\
      <i>- Eg: Setting this to "1" means each number can only win once.</i><br><br>',
      attachTo: {element: '.imaxwintype', on: 'bottom'},
      modalOverlayOpeningPadding: 10,
      canClickTarget: false,
      buttons: [
        {action() {
          return this.show('totalprizes');
        }, text: 'Next'},
      ],
    }, {
      id: 'totalprizes',
      title: 'Total Amount of Prizes',
      text: 'This field will indicate the total amount of prizes that you have to give away.<br><br>\
      <i>- This traslates to the maximum amount of possible winners.</i><br><br>',
      attachTo: {element: '.itotalprizes', on: 'top'},
      modalOverlayOpeningPadding: 10,
      canClickTarget: false,
      buttons: [
        {action() {
          if (that.flag_winnerCrit === 1) {
            return this.show('prizeinterval');
          } else {
            return this.show('airtime');
          }
        }, text: 'Next'},
      ],
    }, {
      id: 'prizeinterval',
      title: 'Prize Intervals',
      text: 'Because you have selected "Interval" you have to provide a interval on which winners will be selected.<br><br>\
      <i>- Eg: If you go with "21" then every 21st entry will be selected as a winner.</i><br><br>',
      attachTo: {element: '.iprizeinterval', on: 'top'},
      modalOverlayOpeningPadding: 10,
      canClickTarget: false,
      buttons: [
        {action() {
          return this.show('airtime');
        }, text: 'Next'},
      ],
    }, {
      id: 'airtime',
      title: 'Prize Type',
      text: 'If your prizes are airtime vouchers you should select Airtime.<br><br>',
      attachTo: {element: '.iairtime', on: 'top'},
      modalOverlayOpeningPadding: 10,
      canClickTarget: false,
      buttons: [
        {action() {
          return this.show('saveall');
        }, text: 'Next'},
      ],
    }, {
      id: 'saveall',
      title: 'Save All Changes',
      text: 'Remember to click here once you have made all changes! Unsaved changes will be discarded.\
      This concludes our tutorial.<br><br>',
      attachTo: {element: '.bsaveall', on: 'top'},
      modalOverlayOpeningPadding: 10,
      canClickTarget: false,
      buttons: [
        {action() {
          return this.complete();
        }, text: 'End Tutorial'},
      ],
    },
    ]);
  }

  tutorial() {
    this.staticTabs.setActiveTab(1);
    this.tutorialActive = true;
    tour.start();
  }


  // changeActiveTab() {
  //   this.staticTabs.setActiveTab(3);
  // }
}
