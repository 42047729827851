import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';

import {LoaderService} from '../../services/loader.service';
import {ForgotPasswordService} from '../../services/forgot-password.service';
import {LoginService} from '../../login.service';
import {SuccessModalComponent} from '../../modals/other/success-modal/success-modal.component';
import {ErrorComponent} from '../../modals/other/error/error.component';
import {Router} from '@angular/router';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  forgotPasswordForm: FormGroup;
  otpForm: FormGroup;
  submitted = false;
  public loading: boolean;
  public loadingtext: string;
  public error: boolean;
  public errorMsg: String;
  public otpEnter: boolean;
  private payload;
  private resp;
  public codeTrim: string;


  constructor(
    public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    private fb: FormBuilder,
    private dataservice: LoginService,
    private forgotPasswordService: ForgotPasswordService,
    private router: Router,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }


  ngOnInit() {
    this.codeTrim = '';
    this.payload = {};
    this.loading = false;
    this.loadingtext = '';
    this.error = false;
    this.otpEnter = false;
    this.resp = {};
    this.forgotPasswordForm = this.fb.group({
      user_name: ['', Validators.required],
      user_pEmail: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
    });

    this.otpForm = this.fb.group({
      user_otp: ['', Validators.required],
    });
  }

  get p() {
    return this.forgotPasswordForm.controls;
  }
  get o() {
    return this.otpForm.controls;
  }
  get user_name() {
    return this.forgotPasswordForm.get('user_name');
  }
  get user_pEmail() {
    return this.forgotPasswordForm.get('user_pEmail');
  }
  get user_otp() {
    return this.otpForm.get('user_otp');
  }

  onSubmit() {
    this.submitted = true;

    // stop if invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.ForgotPassword();
  }

  ForgotPassword() {
    this.loadingtext = 'Checking Credentials...';
    this.payload = {user_name: this.user_name.value, user_pEmail: this.user_pEmail.value};
    this.forgotPasswordService.submitUsername(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              if (!this.resp.success) {
                this.error = true;
                this.errorMsg = this.resp.error;
                return;
              }
              this.modalRef.hide();
            },
            (err) => {
              this.openModalErrorTimeout();
            },
        );
  }


  onSubmitOtp() {
    this.submitted = true;

    // stop if invalid
    if (this.otpForm.invalid) {
      return;
    }
    // this.loading = true;
    this.userLogin();
  }

  userLogin() {
    this.codeTrim = this.user_otp.value.replace(/\s/, '');
    this.loadingtext = 'Verifying OTP...';
    this.dataservice.loginOTP({user_otp: this.codeTrim})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              // this.router.navigate(['/settings/change-password']);
              window.location.replace('/settings/change-password');
            },
            (error) => {
              this.error = error;
              this.openModalErrorWrongCode();
            },
        );
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', btnText: ''},
    },
  }

  modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
    },
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Request Successful!',
      content: {
        heading: 'Your password reset link has been sent to your email address. Check your inbox.',
        btnText: 'Super',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }

  openModalErrorTimeout() {
    this.modalOptionsError.data = {
      heading: 'Request Failed Successfully...',
      content: {
        heading: 'I seem to have lost you there for a moment...',
        suggest: 'Suggested Actions:',
        fix1: '*Please note that you can only request an OTP 5x times per device every 24 hours.',
        fix2: '**Please note that you can only request an OTP every 24 hours.',
        fix3: '***If you are having any issues to get into your account - Feel free to contact us.',
        btnText: 'it happens',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }

  openModalErrorWrongCode() {
    this.modalOptionsError.data = {
      heading: 'Request Failed Successfully...',
      content: {
        heading: 'The OTP Code that you provided is invalid',
        suggest: 'Suggested Actions:',
        fix1: 'Verify that you have entered the OTP code correctly.',
        fix2: '*Note that you only have a limited amount of tries per day.',
        fix3: 'Then Retry',
        btnText: 'okay',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }


  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  //   <app-loader></app-loader>

  // import { LoaderService } from '../../services/loader.service';

  // ,
  //     private loaderService: LoaderService
  //     ) {this.loaderService.isLoading.subscribe((v) => {this.loading = v;});}

  // loading
  // loadingtext
  // loadingfail

  // #Remove:
  //  loading,
  // Timeout
  // Modal

  // loadingtext on each req

  // If Initial load ->
  // loadingfail
  // retry button
  // hide form on fail

  // Hide/Disable on loading

  // Tutorial button
  // Test tutorial
}
