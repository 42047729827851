import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {FileUploader} from 'ng2-file-upload/ng2-file-upload';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';

import {ConfirmComponent} from '../../../modals/other/confirm/confirm.component';
import {SuccessModalComponent} from '../../../modals/other/success-modal/success-modal.component';
import {ErrorComponent} from '../../../modals/other/error/error.component';

import {AdminService} from '../../../services/admin.service';
import {TicketUploadService} from '../../../services/file/ticket-upload.service';
import {environment} from '../../../../environments/environment';
import {LoaderService} from '../../../services/loader.service';
const URL = environment.fileUpURL + 'tickets';

@Component({
  selector: 'app-add-ticket-codes',
  templateUrl: './add-ticket-codes.component.html',
  styleUrls: ['./add-ticket-codes.component.scss'],
})
export class AddTicketCodesComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  @Input() progressLoaded: number = 0;
  public uploader: FileUploader = new FileUploader({url: URL, queueLimit: 1, itemAlias: 'file'});

  private resp: any;
  public Campaigns: Array<any>;
  private tempArr: any;
  importVoucherForm: FormGroup;
  public submitted: boolean;
  public loading: boolean;
  public loadingfail: boolean;
  public uploadButtonDisabled: boolean;
  public submittedToQueue: boolean;
  private selectedUserId: number;
  private selectedCampId: number;
  public userChosen: boolean;
  public campaignChosen: boolean;
  public vouchersInQueue: number;
  public badrows: number;
  voucherQueue: Array<any>;

 // File Uploader Variables
 public initial: boolean;
 public error: string;
 public showButton: boolean;
 public fileNameShow: string;
 public uploadStatus: boolean;
 public uploadError: boolean;
 public endStatus: boolean;
 public filename: string;
 public filenameToRequestRead: string;
 public filenameToConfirmQueue: any;
 public fileType: string;
 public payload: any;
 public confirmed: boolean;

 headTicketQueue = ['Ticket Code', 'Campaign ID'];

  optionsUsers: Array<any>;
  optionsCampaigns: Array<any>;

  constructor(
    public voucherService: AdminService,
    public uploadService: TicketUploadService,
    private fb: FormBuilder,
    private modalService: MDBModalService,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }


  ngOnInit() {
    this.uploader = new FileUploader({url: URL, queueLimit: 1, itemAlias: 'file'});
    this.submitted = false;
    this.submittedToQueue = false;
    this.initial = false;
    this.showButton = false;
    this.uploadStatus = false;
    this.uploadError = false;
    this.endStatus = false;
    this.confirmed = false;
    this.loadingfail = false;
    this.uploadButtonDisabled = false;

    this.resp = {};
    this.Campaigns = [];
    this.tempArr = [];
    this.selectedUserId = 0;
    this.selectedCampId = 0;
    this.userChosen = false;
    this.campaignChosen = false;
    this.vouchersInQueue = 0;
    this.badrows = 0;
    this.voucherQueue = [];
    this.progressLoaded = 0;

    this.error = '';
    this.fileNameShow = '';
    this.filename = '';
    this.filenameToRequestRead = '';
    this.filenameToConfirmQueue = {};
    this.fileType = '';
    this.payload = {};
    this.optionsUsers = [];
    this.optionsCampaigns = [];
    this.loadUsers();

    this.importVoucherForm = this.fb.group({
      user_id: ['', Validators.required],
      camp_id: ['', Validators.required],
    });

    this.selectedUser();
    this.selectedCampaign();

    this.uploader.onAfterAddingFile = (file) => {
      if (this.uploader.queue.length > 1) {
        this.uploader.removeFromQueue(this.uploader.queue[0]);
      }
      file.withCredentials = false;
      this.fileNameShow = file.file.name;

      this.showButton = true;
    };


    this.uploader.onProgressItem = (progress: any) => {
      this.progressLoaded = progress['progress'];
    };

    this.uploader.onErrorItem = (response) => {
      // this.error = 'There seems to be a network error. Please check your internet connection and try to upload your image again';
      this.ngOnInit();
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.filenameToRequestRead = response;
      this.filename = item.file.name;
      this.uploadStatus = item.isUploaded;
      this.uploadError = item.isError;
      if (!this.uploadError) {
        this.showButton = false;
      }
    };
  }

  get p1() {
    return this.importVoucherForm.controls;
  }
  get user_id() {
    return this.importVoucherForm.get('user_id');
  }
  get camp_id() {
    return this.importVoucherForm.get('camp_id');
  }


  loadUsers() {
    return this.voucherService.getAllUsers()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              for (let index = 0; index < this.resp.count; index++) {
                const element = this.resp.rows[index];
                this.tempArr.push({
                  value: element.user_id,
                  label: element.user_name,
                });
              }
              this.optionsUsers = this.tempArr;
              this.tempArr = [];
            }, (err) => this.loadingfail = true);
  }

  selectedUser() {
    this.importVoucherForm.get('user_id').valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (value) => {
          this.userChosen = false;
          this.selectedUserId = value;
          this.optionsCampaigns = [];
          if (this.selectedUserId) {
            this.loadCampaigns();
          }
        });
  }

  selectedCampaign() {
    this.importVoucherForm.get('camp_id').valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (value) => {
          this.selectedCampId = value;
          this.campaignChosen = true;
        });
  }


  loadCampaigns() {
    this.payload = {user_id: this.selectedUserId};
    return this.voucherService.getUserCampaigns(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              if (this.resp.count === 0) {
                this.openModalErrorNoCampaign();
                this.tempArr = [];
                this.userChosen = false;
                return;
              }
              for (let index = 0; index < this.resp.count; index++) {
                const element = this.resp.rows[index];
                this.tempArr.push({
                  value: element.camp_id,
                  label: element.camp_name,
                });
              }
              this.optionsCampaigns = this.tempArr;
              this.tempArr = [];
              this.userChosen = true;
            });
  }

  onUploadButtonClick() {
    this.uploadButtonDisabled = true;
    this.uploader.uploadAll();
  }


  onAddToQueue() {
    if (this.filenameToRequestRead === 'File type error') {
      this.openModalErrorFile();
      this.ngOnInit();
      return;
    }
    this.submitted = true;

    if (this.importVoucherForm.invalid) {
      this.openModalErrorUserCampaign();
      return;
    }

    if (this.filenameToRequestRead === '') {
      this.openModalErrorNoFile();
      return;
    }

    this.payload = {
      filenameToRequestRead: this.filenameToRequestRead,
      camp_id: this.selectedCampId,
    };


    this.submittedToQueue = true;
    this.uploadService.UploadTicketCodes(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data) => {
          this.tempArr = data;
          if (this.tempArr.error === 'Tab delimit error') {
            this.openModalErrorTab();
            this.submitted = false;
            return;
          }
          this.badrows = this.tempArr.badRowCount;
          if (this.badrows > 0) {
            this.openModalErrorBadRowsNotify();
          }
          this.vouchersInQueue = this.tempArr.total;
          this.filenameToConfirmQueue = {filenameToConfirmQueue: this.tempArr.newFilename};
          this.tempArr = this.tempArr.firstTen;

          for (let index = 0; index < this.tempArr.length; index++) {
            const element = this.tempArr[index].row;

            this.voucherQueue.push({
              ticket_code: element.ticket_code,
              camp_id: this.payload.camp_id,
            });

            this.submitted = false;
          }
        });
  }

  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', amount: 0, description: '', btnText: ''},
    },
  }

  openModalConfirm() {
    this.modalOptions.data = {
      heading: 'Are you ready to send?',
      content: {
        heading: 'Are you sure you want to submit a total of ',
        amount: this.vouchersInQueue,
        description: ' ticket numbers?',
        btnText: 'Submit!',
      },
    };
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.onSubmitQueue();
          }
        });
  }

  openModalConfirmDelete() {
    this.modalOptions.data = {
      heading: 'Really remove all tickets?',
      content: {
        heading: 'Are you sure you want to remove a total of ',
        amount: this.vouchersInQueue,
        description: ' ticket numbers from this queue?',
        btnText: 'Remove them all',
      },
    };
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.onDeleteQueue();
          }
        });
  }


  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', btnText: ''},
    },
  }


  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Done and done!',
      content: {
        heading: 'Your ticket number queue has been successfully submitted to be assigned to the campaign - Look at you taking care of business!',
        btnText: 'Thank you',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
    this.ngOnInit();
  }

  openModalSuccessDelete() {
    this.modalOptionsSuccess.data = {
      heading: 'Taken care of!',
      content: {
        heading: 'Your ticket number queue has been successfully removed.',
        btnText: 'Good riddance',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }

  modalOptionsErrorInput = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
    },
  }

  openModalErrorFile() {
    this.modalOptionsErrorInput.data = {
      heading: 'This file looks wrong...',
      content: {
        heading: 'You have uploaded the wrong file type. Please upload a .csv or .xls or .xlsx file. Please try again.',
        suggest: 'Suggested Actions:',
        fix1: 'Please ensure that you upload only one of the suggested file formats (.csv, .xls or .xlsx).',
        fix2: 'Please ensure that the field values are in the correct order - See the example files.',
        fix3: 'Then try again.',
        btnText: 'Try again',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
    this.ngOnInit();
  }

  openModalErrorUserCampaign() {
    this.modalOptionsErrorInput.data = {
      heading: 'Someone forgot something...',
      content: {
        heading: 'It seems like you have forgotten to select a user & campaign to assign ticket numbers to.',
        suggest: 'Suggested Actions:',
        fix1: 'Select an User.',
        fix2: 'Then select a Campaign.',
        fix3: 'Then try again.',
        btnText: 'Try again',
      },
    };
  }


  openModalErrorNoCampaign() {
    this.modalOptionsErrorInput.data = {
      heading: 'No Campaigns Found',
      content: {
        heading: 'It seems like this user does not currently have any campaigns.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that the user has at least one campaign.',
        fix2: 'Then try again.',
        fix3: '',
        btnText: 'okay',
      },
    };
  }

  openModalErrorNoFile() {
    this.modalOptionsErrorInput.data = {
      heading: 'You forgot to upload a file...',
      content: {
        heading: 'It seems like you forgot to select and upload your file.',
        suggest: 'Suggested Actions:',
        fix1: 'Select a file in one of the suggested formats (.csv, .xls, .xlsx).',
        fix2: 'Then click on upload file.',
        fix3: '',
        btnText: 'try again',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  openModalErrorTab() {
    this.modalOptionsErrorInput.data = {
      heading: 'This file looks wrong...',
      content: {
        heading: 'There was a problem reading your file. Please ensure that your file is Tab Delimited. Please try again.',
        suggest: 'Suggested Actions:',
        fix1: 'If you uploaded a .csv file ensure that the fields are separated by tab characters.',
        fix2: 'Ensure that every entry is ended with a new line character.',
        fix3: 'Ensure that the final entry is on last line of document & not ended by a new line character',
        btnText: 'try again',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
    this.ngOnInit();
  }

  openModalErrorBadRowsNotify() {
    this.modalOptionsErrorInput.data = {
      heading: 'Some bad news...',
      content: {
        heading: 'We just finished analyzing your file, it seems that ' + this.badrows + ' of the ticket numbers in your file looked to be in the incorrect format.',
        suggest: 'Suggested Actions:',
        fix1: 'Please ensure that all content in the file is in the correct format.',
        fix2: 'Ensure that you have used the correct delimiter.',
        fix3: 'Then try again.',
        btnText: 'okay',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }


  onSubmitQueue() {
    this.uploadService.commitTicketCodes(this.filenameToConfirmQueue)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data) => {
          this.vouchersInQueue = 0;
          this.voucherQueue = [];
          this.importVoucherForm.reset();
          this.submittedToQueue = false;
          this.openModalSuccess();
        },
        (err) => {},
        );
  }

  onDeleteQueue() {
    this.vouchersInQueue = 0;
    this.voucherQueue = [];
    this.importVoucherForm.reset();
    this.submittedToQueue = false;
    this.openModalSuccessDelete();
    this.ngOnInit();
  }


  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
