
import {Component, OnInit} from '@angular/core';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  modalRef: MDBModalRef;

  constructor(private modalService: MDBModalService) {}

  ngOnInit() {

  }
}
