import {ConnectionService} from '../../connection.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';

import {ConfirmComponent} from '../../modals/other/confirm/confirm.component';
import {SuccessModalComponent} from '../../modals/other/success-modal/success-modal.component';
import {ErrorComponent} from '../../modals/other/error/error.component';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;
  contactForm: FormGroup;
  disabledSubmitButton: boolean;
  optionsSelect: Array<any>;

  submitted;

  // In the constructor we define all fields of the form. All fields except contactFormCopy are marked as required,
  // which means that without filling in these fields, we will not be able to send the form. This is the client-side validation.
  constructor(private fb: FormBuilder, private connectionService: ConnectionService, private modalService: MDBModalService) {
  }

  // In the ngOnInit lifecycle we populate the optionsSelect variable with values that will serve us as message subjects that the user will be able to select in the form.
  ngOnInit() {
    this.disabledSubmitButton = false;
    this.submitted = false;
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      cell: ['', [Validators.required, Validators.pattern(/^(0|27)[0-9]{9}$/)]],
      mail: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      message: [''],
      // copy:     [''],
    });

    this.optionsSelect = [
      {value: 'Sales', label: 'Sales'},
      {value: 'Finance', label: 'Finance'},
      {value: 'Request Instant Winner Campaign', label: 'Request Instant Winner Campaign'},
      {value: 'Request Email2SMS Activation', label: 'Request Email2SMS Activation'},
    ];
  }

  get p() {
    return this.contactForm.controls;
  }

  // The onSubmit() method is responsible for calling the sendMessage() method defined in the ConnectionService service.
  // This method is responsible for sending our form to the backend.
  onSubmit() {
    this.submitted = true;
    if (this.contactForm.invalid) {
      this.openModalError();
      return;
    }

    this.openModalConfirm();
  }

  onSend() {
    this.disabledSubmitButton = true;
    this.connectionService.sendMessage(this.contactForm.value)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.openModalSuccess();
          this.contactForm.reset();
          this.ngOnDestroy();
          this.ngOnInit();
        },
        (err) => {
          this.openModalErrorTimeout();
          this.disabledSubmitButton = false;
        },
        );
  }

  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: 'Confirm message?',
      content: {heading: 'Are you sure you want to send this message?', amount: '', description: '', btnText: 'Confirm'},
    },
  }

  openModalConfirm() {
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.onSend();
          }
        });
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', btnText: ''},
    },
  }


  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Message Sent Successfully',
      content: {
        heading: 'Your message is on its way to us, we will contact you as soon as possible',
        btnText: 'Thank you',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }


  modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
    },
  }

  openModalError() {
    this.modalOptionsError.data = {
      heading: 'Whoops...',
      content: {
        heading: 'It seems like you might have forgotten a field or 2.',
        suggest: 'Suggested Actions:',
        fix1: 'Verify that all required fields have been completed.',
        fix2: 'Verify that you provided valid data.',
        fix3: 'Then try again.',
        btnText: 'Will do',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }

  openModalErrorTimeout() {
    this.modalOptionsError.data = {
      heading: 'Message Failed To Send...',
      content: {
        heading: 'I seem to have lost you there for a moment...',
        suggest: 'Suggested Actions:',
        fix1: 'Please check your network connectivity.',
        fix2: 'Try to refresh your browser.',
        fix3: 'Then try again.',
        btnText: 'it happens',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
