import {Component, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TemplatesSmsService} from '../../services/templates-sms.service';
import {TemplateModalComponent} from '../../modals/edit/template-modal/template-modal.component';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import {LoaderService} from '../../services/loader.service';
import {ConfirmComponent} from '../../modals/other/confirm/confirm.component';
import {SuccessModalComponent} from '../../modals/other/success-modal/success-modal.component';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
})
export class TemplatesComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  private resp: any;
  private payload: any;
  private templates: any = [];
  public templateList: any = [];
  private modalOptions = {};

  public idOfSelected: number;

  public loading: boolean;
  public loadingfail: boolean;

  headMessageQueue = ['#', 'Template', 'Message', '', ''];

  constructor(
    private templateService: TemplatesSmsService,
    private modalService: MDBModalService,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }

  ngOnInit() {
    this.resp = {};
    this.templates = [];
    this.templateList = [];
    this.idOfSelected = 0;
    this.loadingfail = false;

    this.getAllSmsTemplates();
  }

  getAllSmsTemplates() {
    this.templateService.getAllSmsTemplates()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              this.templateList = this.resp.rows;
            }, (error) => {
              this.loadingfail = true;
            },
        );
  }

  onDeleteOne(id) {
    this.idOfSelected = this.templateList[id].template_id;
    this.payload = {
      template_id: this.idOfSelected,
    };
    this.templateService.removeSmsTemplate(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data) => {
          this.openModalSuccessDelete();
          this.templateList.splice(id, 1);
        }, (error) => {

        });
  }

  onEditTemplate(id) {
    this.modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: '',
      containerClass: '',
      animated: true,
      data: {
        title: 'Edit Template',
        content: {
          template_id: this.templateList[id].template_id,
          template_name: this.templateList[id].template_name,
          template_text: this.templateList[id].template_text,
        },
        buttonText: 'Update Template',
      },
    };

    this.openModal();
  }


  onAddTemplate() {
    this.modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: '',
      containerClass: '',
      animated: true,
      data: {
        title: 'Create New Template',
        content: {
          template_id: 0,
          template_name: '',
          template_text: '',
        },
        buttonText: 'Create Template',
      },
    };
    this.openModal();
  }


  openModal() {
    this.modalRef = this.modalService.show(TemplateModalComponent, this.modalOptions);

    this.modalRef.content.action.subscribe( (result: any) => {
      if (result === true) {
        this.openModalSuccess();
        this.ngOnInit();
      }
    });
  }

  ngAfterViewInit() {


  }

  modalOptionsConfirm = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', amount: 0, description: '', btnText: ''},
    },
  }

  openModalConfirmDelete(id: any) {
    this.modalOptionsConfirm.data = {
      heading: 'Really remove this Template?',
      content: {
        heading: 'Are you sure you want to permanently remove this SMS Template',
        amount: 0,
        description: '? This is a permanent action! Once I have your confirmation I can start with the termination process...',
        btnText: 'I really want to',
      },
    };
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptionsConfirm);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.onDeleteOne(id);
          }
        });
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', btnText: ''},
    },
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Better, Faster, Stronger!',
      content: {
        heading: 'I just verified and your template has been successfully updated (and/or) saved.',
        btnText: 'Acknowledge',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }

  openModalSuccessDelete() {
    this.modalOptionsSuccess.data = {
      heading: 'Taken care of!',
      content: {
        heading: 'That thing you wanted me to take care of - The thing regarding that other template. It has been done, no evidence linking back to you.',
        btnText: 'Good riddance',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
