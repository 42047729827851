import {Component, OnInit, OnDestroy, Input, ViewChild, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MdbTablePaginationComponent, MdbTableDirective, MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import {OptOutService} from '../../../../services/optout.service';
import {OptoutModalComponent} from '../../../../modals/edit/optout-modal/optout-modal.component';
import {UploadOptoutModalComponent} from '../../../../modals/file/upload-optout-modal/upload-optout-modal.component';

import {ConfirmComponent} from '../../../../modals/other/confirm/confirm.component';
import {SuccessModalComponent} from '../../../../modals/other/success-modal/success-modal.component';
import {ErrorComponent} from '../../../../modals/other/error/error.component';

@Component({
  selector: 'app-optout-numbers',
  templateUrl: './optout-numbers.component.html',
  styleUrls: ['./optout-numbers.component.scss'],
})
export class OptoutNumbersComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() listToEdit: {ougr_id: number};
  @ViewChild(MdbTablePaginationComponent, {static: true}) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, {static: true}) mdbTable: MdbTableDirective;
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  private resp;
  public numbers: any = [];
  private numbersPrevious: any = [];
  public numbersHeadings = [];
  private modalOptions = {};

  private tempArr: any = [];
  private numberToDelete: any = [];
  private numberToEdit: any = [];

  constructor(
    private optoutService: OptOutService,
    private cdRef: ChangeDetectorRef,
    private modalService: MDBModalService,
  ) { }

  ngOnInit() {
    this.resp = {};
    this.getNumbers();
    this.numbersHeadings = ['Number', 'Remove Number', 'Edit Number'];
  }

  getNumbers() {
    this.optoutService.getListNumbers({ougr_id: this.listToEdit.ougr_id})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              for (let index = 0; index < this.resp.count; index++) {
                const element = this.resp.rows[index];
                this.numbers[index] = element;
              }
              this.mdbTable.setDataSource(this.numbers);
              this.numbers = this.mdbTable.getDataSource();
              this.numbersPrevious = this.mdbTable.getDataSource();
            },
        );
  }

  onEditNumber(id) {
    this.modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: '',
      containerClass: '',
      animated: true,
      data: {
        title: 'Edit Number',
        headings: {
          col1head: '',
          col2head: '',
          col3head: '',
          col4head: '',
        },
        content: {
          ou_cell: this.numbers[id].ou_cell,
          ou_id: this.numbers[id].ou_id,
          ougr_id: this.numbers[id].ougr_id,
        },
        buttonText: 'Update Numbers',
      },
    };
    this.openModal();
  }

  onDeleteNumber(id) {
    if (this.numbers.length === 1) {
      this.openModalErrorInput();
      return;
    }

    this.optoutService.deleteNumber({ou_id: this.numbers[id].ou_id})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.numbers.splice(id, 1);
              this.openModalSuccessDelete();
              this.ngOnInit();
            },
        );
  }

  onAddNumber() {
    this.modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: '',
      containerClass: '',
      animated: true,
      data: {
        title: 'Add New Number',
        headings: {
          col1head: '',
          col2head: '',
          col3head: '',
          col4head: '',
        },
        content: {
          ou_cell: '',
          ou_id: 0,
          ougr_id: this.listToEdit.ougr_id,
        },
        buttonText: 'Add Number',
      },
    };
    this.openModal();
  }

  openModal() {
    this.modalRef = this.modalService.show(OptoutModalComponent, this.modalOptions);

    this.modalRef.content.action.subscribe( (result: any) => {
      if (result === true) {
        this.openModalSuccess();
        this.ngOnInit();
      }
    });
  }

  onAddNumberList() {
    this.modalOptions = {
      data: {
        content: {
          ougr_id: this.listToEdit.ougr_id,
        },
      },
    };
    this.openModalFileUpload();
  }

  openModalFileUpload() {
    this.modalRef = this.modalService.show(UploadOptoutModalComponent, this.modalOptions);

    this.modalRef.content.action.subscribe( (result: any) => {
      if (result === true) {
        this.openModalSuccessUpload();
        this.ngOnInit();
      }
    });
  }

  modalOptionsConfirm = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', amount: 0, description: '', btnText: ''},
    },
  }

  openModalConfirmDelete(id: any) {
    this.modalOptionsConfirm.data = {
      heading: 'Really remove this number?',
      content: {
        heading: 'Are you sure you want to permanently remove this number, friend and companion',
        amount: 0,
        description: '? This is a permanent action! Once I have your confirmation I can start with the termination process...',
        btnText: 'I really want to',
      },
    };
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptionsConfirm);
    this.modalRef.content.action
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (result: any) => {
          if (result) {
            this.onDeleteNumber(id);
          }
        });
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', btnText: ''},
    },
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'More numbers!',
      content: {
        heading: 'I just verified and your number has been successfully updated (and/or) saved... Today - adding numbers; Tomorrow - Ruling the world!',
        btnText: 'Acknowledge',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }

  openModalSuccessDelete() {
    this.modalOptionsSuccess.data = {
      heading: 'Taken care of!',
      content: {
        heading: 'That thing you wanted me to take care of - The thing regarding that other number. It has been done, no evidence linking back to you.',
        btnText: 'Good riddance',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }

  openModalSuccessUpload() {
    this.modalOptionsSuccess.data = {
      heading: 'Because time is money.',
      content: {
        heading: 'Just letting you know that I just finished reading through all of your numbers in your file. All of them seemed legit so I went ahead and added them '+
        'to your opt out list.',
        btnText: 'All done',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }

  modalOptionsErrorInput = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
    },
  }

  openModalErrorInput() {
    this.modalOptionsErrorInput.data = {
      heading: 'Oops...',
      content: {
        heading: 'It seems like you are trying to remove the last contact in your opt out list.',
        suggest: 'Suggested Actions:',
        fix1: 'For technical reasons this is commonly viewed as a illegal move.',
        fix2: 'If you have to remove this number - Try adding another number first then try again.',
        fix3: 'Alternatively you can remove the entire opt out list and start anew.',
        btnText: 'Okay',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
}
