/* eslint-disable max-len */
import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {interval, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {LiveReaderService} from '../../../services/live-reader.service';
import {XlsxService} from '../../../services/xlsx.service';
import {MDBModalRef, MDBModalService, MdbTablePaginationComponent, MdbTableDirective, IMyOptions} from 'ng-uikit-pro-standard';

import {SuccessModalComponent} from '../../../modals/other/success-modal/success-modal.component';
import {ErrorComponent} from '../../../modals/other/error/error.component';
import {LoaderService} from '../../../services/loader.service';
import {DatePipe} from '@angular/common';

const counter = interval(15000);

@Component({
  selector: 'app-reply-sms',
  templateUrl: './reply-sms.component.html',
  styleUrls: ['./reply-sms.component.scss'],
})
export class ReplySmsComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  private subscription: Subscription = new Subscription();

  @ViewChild(MdbTablePaginationComponent, {static: true}) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, {static: true}) mdbTable: MdbTableDirective;
  modalRef: MDBModalRef;

  elements: any = [];
  previous: any = [];
  headElements = ['Cellphone', 'Message', 'Date Received'];
  pipe = new DatePipe('en-ZA'); // Use your own locale

  private resp: any;
  public currcount: number;

  public loadingfail: boolean;
  public refreshCount: number;

  public link: any;
  public downloadReady: boolean;
  public tutorialActive: boolean;
  public processing: boolean;
  public submitted: boolean;
  private payload2: any;
  public loading: boolean;
  public loading2: boolean;
  public liveOrReport: boolean;
  scheduledForm: FormGroup;


  public myDatePickerOptions: IMyOptions = {
    todayBtnTxt: 'Today',
    clearBtnTxt: 'Clear',
    closeBtnTxt: 'Done',

    firstDayOfWeek: 'su',
    closeAfterSelect: true,
    editableDateField: false,
  };

  constructor(
    private xlsx: XlsxService,
    private liveReader: LiveReaderService,
    private cdRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private modalService: MDBModalService,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }

  ngOnInit() {
    this.link = {};
    this.tutorialActive = false;
    this.payload2 = {};
    this.downloadReady = false;
    this.processing = false;
    this.submitted = false;
    this.liveOrReport = false;

    this.scheduledForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });

    this.loading = false;
    this.loading2 = false;
    this.loadingfail = false;
    this.currcount = 0;
    this.refreshCount = 0;
    this.resp = [];
    this.loadAllEntries();
    this.subscription = counter
        .subscribe(() => {
          this.loadAllEntries();
        });
  }

  get s() {
    return this.scheduledForm.controls;
  }
  get startDate() {
    return this.scheduledForm.get('startDate');
  }
  get endDate() {
    return this.scheduledForm.get('endDate');
  }

  loadAllEntries() {
    this.loadingfail = false;
    this.onUnsub();
    this.liveReader.getLiveReply()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              if (this.resp.length !== this.currcount ) {
                this.elements = this.resp;
                this.mdbTable.setDataSource(this.elements);
                this.elements = this.mdbTable.getDataSource();
                this.previous = this.mdbTable.getDataSource();
                this.currcount = this.resp.length;
              }
              this.resp = [];
            },
            (error) => {
              this.loadingfail = true;
            },
        );
  }

  onDownloadReport() {
    this.loading2 = true;
    this.submitted = true;
    if (this.scheduledForm.invalid) {
      this.openModalErrorInput();
      this.loading2 = false;
      return;
    }
    this.payload2 = {
      startDate: this.startDate.value,
      endDate: this.endDate.value,
    };

    this.xlsx.ReceivedSms(this.payload2)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.link = data;
              if (this.link === 'No Entries Found') {
                this.openModalErrorNotFound();
                this.loading2 = false;
                return;
              }
              const newBlob = new Blob([this.link], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
              const data2 = window.URL.createObjectURL(newBlob);
              const dllink = document.createElement('a');
              dllink.href = data2;
              dllink.download = 'Reply_SMS_Report_' + this.payload2.startDate + '_to_' + this.payload2.endDate + '.xlsx';
              dllink.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
              this.loading2 = false;
              setTimeout(function() {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data2);
                dllink.remove();
              }, 100);
            },
        );
  }


  onRefresh() {
    this.scheduledForm.reset();
    this.ngOnInit();
  }

  transformdate(date) {
    return this.pipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', btnText: ''},
    },
  }


  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Your report is ready!',
      content: {
        heading: 'I just finished compiling this report you requested. My apologies for taking so long - I tend to procrastinate a lot.',
        btnText: 'Awesome',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }

  modalOptionsErrorInput = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
    },
  }

  openModalErrorInput() {
    this.modalOptionsErrorInput.data = {
      heading: 'There seems to be a problem...',
      content: {
        heading: 'You haven\'t provided a sufficient date rage.',
        suggest: 'Suggested Actions:',
        fix1: 'Select a start date.',
        fix2: 'Select an end date.',
        fix3: 'Ensure that the end date is after the start date.',
        btnText: 'Fix Dates',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  openModalErrorNotFound() {
    this.modalOptionsErrorInput.data = {
      heading: 'The results are in...',
      content: {
        heading: 'I just finished the wild goose chase you sent me on. I couldn\'t even find a single reply message between ' + this.payload2.startDate + ' and ' + this.payload2.endDate + '.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you have selected the correct time window.',
        fix2: 'Give the recipients some more time reply.',
        fix3: 'Think of 3x things you are thankful for today.',
        btnText: 'Will do',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }


  ngOnDestroy(): any {
    this.subscription.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onUnsub(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
}
