/* eslint-disable no-multi-str */
import {Injectable} from '@angular/core';
import Shepherd from 'shepherd.js';

const campReaderTour = new Shepherd.Tour({
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
    classes: 'shadow-md bg-purple-dark',
    scrollTo: {behavior: 'smooth', block: 'center'},
  },
  confirmCancel: false,
  // confirmCancelMessage: '',
  exitOnEsc: false,
  keyboardNavigation: false,
  useModalOverlay: true,
});

@Injectable({
  providedIn: 'root',
})
export class TutLivereadService {
  constructor() { }

  campReaderTour() {
    campReaderTour.addSteps([{
      id: 'start',
      title: 'Using the Live Reader',
      text: 'This is a quick tutorial that explains how the Live Reader works.<br><br>\
      As always I am available. At the end you will be more skilled at starting at this page.<br><br>\
      <mark>- At some steps I might ask you to interact with the page before we can move on. These interactive actions will be highlighted like this</mark>',
      buttons: [
        {action() {
          return this.next();
        }, text: 'Let\'s Begin'},
      ],
    }, {
      id: 'intro',
      title: 'Campaign Live Reader',
      text: 'The Live Reader will display any and all valid entries from any of your active campaigns as they come in.<br><br>\
      <i>Talk about instant gratification.</i>',
      attachTo: {element: '.mainS19', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step1',
      title: 'Your entries',
      text: 'All your entries will be displayed here. Entries are limited to only the current day - From midnight to midnight.<br><br>\
      <i>The live reader will auto refresh every 15 seconds - Keeping you in the loop as things happen..</i>',
      attachTo: {element: '.s1', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step2',
      title: 'Manual Refresh',
      text: 'If you want to take matters into your own hands - you can always click on this button to force a refresh.<br><br>\
      *Note that this will not stop me from doing my automated refreshes.<br><br>',
      attachTo: {element: '.s2', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Bye'},
      ],
    }, {
      id: 'step3',
      title: 'Well that\'s that I guess.',
      text: 'This concludes our guide on the Campaign Live Reader.<br><br>\
      Any questions?',
      canClickTarget: false,
      buttons: [
        {action() {
          return this.complete();
        }, text: 'Fin'},
      ],
    },
    ]);


    campReaderTour.start();
  }
}
