import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {ReportsCampaignsService} from '../../../services/reports-campaigns.service';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';

import {SuccessModalComponent} from '../../../modals/other/success-modal/success-modal.component';
import {ErrorComponent} from '../../../modals/other/error/error.component';
import {LoaderService} from '../../../services/loader.service';

@Component({
  selector: 'app-ticket-usage',
  templateUrl: './ticket-usage.component.html',
  styleUrls: ['./ticket-usage.component.scss'],
})
export class TicketUsageComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  public selected: boolean;
  public searched: boolean;
  public submitted: boolean;
  public loading: boolean;
  public loadingfail: boolean;
  private payload: any;
  public ticketUsage: {total: number, used: number, remain: number};
  public selectedCampaignId: {camp_id: number};
  private resp: any;
  private resp2: any;
  private resp3: any;
  public ticketInfo: any;
  private campaigns: Array<any>;
  campaignForm: FormGroup;
  ticketForm: FormGroup;
  optionsCampaigns: Array<any>;
  headTicketUsage = ['Ticket Code', 'Ticket Used', 'Ticket Used Date', 'Entry Cellphone', 'Ticket Message', 'Entry Network', 'Is a winner?'];

  constructor(
    private campaignService: ReportsCampaignsService,
    private fb: FormBuilder,
    private modalService: MDBModalService,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }


  ngOnInit() {
    this.selected = false;
    this.searched = false;
    this.submitted = false;
    this.loadingfail = false;
    this.loading = false;
    this.payload = {};
    this.ticketUsage = {total: 0, used: 0, remain: 0};
    this.selectedCampaignId = {camp_id: 0};
    this.ticketInfo = [];
    this.campaigns = [];
    this.optionsCampaigns = [];
    this.resp = {};
    this.resp2 = {};
    this.resp3 = {};
    this.loadAllCampaigns();
    this.campaignForm = this.fb.group({
      camp_id: ['', Validators.required],
    });
    this.ticketForm = this.fb.group({
      ticket_code: ['', Validators.required],
    });
    this.selectedCampaign();
  }

  get p() {
    return this.ticketForm.controls;
  }
  get ticket_code() {
    return this.ticketForm.get('ticket_code');
  }

  loadAllCampaigns() {
    this.campaignService.getUserInstantCampaigns()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              for (let index = 0; index < this.resp.count; index++) {
                const element = this.resp.rows[index];
                this.campaigns.push({
                  value: element.camp_id,
                  label: element.camp_name,
                });
              }
              this.optionsCampaigns = this.campaigns;
              this.resp = {};
            },
            (err) => {
              this.loadingfail = true;
            },
        );
  }

  selectedCampaign() {
    this.campaignForm.get('camp_id').valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (value) => {
          this.selected = false;
          this.selectedCampaignId = {camp_id: value};
          this.getCampaignTicketUsage();
        });
  }


  getCampaignTicketUsage() {
    this.campaignService.getSelectedCampaignTicketUsage(this.selectedCampaignId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.selected = true;
              this.resp2 = data;
            },
            (err) => {
              this.selected = false;
            },
        );
  }

  onSubmitTicketNumber() {
    this.submitted = true;

    // stop if invalid
    if (this.ticketForm.invalid) {
      this.openModalErrorInput();
      return;
    }

    this.getSelectedTicketInfo();
  }

  getSelectedTicketInfo() {
    this.payload = {
      camp_id: this.selectedCampaignId.camp_id,
      ticket_code: this.ticket_code.value,
    };
    this.campaignService.getSelectedTicketNumberInfo(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp3 = data;
              if (!this.resp3.success) {
                this.searched = false;
                this.openModalErrorInvalidTime();
                return;
              } else if (this.resp3.used === false) {
                this.searched = true;
                this.openModalSuccess();
                this.ticketInfo = {
                  code: this.resp3.ticketInfo.ticket_code,
                  used: 'No',
                  date: 'n/a',
                  cell: 'n/a',
                  winner: 'n/a',
                  network: 'n/a',
                  message: 'n/a',
                };
                return;
              } else if (this.resp3.used === true) {
                this.searched = true;
                this.openModalSuccess();
                if (this.resp3.validInfo.valid_isWinner === 1) {
                  this.resp3.validInfo.valid_isWinner = 'Yes';
                } else {
                  this.resp3.validInfo.valid_isWinner = 'No';
                }
                this.ticketInfo = {
                  code: this.resp3.ticketInfo.ticket_code,
                  used: 'Yes',
                  date: this.resp3.ticketInfo.ticket_dteUsed,
                  cell: this.resp3.validInfo.valid_cell,
                  winner: this.resp3.validInfo.valid_isWinner,
                  network: this.resp3.validInfo.valid_networkType,
                  message: this.resp3.messageInfo.smsi_msg,
                };
                return;
              }
            });
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', btnText: ''},
    },
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Found It!',
      content: {
        heading: 'I have successfully found your ticket.',
        btnText: 'let\'s see',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }


  modalOptionsErrorInput = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
    },
  }

  openModalErrorInput() {
    this.modalOptionsErrorInput.data = {
      heading: 'Nothing to look for.',
      content: {
        heading: 'It seems that you haven\'t provided a ticket number for me to look up.',
        suggest: 'Suggested Actions:',
        fix1: 'Provide a valid ticket number.',
        fix2: 'Try again.',
        fix3: '',
        btnText: 'okay',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  openModalErrorInvalidTime() {
    this.modalOptionsErrorInput.data = {
      heading: 'This is not the ticket number you are looking for.',
      content: {
        heading: 'The number that you provided does not seem to exist in our database.',
        suggest: 'Suggested Actions:',
        fix1: 'Verify that you have provided the correct ticket number.',
        fix2: 'Try again.',
        fix3: '',
        btnText: 'okay',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }


  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
