/* eslint-disable no-multi-str */
import {Injectable} from '@angular/core';
import Shepherd from 'shepherd.js';
import {Router} from '@angular/router';

const smsScheduledTour = new Shepherd.Tour({
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
    classes: 'shadow-md bg-purple-dark',
    scrollTo: {behavior: 'smooth', block: 'center'},
  },
  confirmCancel: false,
  // confirmCancelMessage: '',
  exitOnEsc: false,
  keyboardNavigation: false,
  useModalOverlay: true,
});

@Injectable({
  providedIn: 'root',
})
export class TutSmsScheduledService {
  constructor(private router: Router) { }

  smsScheduledTour() {
    const that = this;
    smsScheduledTour.addSteps([{
      id: 'start',
      title: 'Managing Scheduled SMS',
      text: 'This is an interactive tutorial and will walk you through everything you need to know to effectively manage any scheduled SMS that you might have.<br><br>\
      As always I will try my best to be super informative. At the end you will have mastered the art of Scheduling SMS.<br><br>\
      <mark>- At some steps I will ask you to interact with the page before we can move on. These interactive actions will be highlighted like this</mark>',
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'Step1',
      title: 'Scheduled Single Messages',
      text: 'View and micromanage all unsent scheduled messages.<br><br>\
      <i>- Because it is never too late to change your mind.</i><br><br>',
      attachTo: {element: '.mainS14', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step2',
      title: 'Scheduled Queue',
      text: 'All messages that have been scheduled from the "Send Single SMS" page are shown here.<br><br>\
      <i>- From here you may either send a message now or remove it.</i><br><br>',
      attachTo: {element: '.t2', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step3',
      title: 'Cell Number',
      text: 'This column lists all recipients\' numbers.<br><br>\
      <i>- "Cell Number": a set of figures identifying the mobile telephone of a particular subscriber.</i><br><br>',
      attachTo: {element: '.t3', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step4',
      title: 'Message',
      text: 'This column lists the message that will be sent to each recipient respectively.<br><br>\
        <i>- The absence of a message sometimes is a presence of one.</i><br><br>',
      attachTo: {element: '.t4', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step5',
      title: 'Date to Send',
      text: 'This column displays the date when the message is scheduled to be sent.<br><br>\
        <i>- The past cannot be changed. The future is yet in your power.</i><br><br>',
      attachTo: {element: '.t5', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step6',
      title: 'Time to Send',
      text: 'This column displays the exact time at which your message will be sent.<br><br>\
        <i>- Better three hours too soon than a minute too late.</i><br><br>',
      attachTo: {element: '.t6', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step7',
      title: 'Send now?',
      text: 'If plans change and you prefer to get this message out ASAP. You can click on "Send Now" and your message will be urgently sent.<br><br>\
        <i>- You can\'t stop the future<br>You can\'t rewind the past<br>The only way to learn the secret<br> ...is to press play.</i><br><br>',
      attachTo: {element: '.t7', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step8',
      title: 'Remove?',
      text: 'If you made a mistake (which I doubt you would) you can click on "Remove" and that message will be erased.<br><br>\
        <i>- To err is only human, that is why I was created.</i><br><br>',
      attachTo: {element: '.t8', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step9',
      title: 'Moving Along',
      text: 'Now that you understand the function of Scheduled Single SMS we will move on over to Scheduled Named Batches.<br><br>',
      canClickTarget: false,
      buttons: [
        {action() {
          return that.router.navigate(['/sms/scheduled/messages/named']), this.next();
        }, text: 'Let\'s Go'},
      ],
    }, {
      id: 'step10',
      title: 'Scheduled Message Batches',
      text: 'View and micromanage all unsent scheduled message batches.<br><br>\
        <i>- You\'ll be glad that you named your batches.</i><br><br>',
      attachTo: {element: '.mainS15', on: 'bottom'},
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 400);
        });
      },
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step11',
      title: 'Scheduled Batches Queue',
      text: 'All message batches that have been scheduled are shown here.<br><br>\
        <i>- From here you may view, reschedule, instantly send or remove your batches.</i><br><br>',
      attachTo: {element: '.t2', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step12',
      title: 'Batch Name',
      text: 'This column lists all batch names.<br><br>\
        <i>- "Batch": A quantity or consignment of goods produced at one time.</i><br><br>',
      attachTo: {element: '.t3', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step13',
      title: 'Number of messages',
      text: 'This column indicates the amount of messages contained in each individual batch.<br><br>\
        <i>- Because sometimes quantity is better than quality... Wait that doesn\'t sound right does it?</i><br><br>',
      attachTo: {element: '.t4', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step14',
      title: 'Date to Send',
      text: 'This column indicates the date at which your batch will be sent.<br><br>\
        <i>- Don\'t gamble on the future, act now, without delay.</i><br><br>',
      attachTo: {element: '.t5', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step15',
      title: 'Time to Send',
      text: 'This column displays the exact time at which your batch will be sent.<br><br>\
        <i>- Time takes it all, whether you want it to or not.</i><br><br>',
      attachTo: {element: '.t6', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step16',
      title: 'View',
      text: 'This column allows you to see all messages contained within the respective batch.<br><br>\
        <i>- For when there is one rotten tomato in your apple basket.</i><br><br>',
      attachTo: {element: '.t7', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step17',
      title: 'Reschedule?',
      text: 'This column allows you to select a new time and date to send this batch.<br><br>\
        <i>- Change is the only constant in a sea of variables.</i><br><br>',
      attachTo: {element: '.t8', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step18',
      title: 'Send Now?',
      text: 'This column allows you to send a batch ASAP.<br><br>\
        <i>- I\'ll get to it Now-Now.</i><br><br>',
      attachTo: {element: '.t9', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step19',
      title: 'Remove?',
      text: 'This column allows you to permanently remove entire batches respectively.<br><br>\
        <i>- Some things just can\'t be fixed.</i><br><br>',
      attachTo: {element: '.t10', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step20',
      title: 'Well Done!',
      text: 'You have just successfully completed The Scheduled SMS Tutorial.<br><br>\
        <i>This concludes our tutorial - You can now Schedule and Reschedule to your heart\'s content!</i><br><br>\
        <mark>*** Don\'t forget to check out the other 3x in depth SMS tutorials covering Sending SMS, Phone Books and SMS Reports respectively.</mark>',
      canClickTarget: false,
      buttons: [
        {action() {
          return this.complete();
        }, text: 'End Tutorial'},
      ],
    },
    ]);


    smsScheduledTour.start();
  }
}
