import {Component, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {ReportsCampaignsService} from '../../../services/reports-campaigns.service';
import {MDBModalRef, MDBModalService, IMyOptions} from 'ng-uikit-pro-standard';
import {SuccessModalComponent} from '../../../modals/other/success-modal/success-modal.component';
import {ErrorComponent} from '../../../modals/other/error/error.component';
import {ActivatedRoute} from '@angular/router';
import {XlsxService} from '../../../services/xlsx.service';
import {LoaderService} from '../../../services/loader.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-received-sms',
  templateUrl: './received-sms.component.html',
  styleUrls: ['./received-sms.component.scss'],
})
export class ReceivedSmsComponent implements OnInit, OnDestroy, AfterViewInit {
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  pipe = new DatePipe('en-ZA'); // Use your own locale

  public date_flag: boolean;
  public link: any;
  public downloadReady: boolean;
  public tutorialActive: boolean;
  public processing: boolean;
  public submitted: boolean
  private payload: any;
  private resp: any;
  public selectedCampaignId: any;
  public loading: boolean;
  public loadingfail: boolean;
  public selectedCampaignName: string;

  campaign: {camp_id: number};
  private campaigns: any = [];

  public myDatePickerOptions: IMyOptions = {
    todayBtnTxt: 'Today',
    clearBtnTxt: 'Clear',
    closeBtnTxt: 'Done',

    firstDayOfWeek: 'su',
    closeAfterSelect: true,
    editableDateField: false,
  };

  campaignForm: FormGroup;
  scheduledForm: FormGroup;
  optionsCampaigns: Array<any>;

  constructor(
    private xlsx: XlsxService,
    private campaignService: ReportsCampaignsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private modalService: MDBModalService,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }

  ngOnInit() {
    this.date_flag = false;
    this.selectedCampaignName = '';
    this.loading = false;
    this.loadingfail = false;
    this.link = {};
    this.downloadReady = false;
    this.tutorialActive = false;
    this.processing = false;
    this.submitted = false;
    this.payload = {};
    this.selectedCampaignId = {};
    this.resp = {};
    this.campaigns = [];
    this.loadAllCampaigns();

    this.campaign = {
      camp_id: this.route.snapshot.params['camp_id'],
    };

    this.scheduledForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });

    this.campaignForm = this.fb.group({
      camp_id: ['', Validators.required],
    });
    this.selectedCampaign();

    if (this.campaign.camp_id !== undefined) {
      this.campaignForm.setValue({
        camp_id: this.campaign.camp_id,
      });
    }
  }

  get s() {
    return this.campaignForm.controls;
  }
  get p() {
    return this.scheduledForm.controls;
  }
  get camp_id() {
    return this.campaignForm.get('camp_id');
  }
  get startDate() {
    return this.scheduledForm.get('startDate');
  }
  get endDate() {
    return this.scheduledForm.get('endDate');
  }

  loadAllCampaigns() {
    this.campaignService.getUserCampaigns()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;
              for (let index = 0; index < this.resp.count; index++) {
                const element = this.resp.rows[index];
                this.campaigns.push({
                  value: element.camp_id,
                  label: element.camp_name,
                });
              }
              this.optionsCampaigns = this.campaigns;
              this.resp = {};
            },
            (error) => {
              this.loadingfail = true;
            },
        );
  }

  onDownloadReport() {
    this.submitted = true;
    if (this.campaignForm.invalid) {
      this.openModalErrorInput();
      return;
    }

    if (this.date_flag === true) {
      if (this.scheduledForm.invalid) {
        this.openModalErrorInputDate();
        return;
      } else {
        this.payload = {
          camp_id: this.selectedCampaignId.camp_id,
          date_flag: true,
          startDate: this.startDate.value,
          endDate: this.endDate.value,
        };
      }
    } else {
      this.payload = {
        camp_id: this.selectedCampaignId.camp_id,
        date_flag: false,
        startDate: '',
        endDate: '',
      };
    }


    this.xlsx.CampaignEntries(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.link = data;

              if (this.link === 'No Entries Found') {
                this.openModalErrorNotFound();
                this.processing = false;
                this.submitted = false;
                return;
              }
              const newBlob = new Blob([this.link], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
              const data2 = window.URL.createObjectURL(newBlob);
              const dllink = document.createElement('a');
              dllink.href = data2;
              dllink.download = this.selectedCampaignName + '_Campaign_Entries_Report.xlsx';
              dllink.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

              setTimeout(function() {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data2);
                dllink.remove();
              }, 100);
            },
        );
  }

  onRefresh() {
    this.ngOnDestroy();
    this.ngOnInit();
  }


  selectedCampaign() {
    this.campaignForm.get('camp_id').valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (value) => {
          this.selectedCampaignId = {camp_id: value};
          for (let index = 0; index < this.optionsCampaigns.length; index++) {
            const element = this.optionsCampaigns[index];
            if (element.value === value) {
              this.selectedCampaignName = element.label;
              break;
            }
          }
        });
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', btnText: ''},
    },
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Your report is ready!',
      content: {
        heading: 'I just finished compiling this report you requested. My apologies for taking so long - I tend to procrastinate a lot.',
        btnText: 'Awesome',
      },
    };
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  }

  modalOptionsErrorInput = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
    },
  }

  openModalErrorInput() {
    this.modalOptionsErrorInput.data = {
      heading: 'There seems to be a problem...',
      content: {
        heading: 'You haven\'t provided me with a campaign to look up.',
        suggest: 'Suggested Actions:',
        fix1: 'Click on the campaign select.',
        fix2: 'Select one of your campaigns.',
        fix3: 'Then try again.',
        btnText: 'select campaign',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  openModalErrorInputDate() {
    this.modalOptionsErrorInput.data = {
      heading: 'There seems to be a problem...',
      content: {
        heading: 'You haven\'t provided me with suitable date ranges to generate this report.',
        suggest: 'Suggested Actions:',
        fix1: 'Select a starting date.',
        fix2: 'Select an ending date.',
        fix3: 'Then try again.',
        btnText: 'select campaign',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  openModalErrorNotFound() {
    this.modalOptionsErrorInput.data = {
      heading: 'The results are in...',
      content: {
        heading: 'I just finished the wild goose chase you sent me on. I couldn\'t even find a single entry.',
        suggest: 'Suggested Actions:',
        fix1: 'Give the entrees some more time to enter.',
        fix2: 'Advertise your campaign to raise awareness',
        fix3: 'Think of 3x things you are thankful for today.',
        btnText: 'Will do',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }

  openModalErrorTimeout() {
    this.modalOptionsErrorInput.data = {
      heading: 'Update Failed Successfully...',
      content: {
        heading: 'I seem to have lost you there for a moment...',
        suggest: 'Suggested Actions:',
        fix1: 'Please check your network connectivity.',
        fix2: 'Try to refresh your browser.',
        fix3: 'Then try again.',
        btnText: 'it happens',
      },
    };
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput);
  }


  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {

  }
}
