import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import {MdbTablePaginationComponent, MdbTableDirective} from 'ng-uikit-pro-standard';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';

import {ConfirmComponent} from '../../../modals/other/confirm/confirm.component';
import {SuccessModalComponent} from '../../../modals/other/success-modal/success-modal.component';
import {ErrorComponent} from '../../../modals/other/error/error.component';
import {AdminService} from '../../../services/admin.service';
import {LoaderService} from '../../../services/loader.service';

@Component({
  selector: 'app-shortcodes',
  templateUrl: './shortcodes.component.html',
  styleUrls: ['./shortcodes.component.scss'],
})
export class ShortcodesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, {static: false}) mdbTablePagination: MdbTablePaginationComponent;
 @ViewChild(MdbTableDirective, {static: false}) mdbTable: MdbTableDirective;
 @ViewChild(MdbTablePaginationComponent, {static: false}) mdbTablePagination2: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, {static: false}) mdbTable2: MdbTableDirective;
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

 public searchText: string;
  public previous2: string;

  scAdded: boolean;
  scChanged: boolean;
  scDeleted: boolean;
  scAssigned: boolean;
  scSelected: boolean;
  campSelected: boolean;
  scSharedOrDedicated: string;
  scSharedOrDedicatedB: boolean;
  public scHasUser: boolean;
  public tabSelected: number;

  private resp: any;
  private resp2: any;
  private payload: any;
  previous: Array<any>;
  private tempShortcodeArr: Array<any>;
  private shortcodeValueArr: Array<any>;
  private tempUsersArr: Array<any>;
  private assignedUsers: any;
 public campaignsArr: Array<any>;
 public shortcodes: Array<any>;

  public loading: boolean;
  public loadingfail: boolean

  public selectedSCIDa: number;
  public selectedSCIDb: number;
  public selectedSCIDc: number;

  addNewScForm: FormGroup;
  changeScFormSC: FormGroup;
  changeScForm: FormGroup;
  assignScFormSC: FormGroup;
 assignScForm: FormGroup;
 assignScForm2: FormGroup;
  campaignScForm: FormGroup;

  optionsAddNewScValue: Array<any>;
  optionsAddNewScDesc: Array<any>;

  optionsChangeScShortcodes: Array<any>;

  optionsAssignScShortcodes: Array<any>;
  optionsAssignScUsers: Array<any>;

  optionsCampaignScShortcodes: Array<any>;

  headUsersAssigned = ['User ID', 'Username', 'Remove'];
  headCampaignsAssigned = ['Campaign ID', 'Campaign', 'Username'];

  constructor(
    private shortcodeService: AdminService,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private modalService: MDBModalService,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }


  ngOnInit() {
    this.shortcodes = [];
    this.loadingfail = false;
    this.assignedUsers = {user_id: 0, user_name: ''};
    this.resp = {};
    this.resp2 = {};
    this.payload = {};
    this.previous = [];
    this.tempShortcodeArr = [];
    this.shortcodeValueArr = [];
    this.tempUsersArr = [];
    this.assignedUsers = {};
    this.campaignsArr = [];
    this.scAdded = false;
    this.scChanged = false;
    this.scDeleted = false;
    this.scAssigned = false;
    this.scSelected = false;
    this.campSelected = false;
    this.scHasUser = false;

    this.tabSelected = 1;
    this.selectedSCIDa = -1;
    this.selectedSCIDb = -1;
    this.selectedSCIDc = -1;

    this.loadAllScInfo();
    this.addNewScForm = this.fb.group({
      sc_number: ['', [Validators.required, Validators.maxLength(20), Validators.pattern(/^([0-9]|[*]|[#]|[+])*$/)]],
      sc_type: [false, Validators.required],
      sc_value: ['', Validators.required],
      sc_desc: ['', Validators.required],
    });

    this.changeScFormSC = this.fb.group({
      sc_id: ['', Validators.required],
    });

    this.changeScForm = this.fb.group({
      sc_number: ['', [Validators.required, Validators.maxLength(20), Validators.pattern(/^([0-9]|[*]|[#]|[+])*$/)]],
      sc_typeB: [false, Validators.required],
      sc_value: ['', Validators.required],
      sc_desc: ['', Validators.required],
    });
    this.selectedShortcodeChange();

    this.assignScFormSC = this.fb.group({
      sc_id: ['', Validators.required],
    });

    this.assignScForm = this.fb.group({
      user_id: ['', Validators.required],
    });

    this.assignScForm2 = this.fb.group({
      user_id: ['', Validators.required],
    });

    this.selectedShortcodeUser();

    this.campaignScForm = this.fb.group({
      sc_id: ['', Validators.required],
    });
    this.selectedShortcodeCampaign();


    this.optionsAddNewScDesc = [
      {value: 'ZAR', label: 'ZAR'},
    ];
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.shortcodes = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.shortcodes = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.mdbTable.searchDataObservable(this.searchText).subscribe(() => {
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
    });
  }


  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }

  get p1() {
    return this.addNewScForm.controls;
  }
  get p2a() {
    return this.changeScFormSC.controls;
  }
  get p2() {
    return this.changeScForm.controls;
  }
  get p3a() {
    return this.assignScFormSC.controls;
  }
  get p3() {
    return this.assignScForm.controls;
  }
  get p3b() {
    return this.assignScForm2.controls;
  }
  get p4() {
    return this.campaignScForm.controls;
  }

  get sc_number_add() {
    return this.addNewScForm.get('sc_number');
  }
  get sc_type_add() {
    return this.addNewScForm.get('sc_type');
  }
  get sc_value_add() {
    return this.addNewScForm.get('sc_value');
  }
  get sc_desc_add() {
    return this.addNewScForm.get('sc_desc');
  }

  get sc_id_change() {
    return this.changeScFormSC.get('sc_id');
  }
  get sc_number_change() {
    return this.changeScForm.get('sc_number');
  }
  get sc_type_change() {
    return this.changeScForm.get('sc_typeB');
  }
  get sc_value_change() {
    return this.changeScForm.get('sc_value');
  }
  get sc_desc_change() {
    return this.changeScForm.get('sc_desc');
  }

  get sc_id_user() {
    return this.assignScFormSC.get('sc_id');
  }
  get user_id_user() {
    return this.assignScForm.get('user_id');
  }
  get user_id_user2() {
    return this.assignScForm2.get('user_id');
  }

  get sc_id_campaign() {
    return this.campaignScForm.get('sc_id');
  }

  selectedShortcodeChange() {
    this.changeScFormSC.get('sc_id').valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (value) => {
          this.selectedSCIDa = value;
          if (this.resp.shortcode.rows[value].sc_type === 's') {
            this.scSharedOrDedicatedB = false;
          } else if (this.resp.shortcode.rows[value].sc_type === 'd') {
            this.scSharedOrDedicatedB = true;
          } else if (this.resp.shortcode.rows[value].sc_type === 'ds') {
            this.scSharedOrDedicatedB = true;
          } else {
            this.scSharedOrDedicatedB = false;
          }
          this.changeScForm.setValue({
            sc_number: this.resp.shortcode.rows[value].sc_number,
            sc_typeB: this.scSharedOrDedicatedB,
            sc_value: this.resp.shortcode.rows[value].sc_value,
            sc_desc: this.optionsAddNewScDesc[0].value,
          });
        },
        );
  }

  selectedShortcodeUser() {
    this.assignScFormSC.get('sc_id').valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (value) => {
          this.selectedSCIDb = value;
          this.getAllUsersAssignedToShortcode();
        });
  }

  selectedShortcodeCampaign() {
    this.campaignScForm.get('sc_id').valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe( (value) => {
          this.selectedSCIDc = value;
          this.getAllCampaignsAssignedToShortcode();
        });
  }

  loadAllScInfo() {
    this.shortcodeService.getAllShortcodeValues()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp = data;

              for (let index = 0; index < this.resp.value.length; index++) {
                const element = this.resp.value[index];
                this.shortcodeValueArr.push({
                  value: element.sc_value,
                  label: 'R ' + element.sc_value.toFixed(2),
                });
              }
              this.optionsAddNewScValue = this.shortcodeValueArr;
              this.shortcodeValueArr = [];

              this.loadAllShortcodes();
            },
            (err) => {
              this.loadingfail = true;
            },
        );
  }

  loadAllShortcodes() {
    for (let index = 0; index < this.resp.shortcode.count; index++) {
      const element = this.resp.shortcode.rows[index];
      this.tempShortcodeArr.push({
        value: index,
        label: element.sc_number,
      });
    }
    this.optionsChangeScShortcodes = this.tempShortcodeArr;
    this.tempShortcodeArr = [];
    this.loadAllDedicatedShortcodes();
  }


  loadAllDedicatedShortcodes() {
    for (let index = 0; index < this.resp.dedicated.count; index++) {
      const element = this.resp.dedicated.rows[index];
      this.tempShortcodeArr.push({
        value: index,
        label: element.sc_number,
      });
      if (element.sc_assgn2user === 0) {
        this.shortcodes.push({
          sc_number: element.sc_number,
          sc_value: element.sc_value,
          sc_id: element.sc_id,
        });
      }
    }
    this.mdbTable.setDataSource(this.shortcodes);
    this.shortcodes = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
    this.optionsAssignScShortcodes = this.tempShortcodeArr;
    this.tempShortcodeArr = [];
    this.loadAllUsers();
  }


  loadAllUsers() {
    for (let index = 0; index < this.resp.user.count; index++) {
      const element = this.resp.user.rows[index];
      this.tempUsersArr.push({
        value: element.user_id,
        label: element.user_name,
      });
    }
    this.optionsAssignScUsers = this.tempUsersArr;
    this.tempUsersArr = [];
  }

  getAllUsersAssignedToShortcode() {
    this.assignedUsers = {user_name: '', user_id: 0};
    this.payload = {
      sc_id: this.resp.dedicated.rows[this.selectedSCIDb].sc_id,
    };
    this.shortcodeService.getAllUsersAssignedToShortcode(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp2 = data;
              if (this.resp2.count !== 0) {
                this.assignedUsers = {
                  user_id: this.resp2.rows[0].user_id,
                  user_name: this.resp2.rows[0].User.user_name,
                };
                this.scHasUser = true;
              } else {
                this.scHasUser = false;
              }
              this.scSelected = true;
            },
        );
  }

  getAllCampaignsAssignedToShortcode() {
    this.campaignsArr = [];
    this.payload = {
      sc_id: this.resp.shortcode.rows[this.selectedSCIDc].sc_id,
    };
    this.shortcodeService.getCampaignAssignedToShortcode(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp2 = data;
              for (let index = 0; index < this.resp2.count; index++) {
                const element = this.resp2.rows[index];
                this.campaignsArr.push({
                  camp_id: element.camp_id,
                  camp_name: element.camp_name,
                  user_name: element.User.user_name,
                });
              }
              this.campSelected = true;
              this.mdbTable2.setDataSource(this.campaignsArr);
              this.campaignsArr = this.mdbTable2.getDataSource();
              this.previous = this.mdbTable2.getDataSource();

              this.mdbTablePagination2.setMaxVisibleItemsNumberTo(5);

              this.mdbTablePagination2.calculateFirstItemIndex();
              this.mdbTablePagination2.calculateLastItemIndex();
              this.cdRef.detectChanges();
            },

        );
  }

  onAddNewShortcode() {
    this.scAdded = true;

    if (this.addNewScForm.invalid) {
      this.openModalErrorCreate();
      return;
    };

    if (this.sc_type_add.value === false) {
      this.scSharedOrDedicated = 's';
    } else {
      this.scSharedOrDedicated = 'd';
    }
    this.payload = {
      sc_number: this.sc_number_add.value,
      sc_type: this.scSharedOrDedicated,
      sc_value: this.sc_value_add.value,
      sc_desc: this.sc_desc_add.value + ' ' + this.sc_value_add.value.toFixed(2),
    };

    this.shortcodeService.tryToAddNewShortcode(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp2 = data;
              if (!this.resp2.created) {
                this.openModalErrorCreateDuplicate();
              } else
              if (this.resp2.created) {
                this.openModalSuccessCreate();
              }
              this.ngOnDestroy();
              this.ngOnInit();
              this.tabSelected = 1;
            },
        );
  }


  onUpdateShortcode() {
    this.scChanged = true;
    this.scDeleted = false;

    if (this.changeScForm.invalid) {
      this.openModalErrorUpdate();
      return;
    }

    if (this.sc_type_change.value === false) {
      this.scSharedOrDedicated = 's';
    } else {
      this.scSharedOrDedicated = 'd';
    }
    this.payload = {
      sc_id: this.resp.shortcode.rows[this.selectedSCIDa].sc_id,
      sc_number: this.sc_number_change.value,
      sc_type: this.scSharedOrDedicated,
      sc_value: this.sc_value_change.value,
      sc_desc: this.sc_desc_change.value + ' ' + this.sc_value_change.value,
    };

    this.shortcodeService.updateShortcode(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp2 = data;
              if (this.resp2[0] === 0) {
                this.openModalSuccessUpdateNoChange();
              } else if (this.resp2[0] === 1) {
                this.openModalSuccessUpdate();
              }
              this.ngOnDestroy();
              this.ngOnInit();
              this.tabSelected = 2;
            },
        );
  }

  onDeleteShortcode() {
    this.scDeleted = true;
    this.scChanged = false;

    if (this.changeScFormSC.invalid) {
      this.openModalErrorDelete();
      return;
    }

    this.payload = {
      sc_id: this.resp.shortcode.rows[this.selectedSCIDa].sc_id,
    };

    this.shortcodeService.deleteShortcode(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp2 = data;
              if (!this.resp2.openCampaign) {
                this.openModalSuccessDelete();
              } else {
                this.openModalErrorDeleteOpenCampaign();
              }
              this.ngOnDestroy();
              this.ngOnInit();
              this.tabSelected = 2;
            },
        );
  }

  onAddUserToShortcode() {
    this.scAssigned = true;

    if (this.assignScForm.invalid || this.assignScFormSC.invalid) {
      this.openModalErrorAssignUser();
      return;
    }

    this.payload = {
      sc_id: this.resp.dedicated.rows[this.selectedSCIDb].sc_id,
      user_id: this.user_id_user.value,
    };

    this.shortcodeService.assignUserToShortcode(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp2 = data;
              if (this.resp2.assigned) {
                this.openModalSuccessAssignUser();
              } else if (!this.resp2.assigned) {
                this.openModalErrorAssignUserAlready();
                return;
              }
              this.ngOnDestroy();
              this.ngOnInit();
              this.tabSelected = 3;
            },
        );
  }

  onAddUserToShortcode2(sc_id) {
    this.scAssigned = true;

    if (this.assignScForm2.invalid) {
      this.openModalErrorAssignUser2();
      return;
    }

    this.payload = {
      sc_id: sc_id,
      user_id: this.user_id_user2.value,
    };


    this.shortcodeService.assignUserToShortcode(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp2 = data;
              if (this.resp2.assigned) {
                this.openModalSuccessAssignUser();
              } else if (!this.resp2.assigned) {
                this.openModalErrorAssignUserAlready();
                return;
              }
              this.ngOnDestroy();
              this.ngOnInit();
              this.tabSelected = 3;
            },
        );
  }

  onDeleteOneAssignedUser() {
    this.payload = {
      sc_id: this.resp.dedicated.rows[this.selectedSCIDb].sc_id,
      user_id: this.assignedUsers.user_id,
    };

    this.shortcodeService.checkForUserOpenCampaigns(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (data) => {
              this.resp2 = data;
              if (this.resp2.deleted) {
                this.openModalSuccessRemoveAssignedUser();
              } else if (!this.resp2.deleted) {
                this.openModalErrorCampaignOpen();
                return;
              }
              this.ngOnDestroy();
              this.ngOnInit();
              this.tabSelected = 3;
            },
        );
  }

modalOptions = {
  backdrop: true,
  keyboard: true,
  focus: true,
  show: false,
  ignoreBackdropClick: false,
  class: 'modal-dialog modal-notify modal-info',
  containerClass: 'modal fade',
  animated: true,
  data: {
    heading: '',
    content: {heading: '', amount: 0, description: '', btnText: ''},
  },
}

openModalConfirmCreate() {
  this.modalOptions.data = {
    heading: 'Really Create?',
    content: {
      heading: 'Are you sure that you want to create this shortcode?',
      amount: 0,
      description: '',
      btnText: 'Create It!',
    },
  };
  this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
  this.modalRef.content.action
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe( (result: any) => {
        if (result) {
          this.onAddNewShortcode();
        }
      });
}

openModalConfirmUpdate() {
  this.modalOptions.data = {
    heading: 'Really Update?',
    content: {
      heading: 'Are you sure that you want to permanently change this shortcode forever?',
      amount: 0,
      description: '',
      btnText: 'Change It!',
    },
  };
  this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
  this.modalRef.content.action
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe( (result: any) => {
        if (result) {
          this.onUpdateShortcode();
        }
      });
}


openModalConfirmDelete() {
  this.modalOptions.data = {
    heading: 'Really Delete?',
    content: {
      heading: 'I sure hope you know what you\'re doing. Do you really want to delete this shortcode?',
      amount: 0,
      description: '',
      btnText: 'Delete It!',
    },
  };
  this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
  this.modalRef.content.action
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe( (result: any) => {
        if (result) {
          this.openModalConfirmDeleteConfirm();
        }
      });
}

openModalConfirmDeleteConfirm() {
  this.modalOptions.data = {
    heading: 'Are You Absolutely Positive?',
    content: {
      heading: 'Are you really really sure that you want to delete this shortcode? This is a permanent action!',
      amount: 0,
      description: '',
      btnText: 'I\'m sure!',
    },
  };
  this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
  this.modalRef.content.action
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe( (result: any) => {
        if (result) {
          this.onDeleteShortcode();
        }
      });
}


openModalConfirmAssignUser() {
  this.modalOptions.data = {
    heading: 'Really Assign?',
    content: {
      heading: 'Are you sure you want to assign this shortcode to the selected user?',
      amount: 0,
      description: '',
      btnText: 'Assign It!',
    },
  };
  this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
  this.modalRef.content.action
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe( (result: any) => {
        if (result) {
          this.onAddUserToShortcode();
        }
      });
}

openModalConfirmAssignUser2(sc_id) {
  this.modalOptions.data = {
    heading: 'Really Assign?',
    content: {
      heading: 'Are you sure you want to assign this shortcode to the selected user?',
      amount: 0,
      description: '',
      btnText: 'Assign It!',
    },
  };
  this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
  this.modalRef.content.action
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe( (result: any) => {
        if (result) {
          this.onAddUserToShortcode2(sc_id);
        }
      });
}

openModalConfirmRemoveAssignedUser() {
  this.modalOptions.data = {
    heading: 'Really Remove User Dedication?',
    content: {
      heading: 'Are you sure you want to remove the currently assigned user from the selected shortcode?',
      amount: 0,
      description: '',
      btnText: 'Confirm!',
    },
  };
  this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions);
  this.modalRef.content.action
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe( (result: any) => {
        if (result) {
          this.onDeleteOneAssignedUser();
        }
      });
}


modalOptionsSuccess = {
  backdrop: true,
  keyboard: true,
  focus: true,
  show: false,
  ignoreBackdropClick: false,
  class: 'modal-dialog modal-notify modal-success',
  containerClass: 'modal fade',
  animated: true,
  data: {
    heading: '',
    content: {heading: '', btnText: ''},
  },
}


openModalSuccessCreate() {
  this.modalOptionsSuccess.data = {
    heading: 'Created Successfully',
    content: {
      heading: 'Fantastic - Your new shortcode: ' + this.resp2.shortcode.sc_number + ' was created successfully!',
      btnText: 'Awesome',
    },
  };
  this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
}


openModalSuccessUpdateNoChange() {
  this.modalOptionsSuccess.data = {
    heading: 'Updated Successfully',
    content: {
      heading: 'Your request was processed - But there were no changes made because you didn\'t make any changes',
      btnText: 'okay',
    },
  };
  this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
}

openModalSuccessUpdate() {
  this.modalOptionsSuccess.data = {
    heading: 'Updated Successfully',
    content: {
      heading: 'Your request was processed - All changes made were successfully applied',
      btnText: 'super',
    },
  };
  this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
}


openModalSuccessDelete() {
  this.modalOptionsSuccess.data = {
    heading: 'Deleted Successfully',
    content: {
      heading: 'Your request was processed - The selected shortcode has ceased to exist.',
      btnText: 'good riddance',
    },
  };
  this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
}


openModalSuccessAssignUser() {
  this.modalOptionsSuccess.data = {
    heading: 'Assigned Successfully',
    content: {
      heading: 'Your request was processed - The selected user has been assigned to the shortcode in question.',
      btnText: 'thanks',
    },
  };
  this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
}


openModalSuccessRemoveAssignedUser() {
  this.modalOptionsSuccess.data = {
    heading: 'Assigned User Successfully Removed',
    content: {
      heading: 'Your request was processed - The selected assigned user has been removed from the shortcode in question.',
      btnText: 'thanks',
    },
  };
  this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
}


modalOptionsError = {
  backdrop: true,
  keyboard: true,
  focus: true,
  show: false,
  ignoreBackdropClick: false,
  class: 'modal-dialog modal-notify modal-danger',
  containerClass: 'modal fade',
  animated: true,
  data: {
    heading: '',
    content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
  },
}

openModalErrorCreate() {
  this.modalOptionsError.data = {
    heading: 'Failed To Create Successfully',
    content: {
      heading: 'Your request failed. Please ensure that you have provided all of the required fields.',
      suggest: 'Suggested Actions:',
      fix1: 'Ensure all required fields are provided.',
      fix2: 'Look for the red text to guide you.',
      fix3: 'Then try again.',
      btnText: 'lets see',
    },
  };
  this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
}


openModalErrorCreateDuplicate() {
  this.modalOptionsError.data = {
    heading: 'Failed To Create Successfully',
    content: {
      heading: 'Your request failed. I am pretty sure that the shortcode you are trying to create already exists.',
      suggest: 'Suggested Actions:',
      fix1: 'Try creating a different shortcode.',
      fix2: 'Delete the current conflicting shortcode.',
      fix3: 'Try to reach your daily dietary fibre requirements.',
      btnText: 'okay',
    },
  };
  this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
}


openModalErrorUpdate() {
  this.modalOptionsError.data = {
    heading: 'Update Failed Successfully',
    content: {
      heading: 'Your request failed. My best guess is that you did not provide all of the fields with valid info.',
      suggest: 'Suggested Actions:',
      fix1: 'Verify that you have provided data in all of the required fields.',
      fix2: 'Verify that all data provided is valid.',
      fix3: 'Don\'t let the little things get you down.',
      btnText: 'okay',
    },
  };
  this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
}

openModalErrorDelete() {
  this.modalOptionsError.data = {
    heading: 'Delete Failed Successfully',
    content: {
      heading: 'Your request failed. My best guess is that you did not indicate which shortcode you want to delete.',
      suggest: 'Suggested Actions:',
      fix1: 'Select a shortcode to delete.',
      fix2: 'Try again.',
      fix3: 'Keep on keeping on.',
      btnText: 'will do',
    },
  };
  this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
}


openModalErrorDeleteOpenCampaign() {
  this.modalOptionsError.data = {
    heading: 'Delete Failed Successfully',
    content: {
      heading: 'Your request failed. There seems to be a currently running campaign using this shortcode you are trying to delete.',
      suggest: 'Suggested Actions:',
      fix1: 'Close the campaign first.',
      fix2: 'Unassign the shortcode from the campaign.',
      fix3: 'Try again.',
      btnText: 'will do',
    },
  };
  this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
}


openModalErrorAssignUser() {
  this.modalOptionsError.data = {
    heading: 'Failed',
    content: {
      heading: 'Your request failed. It looks like you didn\'t specify a user and/or a shortcode to link.',
      suggest: 'Suggested Actions:',
      fix1: 'Select a shortcode.',
      fix2: 'Select an user.',
      fix3: 'Try again.',
      btnText: 'will do',
    },
  };
  this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
}

openModalErrorAssignUser2() {
  this.modalOptionsError.data = {
    heading: 'Failed',
    content: {
      heading: 'Your request failed. It looks like you didn\'t specify a user before selecting a shortcode to assign.',
      suggest: 'Suggested Actions:',
      fix1: 'Select a user first.',
      fix2: 'Then try clicking on "Assign Selected User" again.',
      fix3: 'Try again.',
      btnText: 'will do',
    },
  };
  this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
}

openModalErrorAssignUserAlready() {
  this.modalOptionsError.data = {
    heading: 'Failed',
    content: {
      heading: 'Your request failed. There is already an user assigned to this shortcode - Only one user per dedicated shortcode is allowed.',
      suggest: 'Suggested Actions:',
      fix1: 'Remove current user from assigned shortcode.',
      fix2: 'Try a different shortcode.',
      fix3: 'Try again.',
      btnText: 'will do',
    },
  };
  this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
}


openModalErrorCampaignOpen() {
  this.modalOptionsError.data = {
    heading: 'Failed',
    content: {
      heading: 'Your request failed. The user you want to unassign still has an open campaign using this shortcode.',
      suggest: 'Suggested Actions:',
      fix1: 'Close the campaign in question.',
      fix2: 'Try a different shortcode.',
      fix3: 'Try again.',
      btnText: 'will do',
    },
  };
  this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
}


ngOnDestroy(): any {
  this.ngUnsubscribe.next();
  this.ngUnsubscribe.complete();
}
}
