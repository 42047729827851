import {Component, OnInit} from '@angular/core';
import {MDBModalRef} from 'ng-uikit-pro-standard';
import {TutCampReportsService} from '../../../services/guide-shortcode/tut-camp-reports.service';
import {TutLivereadService} from '../../../services/guide-shortcode/tut-liveread.service';
import {TutManageCampService} from '../../../services/guide-shortcode/tut-manage-camp.service';
import {TutNewCampService} from '../../../services/guide-shortcode/tut-new-camp.service';
import {TutRandomWinnerService} from '../../../services/guide-shortcode/tut-random-winner.service';

@Component({
  selector: 'app-shortcode-guide',
  templateUrl: './shortcode-guide.component.html',
  styleUrls: ['./shortcode-guide.component.scss'],
})
export class ShortcodeGuideComponent implements OnInit {
  constructor(public modalRef: MDBModalRef,
    private tutCampReport: TutCampReportsService,
    private tutLiveRead: TutLivereadService,
    private tutManageCamp: TutManageCampService,
    private tutNewCamp: TutNewCampService,
    private tutRandomWinner: TutRandomWinnerService,
  ) { }

  ngOnInit() {
  }

  onOpenCreateCampaign() {
    this.tutNewCamp.newCampaignTour();
  }

  onOpenManageCampaign() {
    this.tutManageCamp.manageCampaignTour();
  }

  onOpenLiveReader() {
    this.tutLiveRead.campReaderTour();
  }

  onOpenRandomWinner() {
    this.tutRandomWinner.campWinnerTour();
  }

  onOpenCampReports() {
    this.tutCampReport.shortcodeReportTour();
  }
}
