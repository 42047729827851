import {Component, OnInit} from '@angular/core';
import {MDBModalRef} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss'],
})
export class SuccessModalComponent implements OnInit {
  heading = '';
  content = {
    heading: '',
    btnText: '',
  }

  constructor(public frame: MDBModalRef) {}


  ngOnInit() {
  }
}
