import {Component, OnInit} from '@angular/core';
import {MDBModalRef} from 'ng-uikit-pro-standard';
import {Subject} from 'rxjs';


@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
  action: Subject <any> = new Subject();

  heading = '';
  content = {
    heading: '',
    amount: 0,
    description: '',
    btnText: '',
  }

  constructor(public frame: MDBModalRef) {}

  ngOnInit() {
  }

  onYesClick() {
    this.frame.hide();
    this.action.next('true');
  }
}
