/* eslint-disable max-len */
import {BrowserModule} from '@angular/platform-browser';
import {MDBBootstrapModulesPro} from 'ng-uikit-pro-standard';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LayoutModule} from '@angular/cdk/layout';

import {MDBSpinningPreloader} from 'ng-uikit-pro-standard';
import {NgModule} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {FileUploadModule} from 'ng2-file-upload';
import {PDFExportModule} from '@progress/kendo-angular-pdf-export';
import {PasswordStrengthMeterModule} from 'angular-password-strength-meter';

import {HammerGestureConfig, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
declare let Hammer: any;
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    'pan': {direction: Hammer.DIRECTION_All},
    'swipe': {direction: Hammer.DIRECTION_VERTICAL},
  };

  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: 'auto',
      inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput,
      recognizers: [
        [Hammer.Swipe, {
          direction: Hammer.DIRECTION_HORIZONTAL,
        }],
      ],
    });
    return mc;
  }
}

import {LoaderService} from './services/loader.service';
import {LoaderInterceptor} from './_helpers/loader.interceptor';


import {ErrorInterceptor} from './_helpers/error.interceptors';
import {JwtInterceptor} from './_helpers/jwt.interceptor';

// MODALS
import {CampaignSelectModalComponent} from './modals/campaign-select-modal/campaign-select-modal.component';


import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {CampaignDashComponent} from './home/campaign-dash/campaign-dash.component';
import {PersonalDetailsComponent} from './account/personal-details/personal-details.component';
import {BillingDetailsComponent} from './account/billing-details/billing-details.component';
import {NumberLookupComponent} from './sms/reports/number-lookup/number-lookup.component';
import {SentSmsComponent} from './sms/reports/sent-sms/sent-sms.component';
import {ReplySmsComponent} from './sms/reports/reply-sms/reply-sms.component';
import {BuyCreditsComponent} from './sms/credits/buy-credits/buy-credits.component';
import {PurchaseHistoryComponent} from './sms/credits/purchase-history/purchase-history.component';
import {ReplyRoutingComponent} from './sms/reply-routing/reply-routing.component';
import {ManageCampaignComponent} from './shortcode/manage-campaign/manage-campaign.component';
import {LiveReaderComponent} from './shortcode/live-reader/live-reader.component';
import {RandomWinnerComponent} from './shortcode/random-winner/random-winner.component';
import {PrivacyPolicyComponent} from './general/privacy-policy/privacy-policy.component';
import {TermsAndConditionsComponent} from './general/terms-and-conditions/terms-and-conditions.component';
import {FaqComponent} from './general/faq/faq.component';
import {ContactUsComponent} from './contact/contact-us/contact-us.component';
import {UsersComponent} from './admin/manage/users/users.component';
import {CampaignsComponent} from './admin/manage/campaigns/campaigns.component';
import {ShortcodesComponent} from './admin/manage/shortcodes/shortcodes.component';
import {ApproveComponent} from './admin/credits-and-voucers/approve/approve.component';
import {SmsBundlesComponent} from './admin/credits-and-voucers/sms-bundles/sms-bundles.component';
import {AddVoucherComponent} from './admin/credits-and-voucers/add-voucher/add-voucher.component';
import {NavbarComponent} from './navigation/navbar/navbar.component';
import {FooterComponent} from './navigation/footer/footer.component';
import {Page404Component} from './page404/page404.component';
import {PreferencesComponent} from './account/settings/preferences/preferences.component';
import {ChangePasswordComponent} from './account/settings/change-password/change-password.component';
import {ViewBookComponent} from './sms/send/phone-books/view-book/view-book.component';
import {CreateBookComponent} from './sms/send/phone-books/create-book/create-book.component';
import {SingleSmsComponent} from './sms/send/single-sms/single-sms.component';
import {FromPhonebookComponent} from './sms/send/from-phonebook/from-phonebook.component';
import {UploadFileComponent} from './sms/send/upload-file/upload-file.component';
import {ShortcodeCampaignComponent} from './sms/send/shortcode-campaign/shortcode-campaign.component';
import {NewKeywordCampaignComponent} from './shortcode/new-campaign/new-keyword-campaign/new-keyword-campaign.component';
import {NewInstantWinnerCampaignComponent} from './shortcode/new-campaign/new-instant-winner-campaign/new-instant-winner-campaign.component';
import {RegisterLoginComponent} from './modals/register-login/register-login.component';
import {ContactComponent} from './landing-page/contact/contact.component';
import {LandingComponent} from './landing-page/landing/landing.component';
import {NavbarLandingComponent} from './landing-page/navbar-landing/navbar-landing.component';
import {OtherComponent} from './landing-page/other/other.component';
import {PriceBulkComponent} from './landing-page/price-bulk/price-bulk.component';
import {PriceOtherComponent} from './landing-page/price-other/price-other.component';
import {ProductsComponent} from './landing-page/products/products.component';
import {FaqLandingComponent} from './landing-page/faq-landing/faq-landing.component';
import {ReceivedSmsComponent} from './shortcode/reports/received-sms/received-sms.component';
import {CampaignReportComponent} from './shortcode/reports/campaign-report/campaign-report.component';
import {TicketUsageComponent} from './shortcode/reports/ticket-usage/ticket-usage.component';
import {ValidEntriesComponent} from './shortcode/reports/valid-entries/valid-entries.component';
import {UnnamedComponent} from './sms/scheduled/unnamed/unnamed.component';
import {NamedComponent} from './sms/scheduled/named/named.component';
import {ConfirmComponent} from './modals/other/confirm/confirm.component';
import {SuccessModalComponent} from './modals/other/success-modal/success-modal.component';
import {AddTicketCodesComponent} from './admin/credits-and-voucers/add-ticket-codes/add-ticket-codes.component';
import {PhonebookComponent} from './sms/send/phone-books/view-book/phonebook/phonebook.component';
import {ContactsComponent} from './sms/send/phone-books/view-book/contacts/contacts.component';
import {ContactModalComponent} from './modals/edit/contact-modal/contact-modal.component';
import {UploadModalComponent} from './modals/file/upload-modal/upload-modal.component';
import {UploadBlacklistModalComponent} from './modals/file/upload-blacklist-modal/upload-blacklist-modal.component';
import {ForgotPasswordComponent} from './modals/forgot-password/forgot-password.component';
import {ErrorComponent} from './modals/other/error/error.component';
import {InvoiceComponent} from './modals/invoice/invoice.component';
import {ActivateAccountComponent} from './modals/activate-account/activate-account.component';
import {LoadingdisableDirective} from './loadingdisable.directive';
import {LoaderComponent} from './loader/loader.component';
import {SmsGuideComponent} from './modals/guide/sms-guide/sms-guide.component';
import {ShortcodeGuideComponent} from './modals/guide/shortcode-guide/shortcode-guide.component';
import {CreditAlertComponent} from './account/settings/preferences/credit-alert/credit-alert.component';
import {TemplatesComponent} from './sms/templates/templates.component';
import {TemplateModalComponent} from './modals/edit/template-modal/template-modal.component';
import {TemplateSelectModalComponent} from './modals/edit/template-select-modal/template-select-modal.component';
import {Sms2emailModalComponent} from './modals/sms2email/sms2email-modal/sms2email-modal.component';
import {SetupComponent} from './email2sms/setup/setup.component';
import {E2sReportComponent} from './email2sms/e2s-report/e2s-report.component';
import {SplashComponent} from './modals/splash/splash.component';
import {ViewOptoutComponent} from './sms/optout/view-optout/view-optout.component';
import {OptoutNumbersComponent} from './sms/optout/view-optout/optout-numbers/optout-numbers.component';
import {OptoutListsComponent} from './sms/optout/view-optout/optout-lists/optout-lists.component';
import {OptoutModalComponent} from './modals/edit/optout-modal/optout-modal.component';
import {NewOptoutModalComponent} from './modals/edit/new-optout-modal/new-optout-modal.component';
import {UploadOptoutModalComponent} from './modals/file/upload-optout-modal/upload-optout-modal.component';
import {ManageWinnerBlacklistComponent} from './shortcode/winner-blacklist/manage-winner-blacklist/manage-winner-blacklist.component';
import {BlacklistWinnerComponent} from './modals/edit/blacklist-winner/blacklist-winner.component';
import {UploadBlackistWinnersModalComponent} from './modals/file/upload-blackist-winners-modal/upload-blackist-winners-modal.component';
import {RandomWinnerEmailModalComponent} from './modals/random-winner-email-modal/random-winner-email-modal.component';
import {FirstWinnerComponent} from './shortcode/first-winner/first-winner.component';
import {CampaignResponsesComponent} from './shortcode/reports/campaign-responses/campaign-responses.component';
import {EmailTemplatesComponent} from './account/settings/preferences/email-templates/email-templates.component';
import {DealersComponent} from './admin/manage/dealers/dealers.component';
// import {CustomersComponent} from './dealer/manage/customers/customers.component';
// import { CustomerShortcodesComponent } from './dealer/manage/customer-shortcodes/customer-shortcodes.component';


@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    CampaignDashComponent,
    PersonalDetailsComponent,
    BillingDetailsComponent,
    NumberLookupComponent,
    SentSmsComponent,
    ReplySmsComponent,
    BuyCreditsComponent,
    PurchaseHistoryComponent,
    ReplyRoutingComponent,
    ManageCampaignComponent,
    LiveReaderComponent,
    RandomWinnerComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    FaqComponent,
    ContactUsComponent,
    UsersComponent,
    CampaignsComponent,
    ShortcodesComponent,
    ApproveComponent,
    SmsBundlesComponent,
    AddVoucherComponent,
    NavbarComponent,
    FooterComponent,
    Page404Component,
    PreferencesComponent,
    ChangePasswordComponent,
    ViewBookComponent,
    CreateBookComponent,
    SingleSmsComponent,
    FromPhonebookComponent,
    UploadFileComponent,
    ShortcodeCampaignComponent,
    CampaignSelectModalComponent,
    NewKeywordCampaignComponent,
    NewInstantWinnerCampaignComponent,
    RegisterLoginComponent,
    ContactComponent,
    LandingComponent,
    NavbarLandingComponent,
    OtherComponent,
    PriceBulkComponent,
    PriceOtherComponent,
    ProductsComponent,
    FaqLandingComponent,
    ReceivedSmsComponent,
    CampaignReportComponent,
    TicketUsageComponent,
    ValidEntriesComponent,
    UnnamedComponent,
    NamedComponent,
    ConfirmComponent,
    SuccessModalComponent,
    AddTicketCodesComponent,
    PhonebookComponent,
    ContactsComponent,
    ContactModalComponent,
    UploadModalComponent,
    UploadBlacklistModalComponent,
    ForgotPasswordComponent,
    ErrorComponent,
    InvoiceComponent,
    ActivateAccountComponent,
    LoadingdisableDirective,
    LoaderComponent,
    SmsGuideComponent,
    ShortcodeGuideComponent,
    CreditAlertComponent,
    TemplatesComponent,
    TemplateModalComponent,
    TemplateSelectModalComponent,
    Sms2emailModalComponent,
    SetupComponent,
    E2sReportComponent,
    SplashComponent,
    ViewOptoutComponent,
    OptoutNumbersComponent,
    OptoutListsComponent,
    OptoutModalComponent,
    NewOptoutModalComponent,
    UploadOptoutModalComponent,
    ManageWinnerBlacklistComponent,
    BlacklistWinnerComponent,
    UploadBlackistWinnersModalComponent,
    RandomWinnerEmailModalComponent,
    FirstWinnerComponent,
    CampaignResponsesComponent,
    EmailTemplatesComponent,
    DealersComponent,
    // CustomersComponent,
    // CustomerShortcodesComponent,
  ],
  imports: [
    BrowserModule,
    MDBBootstrapModulesPro.forRoot(),
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FileUploadModule,
    PDFExportModule,
    LayoutModule,
    PasswordStrengthMeterModule,
  ],
  entryComponents: [CampaignSelectModalComponent, RegisterLoginComponent,
    ConfirmComponent, ActivateAccountComponent, RandomWinnerEmailModalComponent,
    SuccessModalComponent, ContactModalComponent,
    UploadModalComponent, UploadBlacklistModalComponent, ForgotPasswordComponent, ErrorComponent, InvoiceComponent, SmsGuideComponent, ShortcodeGuideComponent,
    TemplateModalComponent, TemplateSelectModalComponent, Sms2emailModalComponent, SplashComponent, OptoutModalComponent, NewOptoutModalComponent, UploadOptoutModalComponent, UploadBlackistWinnersModalComponent, BlacklistWinnerComponent],
  providers: [
    MDBSpinningPreloader, LoaderService,
    {provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
