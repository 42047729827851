/* eslint-disable no-multi-str */
/* eslint-disable max-len */
import {Injectable} from '@angular/core';
import Shepherd from 'shepherd.js';
import {Router} from '@angular/router';

const shortcodeReportTour = new Shepherd.Tour({
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
    classes: 'shadow-md bg-purple-dark',
    scrollTo: {behavior: 'smooth', block: 'center'},
  },
  confirmCancel: false,
  // confirmCancelMessage: '',
  exitOnEsc: false,
  keyboardNavigation: false,
  useModalOverlay: true,
});

@Injectable({
  providedIn: 'root',
})
export class TutCampReportsService {
  constructor(private router: Router) { }

  shortcodeReportTour() {
    const that = this;
    shortcodeReportTour.addSteps([{
      id: 'start',
      title: 'Using Campaign Reports',
      text: `This is an interactive tutorial and will walk you through everything you need to know to use the Shortcode Reports efficiently.<br><br>
      As always I will be pointing at stuff, and telling you some things about the stuff im pointing at. At the end you will have mastered the art of Interpreting Shortcode Reports.<br><br>
      <mark>- At some steps I will ask you to interact with the page before we can move on. These interactive actions will be highlighted like this</mark>`,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'Step1',
      title: 'Breakdown',
      text: `In this tutorial we will be examining the 4x different SMS Reports.<br><br>
      First up will be the Received SMS Report.<br><br>
      Followed by the Ticket Usage Report.<br><br>
      Then it will be the Valid Entries Report.<br><br>
      And we will round everything off with The Grand Campaign Report.`,
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Lets Begin'},
      ],
    }, {
      id: 'step1',
      title: 'Campaign Entries',
      text: `Finds all valid entries for the selected campaign and downloads the report.<br><br>
      <i>- I am sure you will find this to be quite simple to do.</i><br><br>`,
      attachTo: {element: '.mainS21', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step2',
      title: 'Select a Campaign',
      text: `Here you select the campaign from which you would like to get entries from.<br><br>`,
      attachTo: {element: '.t2', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step3',
      title: 'Generate Report Button',
      text: `After successfully selecting a campaign you simply click on this button.<br><br>
      <i>- And Voilà!</i><br><br>`,
      attachTo: {element: '.t3', on: 'left'},
      modalOverlayOpeningPadding: 10,
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step4',
      title: 'Downloading',
      text: `At this point your report will automatically start to download.<br><br>`,
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step5',
      title: 'As Simple As That',
      text: `This concludes our guide on Getting Campaign Entries Reports.<br><br>
      Next we will look at your Campaigns Ticket Usage Report`,
      canClickTarget: false,
      buttons: [
        {action() {
          return that.router.navigate(['/shortcode/campaign/reports/ticket-usage']), this.next();
        }, text: 'Ticket Usage Report'},
      ],
    }, {
      id: 'step6',
      title: 'Ticket Usage Report',
      text: `From this page you can view any of your campaign's ticket usage.<br><br>
      <i>- If you are running an Instant Winner Campaign that is ticket based your campaign will use unique ticket codes to award prizes.</i>`,
      attachTo: {element: '.mainS23', on: 'bottom'},
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 400);
        });
      },
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////////
      id: 'step7',
      title: 'Campaign Select',
      text: `First thing you want to do is to select your Instant Winner Campaign from this list.<br><br>
    <i>If you have at least one existing campaign please select a campaign otherwise you can come back here at a later stage.</i><br><br>
    <mark>- To continue either select a campaign then Click on "Next" or click on "Skip" if you don't have any campaigns yet.</mark>`,
      attachTo: {element: '.t7', on: 'bottom'},
      canClickTarget: true,
      modalOverlayOpeningPadding: 20,
      buttons: [
        {action() {
          return this.show('stepSkip');
        }, text: 'Skip'},
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'stepCheck',
      title: 'Verifying...',
      text: `Just checking if you selected a campaign.<br><br>
    If you do not have any campaigns to select please click on "Skip" on the next window.<br><br>
    If you do have campaigns and did not select one - Please do. If you did select a campaign - Lets get into it!`,
      canClickTarget: false,
      buttons: [
        {action() {
          if (document.getElementById('loadCheck') === null) {
            return this.show('stepError');
          } else {
            return this.show('step8');
          }
        }, text: 'Next'},
      ],
    }, {
      id: 'stepError',
      title: 'Back to the drawing board',
      text: 'Please select a campaign before clicking on "Next" this time...',
      canClickTarget: false,
      buttons: [
        {action() {
          return this.show('step7');
        }, text: 'Start Again'},
      ],
    }, {
      id: 'step8',
      title: 'Campaign Ticket Usage Stats',
      text: `After selecting a campaign you will see this page.<br><br>
      <i>Shown here are the total amount of tickets , the amount of tickets used and the available amount.</i><br><br>`,
      attachTo: {element: '.t8', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step9',
      title: 'Searching For A Ticket',
      text: `If you want detailed information about a ticket - like the winner's details, date used, etc. - You can type in the ticket code here.`,
      attachTo: {element: '.t9', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step10',
      title: 'To search',
      text: 'After entering a ticket code to search for you simply click on this button to find it.<br><br>',
      attachTo: {element: '.t10', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step11',
      title: 'Changing Campaigns',
      text: 'To go back and select a different campaign you may click on this button at any time.<br><br>\
    <mark>- To continue please click on "Change Campaign" when you feel that you are ready.</mark>',
      attachTo: {element: '.t11', on: 'bottom'},
      advanceOn: {selector: '.t11', event: 'click'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step12',
      title: 'As Simple As That!',
      text: `This concludes our Campaign Ticket Usage tour. Next up we are looking at Valid Entries<br><br>
    <i>- Two down, Two to go!</i>`,
      canClickTarget: false,
      buttons: [
        {action() {
          return that.router.navigate(['/shortcode/campaign/reports/valid-entries']), this.show('step13');
        }, text: 'Next'},
      ],
    }, {
      id: 'stepSkip',
      title: `It's okay`,
      text: `Don't worry - You can always come back to this tutorial once you have some campaigns running.`,
      canClickTarget: false,
      buttons: [
        {action() {
          return that.router.navigate(['/shortcode/campaign/reports/valid-entries']), this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step13',
      title: 'Campaign Valid Entries',
      text: `From here you can download detailed Entry reports on any of your Instant Winner Campaigns.<br><br>
    <i>- This report will include: Date of Entry, if entries are winning entries or not, Network Carrier, number used to enter and the message text.</i><br><br>`,
      attachTo: {element: '.mainS24', on: 'bottom'},
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 400);
        });
      },
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step14',
      title: 'Campaign Select',
      text: 'Similar to the Received SMS Report - You only have to select one of your campaigns.',
      attachTo: {element: '.t14', on: 'bottom'},
      canClickTarget: true,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step15',
      title: 'Download Report',
      text: `After selecting one of your campaigns you simply click on "Generate Report".<br><br>
    <i>- Your report should automatically start to download almost instantly.</i><br><br>`,
      attachTo: {element: '.t15', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step16',
      title: 'Easy as Pi',
      text: 'That pretty much covers downloading valid entry reports for your Instant Winner Campaigns.',
      canClickTarget: false,
      buttons: [
        {action() {
          return that.router.navigate(['/shortcode/campaign/reports/report']), this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step17',
      title: 'Campaign Report Generator',
      text: `Aaaaand last but certainly not least, we have the Fabled Campaign Report Generator.<br><br>
    This bad boy likes to completely over analyse any campaign that you give it.<br><br>
    <i>Results include lots of bells and whistles, rounded off with a nice bow ribbon.</i>`,
      attachTo: {element: '.mainS22', on: 'bottom'},
      canClickTarget: false,
      beforeShowPromise: function() {
        return new Promise<void>(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 400);
        });
      },
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step19',
      title: 'The End of Our Journey',
      text: `I am going to leave you here to generate your report in peace and freedom to explore said report.<br><br>\
    You should find all the different graphs and tables to be self explanatory and frankly way to comprehensive.<br><br>
    <i>- You also have the option download a pdf of the report generated.</i>`,
      canClickTarget: false,
      buttons: [
        {action() {
          return this.next();
        }, text: 'Next'},
      ],
    }, {
      id: 'step20',
      title: 'Fin.',
      text: `This concludes the Shortcode Reports Tour.<br><br>\
    You have been awesome! Thank you for taking the time to understand what makes me tick.<br><br>\
    I look forward to our next encounter - Until then keep smiling and stay positive.<br><br>\
    <i>Bye =)</i>`,
      canClickTarget: false,
      buttons: [
        {action() {
          return this.complete();
        }, text: 'Next'},
      ],
    },
    ]);


    shortcodeReportTour.start();
  }
}
