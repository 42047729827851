import {Injectable} from '@angular/core';
import {
  // HttpErrorResponse,
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LoaderService} from '../services/loader.service';
import {ErrorComponent} from '../modals/other/error/error.component';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];
    modalRef: MDBModalRef;
    constructor(private loaderService: LoaderService, private modalService: MDBModalService) { }

    removeRequest(req: HttpRequest<any>) {
      const i = this.requests.indexOf(req);
      if (i >= 0) {
        this.requests.splice(i, 1);
      }
      this.loaderService.isLoading.next(this.requests.length > 0);
    }

    modalOptionsError = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-dialog modal-notify modal-danger',
      containerClass: 'modal fade overflow-auto',
      animated: true,
      data: {
        heading: '',
        content: {heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''},
      },
    }


    openModalErrorTimeout() {
      this.modalOptionsError.data = {
        heading: 'Well this is embarrassing...',
        content: {
          heading: 'I seem to have lost you there for a moment... Your request was unsuccessful due to one of the following:',
          suggest: 'Troubleshooting:',
          fix1: 'Network: There could be a hiccup with your internet connection. If this persists try refreshing your browser.',
          fix2: 'Internal: Programmers can be notoriously lazy - They are working on the problem. ',
          fix3: 'External: This includes Loadshedding, High Traffic Volumes and Covid-19 related matters.',
          btnText: '...',
        },
      };
      this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      this.requests.push(req);
      this.loaderService.isLoading.next(true);
      return Observable.create((observer) => {
        const subscription = next.handle(req)
            .subscribe(
                (event) => {
                  if (req.headers.get('skip')) {
                    this.removeRequest(req);
                    observer.next(event);
                  }
                  if (event instanceof HttpResponse) {
                    this.removeRequest(req);
                    observer.next(event);
                  }
                },
                (err) => {
                  if (!req.headers.get('skip')) {
                    this.openModalErrorTimeout();
                  }
                  this.removeRequest(req);
                  observer.error(err);
                },
                () => {
                  this.removeRequest(req);
                  observer.complete();
                });
        // remove request from queue when cancelled
        return () => {
          this.removeRequest(req);
          subscription.unsubscribe();
        };
      });
    }
}
