import {Component, OnInit} from '@angular/core';
import {MDBModalRef} from 'ng-uikit-pro-standard';
import {TutorialService} from '../../../services/guide/tutorial.service';
import {TutPhonebookCreateService} from '../../../services/guide/tut-phonebook-create.service';
import {TutSmsReportsService} from '../../../services/guide/tut-sms-reports.service';
import {TutSmsScheduledService} from '../../../services/guide/tut-sms-scheduled.service';

@Component({
  selector: 'app-sms-guide',
  templateUrl: './sms-guide.component.html',
  styleUrls: ['./sms-guide.component.scss'],
})
export class SmsGuideComponent implements OnInit {
  constructor(public modalRef: MDBModalRef,
    private tutorial: TutorialService,
    private tutPhonebook: TutPhonebookCreateService,
    private tutSmsReport: TutSmsReportsService,
    private tutSmsScheduled: TutSmsScheduledService,
  ) { }

  ngOnInit() {
  }

  onOpenSms() {
    this.tutorial.smsTour();
  }

  onOpenPhonebook() {
    this.tutPhonebook.phonebookTour();
  }

  onOpenReport() {
    this.tutSmsReport.smsReportTour();
  }

  onOpenSchedule() {
    this.tutSmsScheduled.smsScheduledTour();
  }
}
